import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { thunks } from "../thunks/general";

export default function ProtectedRoute({ redirectTo = "/" }) {
  const general = useSelector((state) => state.general);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + `api/auth/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = response.data;
        dispatch(thunks.setStatus(result.status));
        dispatch(thunks.setUsername(result.name));
        dispatch(thunks.setUserId(result.id));
        dispatch(thunks.setRole(result.temporaryRole));
        dispatch(thunks.setAgency(result.agency_id));
        dispatch(thunks.setTokens(result.availableTokens));
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    if (
      !token &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/register"
    ) {
      navigate(redirectTo);
    } else {
      fetchData();
    }

    if (token && general.status == "Pending") {
      navigate("/verification-opt");
    }

    // Se siamo nella pagina "/" e abbiamo il token, reindirizza a "dashboard"
    if (
      (token && window.location.pathname === "/") ||
      (token && window.location.pathname === "/register")
    ) {
      navigate("/dashboard");
    }
  }, [token, navigate, redirectTo, general.status, dispatch, general.userId]);

  return <Outlet />;
}
