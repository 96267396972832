import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap(({ coordinates }) => (

    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
    >
      <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
    </GoogleMap>
  ))
);

const MapComponent = ({ coordinates }) => {

  return (
    <div>
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "400px", width: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        coordinates={coordinates}
      />
    </div>
  );
};

export default MapComponent;
