import React, { useState, useEffect, useMemo, Suspense } from "react";
import {
  itIT,
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import LaunchIcon from "@mui/icons-material/Launch";

import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  CardHeader,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import CustomCheckbox from "components/customInputs/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DynamicWizard from "../../components/dynamic-wizard";
import { Row, Col } from "reactstrap";
import useMounted from "react-use-mounted";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BuildIcon from "@mui/icons-material/Build";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
export const List = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentFormType = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [modificaText, setModificaText] = useState("");
  const [checkboxState, setCheckboxState] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const apiRef = useGridApiRef();
  const [rowsData, setFormFieldsRows] = useState([]);
  const [formFieldsColumn, setFormFieldsColumn] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [columnsData, setColumnsData] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [visibilityFields, setVisibilityFields] = useState({});
  const [error, setError] = useState(null);

  const [localLoader, setLocalLoader] = useState(false);
  const isMounted = useMounted();

  const handleOpenDialog = (rowId, actionType) => {
    if (actionType === "delete") {
      setDeleteConfirmDialog({ open: true, rowId });
    } else {
      setConfirmDialog({ open: true, rowId });
    }
  };

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const formType = location.pathname.split("/")[2];
  async function getJsonField() {
    try {
      setLocalLoader(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getFieldsForObjectTable/${currentFormType}`,
        { headers: headers }
      );

      if (Array.isArray(response.data)) {
        setState(response.data);
      } else {
        // Gestisci il caso in cui i dati non sono un array
        console.error("Expected an array of data");
        return;
      }

      if (response.data === "No data available") {
        setFormFieldsRows([]);
        return;
      }

      if (Array.isArray(response.data)) {
        const formFieldColumn = [];
        const fieldNamesSet = new Set();

        response.data.forEach((field) => {
          const fieldName = field.fieldName;
          const fieldLabel = field.label;
          const columnVisibility = field.showInTable === 1;

          visibilityFields[fieldName] = columnVisibility;

          if (!fieldNamesSet.has(fieldName)) {
            fieldNamesSet.add(fieldName);
            formFieldColumn.push({
              field: fieldName,
              headerName: fieldLabel,
              width: 200,
              hide: !columnVisibility,
              renderCell: (row) => (
                <>
                  <Row>
                    <Col>
                      {row.row[fieldName] ? (
                        <>
                          {fieldName === "title" || fieldName === "name" ? (
                            <Link
                              to={
                                "/adminList/" +
                                currentFormType +
                                "/show/" +
                                row.id
                              }
                            >
                              {row.row[fieldName]}
                            </Link>
                          ) : (
                            row.row[fieldName]
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </Col>
                  </Row>
                </>
              ),
            });
          }
        });

        const responseRecords = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT +
            `api/auth/getRecords/${currentFormType}`,
          { headers: headers }
        );
        //console.log("formFieldColumn", responseRecords);
        setFormFieldsColumn(formFieldColumn);
        setColumnsData(formFieldColumn);
        setFormFieldsRows(responseRecords.data);
        setVisibilityFields(visibilityFields);
      } else {
        throw new Error("Formato di risposta non valido");
      }
    } catch (e) {
      setError(e.message);
      console.error(e);
    } finally {
      setLocalLoader(false);
    }
  }

  useEffect(() => {
    getJsonField();
  }, [currentFormType, isMounted]);

  const handleOpenConfirmDialog = (rowId, actionType, status) => {
    if (actionType === "delete") {
      setDeleteConfirmDialog({ open: true, rowId });
    } else {
      setConfirmDialog({ open: true, rowId, status });
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (!checkboxSelected || checkboxSelected.length === 0) {
        console.log("Disabling button: No selected rows");
        toast.error("Nessuna riga selezionata per l'eliminazione.");
        return;
      }
      const token = localStorage.getItem("token");

      const headers = { Authorization: `Bearer ${token}` };

      const endpoint = process.env.REACT_APP_APP_ENDPOINT + `api/auth/delete`;

      // Usa axios.delete con data invece di params
      const response = await axios.delete(endpoint, {
        data: { ids: checkboxSelected },
        headers,
      });

      if (response.status === 200) {
        // Aggiorna lo stato del componente con i nuovi dati
        const updatedRows = rowsData.filter(
          (row) => !checkboxSelected.includes(row.id)
        );
        setFormFieldsRows(updatedRows);

        toast.success("Righe eliminate con successo");
      } else {
        toast.error("Errore durante l'eliminazione.");
      }
    } catch (error) {
      console.error("Errore durante l'eliminazione:", error);
      toast.error("Errore durante l'eliminazione.");
    } finally {
      setDeleteConfirmDialog({ open: false, selectedRowId: [] });
      handleCloseDialog();
    }
  };
  const handleLinkClick = (rowId) => {
    const url = `/asta/${rowId}`;
    window.open(url, "_blank"); // Apre il link in una nuova pagina
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCheckboxState({ checkbox1: false, checkbox2: false, checkbox3: false });
    setModificaText("");
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsModalOpen(false);
    setConfirmDialog({ open: false, rowId: null, newState: null });
    setDeleteConfirmDialog({ open: false, rowId: null, newState: null });
  };
  const handleCheckboxChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSelectionChange = (newSelection) => {
    setCheckboxSelected(newSelection);
  };
  const handleModificaTextChange = (event) => {
    setModificaText(event.target.value);
  };

  const handleEditDialogSubmit = async () => {
    if (!modificaText.trim()) {
      toast.error("Il campo di testo per la modifica è obbligatorio.");
      return;
    }
    // Qui invii i dati al server, ad esempio:
    try {
      const response = await axios.post("Your_API_Endpoint", {
        checkboxes: checkboxState,
        modificaText: modificaText,
      });
      if (response.status === 200) {
        toast.success("Modifica richiesta con successo!");
      } else {
        toast.error("Qualcosa è andato storto.");
      }
    } catch (error) {
      toast.error("Errore durante l'esecuzione dell'azione.");
    }
    handleEditDialogClose();
  };
  const handleOnClick = (rowId) => {
    navigate("/admin/" + currentFormType + "/" + rowId);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  function StatusCell(props) {
    const { status } = props;

    let color = "default";
    let icon = null;
    let label = "Stato";

    switch (status) {
      case "Approvato":
        color = "green";
        icon = <CheckCircleIcon />;
        label = "Approvato";
        break;
      case "Rifiutato":
        color = "red";
        icon = <CancelIcon />;
        label = "Rifiutato";
        break;
      case "Sospesa":
        color = "red";
        icon = <PauseCircleOutlineIcon />;
        label = "Sospesa";
        break;
      case "Attiva":
        color = "green";
        icon = <PlayCircleOutlineIcon />;
        label = "Attiva";
        break;
      case "Richiesta Modifica":
        color = "orange";
        icon = <BuildIcon />;
        label = "Modifica";
        break;
      case "Nuovo":
        color = "darkblue";
        icon = <BuildIcon />;
        label = "Nuovo";
        break;
      default:
        label = status;
    }

    return (
      <Button
        startIcon={icon}
        label="status"
        disabled
        variant="contained"
        style={{
          backgroundColor: color,
          color: "white",
          cursor: "default",
          padding: "3px 10px",
          textTransform: "none",
        }}
      >
        {label}
      </Button>
    );
  }

  const getApprovalColumn = () => {
    if (
      (currentFormType !== "immobile" && currentFormType !== "asta") ||
      isSmallDevice
    ) {
      return null;
    }

    const renderButtonsBasedOnStatus = (status, rowId) => {
      if (status !== 1) {
        if (currentFormType == "immobile") {
          return (
            <>
              <Button
                color="success"
                onClick={() =>
                  handleOpenConfirmDialog(rowId, status, "Approvato")
                }
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Approva" arrow>
                  <CheckCircleOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>
              <Button
                color="error"
                onClick={() =>
                  handleOpenConfirmDialog(rowId, status, "Rifiutato")
                }
              >
                <Tooltip title="Rifiuta">
                  <ClearOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>
              <Button color="warning" onClick={() => handleEditDialogOpen()}>
                <Tooltip title="Modifica">
                  <ModeEditOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>

              <Button
                color="success"
                onClick={() => handleOpenConfirmDialog(rowId, status, "Attiva")}
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Avvia" arrow>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <PlayCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "8px" }}>Avvia</span>
                  </div>
                </Tooltip>
              </Button>
              <Button
                color="success"
                onClick={() =>
                  handleOpenConfirmDialog(rowId, status, "Sospesa")
                }
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Sospendi" arrow>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <PauseCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "8px" }}>Sospendi</span>
                  </div>
                </Tooltip>
              </Button>

              <a
                to={`/asta/${rowId}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "green",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(rowId);
                }}
              >
                <LaunchIcon style={{ marginLeft: "4px" }} />
              </a>
            </>
          );
        }
      } else {
        return (
          <Button
            color="error"
            onClick={() => handleOpenConfirmDialog(rowId, status, "Sospeso")}
          >
            Sospendi
          </Button>
        );
      }
    };

    return {
      id: "approvalColumn", // Aggiungi una chiave univoca
      field: "actions",
      headerName: "Gestisci Inserzioni",
      sortable: false,
      width: isSmallDevice ? 150 : 400, // Imposta una larghezza fissa
      pinned: "right", // Fissa la colonna a destra
      renderCell: (params) =>
        renderButtonsBasedOnStatus(params.row.status, params.row.id),
      cellClassName: "actions-column",
    };
  };

  const handleNewPasswordAccount = async (userId) => {
    if (!userId) {
      console.log("Disabling button: No email provided");
      toast.error("Inserisci un indirizzo email.");
      return;
    }

    try {
      const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/newUserPassword`;

      // L'invio dei dati è asincrono, quindi uso await per attendere la risposta
      const response = await axios.post(endpoint, { userId: userId });

      // Controlla lo stato della risposta
      if (response.status === 200) {
        // Operazione riuscita
        toast.success(
          "Controlla la tua email per le istruzioni sulla reimpostazione della password."
        );
      } else {
        // Se il server ritorna uno stato diverso da 200, mostra comunque un errore
        toast.error("Errore durante l'invio della nuova password.");
      }
    } catch (error) {
      // Gestisce sia errori di rete che errori restituiti dal server (es. 4xx, 5xx)
      const errorMessage =
        error.response?.data?.message ||
        "Errore durante l'invio della nuova password.";
      console.error(
        "Errore durante l'invio della nuova password:",
        errorMessage
      );
      toast.error(errorMessage);
    }
  };

  const getColumnsForUsertable = () => {
    if (currentFormType !== "User" || isSmallDevice) {
      return null;
    }

    const renderButtonForUserTable = (status, rowId) => {
      if (currentFormType == "User") {
        return (
          <>
            <Button
              color="success"
              onClick={() => handleNewPasswordAccount(rowId)}
              style={{ marginRight: 0 }}
            >
              <Button size="small">Invia Nuova Password</Button>
            </Button>
          </>
        );
      }
    };

    return {
      id: "approvalColumnUser", // Aggiungi una chiave univoca
      field: "actions",
      headerName: "Gestisci Utente",
      sortable: false,
      width: isSmallDevice ? 150 : 300, // Imposta una larghezza fissa
      pinned: "right", // Fissa la colonna a destra
      renderCell: (params) =>
        renderButtonForUserTable(params.row.status, params.row.id),
      cellClassName: "actions-column",
    };
  };

  const DeleteButtonCustom = ({ checkboxSelected }) => {
    const handleDelete = async () => {
      setDeleteConfirmDialog({
        open: true,
        selectedRows: checkboxSelected,
      });
    };

    return (
      <Button
        color="error"
        onClick={handleDelete}
        disabled={!checkboxSelected || checkboxSelected.length === 0}
        sx={{
          gap: "5px",
          background: "red",
          border: "solid",
          color: "#fff",
          padding: "5px 15px",
          lineHeight: "20px",
          fontSize: "14px",
          borderRadius: "5px",
          "&:hover": {
            background: "#fff",
            color: "red",
            border: "solid",
          },
          ...(checkboxSelected &&
            checkboxSelected.length === 0 && {
              background: "lightGrey",
              cursor: "not-allowed",
              color: "white",
            }),
        }}
      >
        ELIMINA
        <DeleteIcon sx={{ fontSize: "medium" }} />
      </Button>
    );
  };

  // const columnsWithEditIcon = useMemo(
  //   () =>
  //     [
  //       ...columnsData,
  //       {
  //         field: "status",
  //         width: 150,
  //         renderCell: (params) => (
  //           <StatusCell status={params.row.status} rowId={params.row.id} />
  //         ),
  //       },
  //       getApprovalColumn(),
  //     ].filter(Boolean),
  //   [columnsData, currentFormType]
  // );

  function StatusCellRenderer(params) {
    return <StatusCell status={params.row.status} rowId={params.row.id} />;
  }

  const columnsWithEditIcon = useMemo(
    () =>
      [
        ...columnsData.map((column) => {
          if (column.field === "status") {
            return {
              ...column,
              renderCell: StatusCellRenderer,
            };
          }
          return column;
        }),
        getApprovalColumn(),
        getColumnsForUsertable(),
      ].filter(Boolean),
    [columnsData, currentFormType]
  );

  const CustomToolbar = () => {
    const location = useLocation();
    const currentFormType = location.pathname.split("/")[2];
    const isAnyRowSelected = checkboxSelected !== 0;

    const ToolbarButtonStyle = {
      background: "#66C9BA",
      border: "solid",
      color: "#fff",
      padding: "5px 15px",
      lineHeight: "20px",
      fontSize: "14px",
      borderRadius: "5px",
      "&:hover": {
        background: "#fff",
        color: "#66C9BA",
        border: "solid",
      },
    };

    const formTypeLabels = {
      tipologieImmobili: "Tipi di Immobili",
      immobile: "Tutti gli Immobili",
      ordine: "Gestione Ordini",
      commission: "Commissioni",
      extras: "Opzioni Extra",
      User: "Utenti",
      agenzie: "Agenzie",
      contratti: "Contratti",
      // Aggiungi qui altre mappature secondo necessità
    };

    const renderHeading = () => {
      const label = formTypeLabels[currentFormType] ?? "Label di default";

      return (
        <>
          <Typography variant="h5" sx={{ paddingLeft: 0 }}>
            <AccountBoxIcon
              fontSize="large"
              style={{ verticalAlign: "middle" }}
            />{" "}
            {label.charAt(0).toUpperCase() + label.slice(1)}
          </Typography>
        </>
      );
    };

    const renderActionButtons = () => {
      const isAnyRowSelected = checkboxSelected !== 0;

      return (
        <>
          <DeleteButtonCustom
            selectedRowId={selectedRowId}
            checkboxSelected={checkboxSelected}
          ></DeleteButtonCustom>

          <Link to={"/adminList/" + currentFormType + "/create/"}>
            <Button color="success" sx={ToolbarButtonStyle}>
              AGGIUNGI +
            </Button>
          </Link>
        </>
      );
    };

    return (
      <GridToolbarContainer
        sx={{
          padding: "20px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Contenuto allineato a sinistra */}
        <div>{renderHeading()}</div>

        {/* Contenuto allineato a destra */}
        <div style={{ display: "flex", alignItems: "middle" }}>
          <GridToolbarQuickFilter sx={{ marginLeft: 1, marginRight: 1 }} />

          <GridToolbarColumnsButton sx={ToolbarButtonStyle} />
          <GridToolbarFilterButton sx={ToolbarButtonStyle} />
          <GridToolbarDensitySelector />
          <GridToolbarExport sx={ToolbarButtonStyle} />
          {renderActionButtons()}
        </div>
      </GridToolbarContainer>
    );
  };

  const handleConfirmApprove = async () => {
    const { rowId, status } = confirmDialog;
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/editHouseStatus",
        { id: rowId, status: status },
        { headers: headers }
      );

      if (response.status === 200) {
        // Aggiorna lo stato del componente con i nuovi dati

        // Aggiorna la riga corrispondente con il nuovo stato
        const updatedRows = rowsData.map((row) => {
          if (row.id === rowId) {
            // Assumiamo che 'status' sia il campo da aggiornare
            return { ...row, status: status };
          }
          return row;
        });

        setFormFieldsRows(updatedRows);

        toast.success("Stato aggiornato con successo");
      } else {
        throw new Error("Errore nella risposta API");
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento dello stato:", error);
      toast.error("Errore nell'aggiornamento dello stato: " + error.message);
    } finally {
      setConfirmDialog({ open: false, rowId: null, status: null });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {localLoader ? (
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Typography style={{ marginBottom: "1rem" }} variant="body1">
                Caricamento...
              </Typography>
              <CircularProgress />
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card sx={{ margin: 3 }}>
              <CardContent>
                <DataGridPro
                  rows={rowsData}
                  columns={columnsWithEditIcon}
                  getRowId={(row) => row.id}
                  initialState={{ pinnedColumns: { right: ["actions"] } }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  rowHeight={40}
                  checkboxSelection
                  onRowSelectionModelChange={handleSelectionChange}
                  disableRowSelectionOnClick
                  disableDensitySelector
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    width: isSmallDevice ? "100vh" : "auto",
                    maxHeight: "80vh",
                    minHeight: "80vh",
                    overflowX: "auto",
                  }}
                  getRowClassName={(params) => {
                    const { row } = params;
                    if (row.status === "Sospesa") {
                      return "suspended-row";
                    }
                    return undefined;
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Dialog open={deleteConfirmDialog.open} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          {"Conferma Eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={handleConfirmDelete} autoFocus color="error">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialog.open} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">{"Conferma Azione"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler procedere con questa azione?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={handleConfirmApprove} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: "0px",
          },
        }}
      >
        <DialogTitle>
          <>Crea un nuovo {currentFormType}</>
        </DialogTitle>
        <DialogContent>
          <DynamicWizard
            objectType={currentFormType}
            onClose={handleCloseModal}
            reloadRecords={getJsonField}
          />
        </DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Richiedi Modifiche</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox1}
                onChange={handleCheckboxChange}
                name="checkbox1"
              />
            }
            label="Dati anagrafici"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox2}
                onChange={handleCheckboxChange}
                name="checkbox2"
              />
            }
            label="Documentazione"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox3}
                onChange={handleCheckboxChange}
                name="checkbox3"
              />
            }
            label="Foto o video"
          />
          <TextField
            margin="dense"
            id="modificaText"
            label="Dettagli Modifica"
            type="text"
            fullWidth
            variant="outlined"
            value={modificaText}
            onChange={handleModificaTextChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Annulla</Button>
          <Button onClick={handleEditDialogSubmit}>Invia</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default List;
