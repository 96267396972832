import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { customer, admin, agenzia, master, superAgency } from "./sidebar";
import Button from "@mui/material/Button";
import { thunks } from "../../thunks/general";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack, List } from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#ffffff",
  boxShadow: "none",
  width: "100%",
  marginLeft: drawerWidth,
  ...(open && {
    marginLeft: 0,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "fixed",
    top: "64px",
    height: "calc(100% - 64px)",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#66C9BA",
    justifyContent: "space-between",
    padding: "20px 0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      width: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  },
}));

const defaultTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "10px 14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: "0!important",
        },
        input: {
          padding: "10px 14px",
        },
        notchedOutline: {
          top: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          marginTop: "0!important",
          padding: "10px 14px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "0!important",
        },
      },
    },
    MuiTextareaAutosize: {
      styleOverrides: {
        root: {
          padding: "10px 14px",
          marginTop: "0!important",
        },
      },
    },
  },
});

export const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(!isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const general = useSelector((state) => state.general);

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobile]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              alignItems="left"
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "flex-end",
                padding: 0,
                gap: 1,
              }}
            >
              {open ? (
                <IconButton
                  edge="false"
                  onClick={toggleDrawer}
                  sx={{ "&:hover": { background: "none" } }}
                >
                  <ChevronLeftIcon />
                </IconButton>
              ) : (
                <IconButton
                  edge="false"
                  color="black"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{ "&:hover": { background: "none" } }}
                  /*  sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }} */
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Box className="logo" sx={{ flex: 1 }}>
                <img
                  src="https://www.biddingwave.it/wp-content/uploads/2024/02/bidding-wave-crop.png"
                  alt="Logo"
                  style={{
                    width: "220px",
                  }}
                />
                {/* <Typography
                  color="black"
                  variant="h7"
                  fontSize="11px"
                  display="block"
                  lineHeight="1.2"
                >
                  Hai effettuato l'accesso come <b>{general.temporaryRole}</b>
                </Typography> */}
              </Box>
              <Box sx={{ color: "black", marginTop: 1 }}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{ margin: 0 }}
                >
                  Benvenuto,{" "}
                  {general.username ? general.username : general.temporaryRole}
                </Typography>
                <Typography gutterBottom variant="caption" component="div">
                  Hai effettuato l'accesso come,{" "}
                  {general.temporaryRole ? general.temporaryRole : "N/A"}
                </Typography>
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <List component="nav">
            {general.temporaryRole == "Customer" && customer}
            {general.temporaryRole == "Admin" && admin}
            {general.temporaryRole == "Agenzia" && agenzia}
            {general.temporaryRole == "Super Agency" && superAgency}
            {general.temporaryRole == "Master" && master}
          </List>
          <Box sx={{ padding: "0 20px" }}>
            <Button
              variant="contained"
              color="error"
              onClick={(e) => {
                localStorage.removeItem("token");
                dispatch(thunks.resetState());
                navigate("/");
              }}
              sx={{ boxShadow: "none" }}
            >
              LogOut
            </Button>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            transition: "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1)",
            marginLeft: open ? `${drawerWidth}px` : "0",
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
