import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';

const Datetime = ({ field, value, onChange }) => {
  const handleDateChange = (newValue) => {
    onChange(field.fieldName, newValue);
  };

  return (
      <DateTimePicker
          label={field.label}
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
      />
  );
};

export default Datetime;
