import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CardContent,
  Grid,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function PhotoForm({ prefilledValues, objectType, recordId }) {
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const location = useLocation();
  const currentFormType = objectType ? objectType : location.pathname.split("/")[2];
  const currentRecordId = recordId ? recordId : location.pathname.split("/")[4];

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;

        const getRecord = axios.get(
          `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
          { headers }
        );
        const getFields = axios.get(
          `${endpoint}api/auth/getFieldsForObjectForm/${currentFormType}`,
          { headers }
        );

        const [recordResponse, fieldsResponse] = await Promise.all([
          getRecord,
          getFields,
        ]);

        setFormData(recordResponse.data);
        setFieldsData(fieldsResponse.data);
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data
          : "Errore durante il recupero dei dati.";
        toast.error(errorMessage);
      }
    };

    if (currentFormType && currentRecordId) {
      fetchFormData();
    }
  }, [currentFormType, currentRecordId]);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const renderFields = () => {
    return Object.entries(fieldsData).map(([sectionKey, fields]) => (

      
      <Grid container spacing={2} key={sectionKey}>
        {fields.map((field, index) => {
          if (field.type !== "Photo" || !formData[field.fieldName]) {
            return null;
          }
          return (
            <Grid item xs={4} key={index}>
              <img
                src={formData[field.fieldName]}
                alt={field.label}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => handleOpenModal(formData[field.fieldName])}
              />
            </Grid>
          );
        })}
      </Grid>
    ));
  };

  return (
    <>
      <CardContent>
        {renderFields()}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={styleModal}>
            <img src={selectedImage} alt="Ingrandimento foto" style={{ maxWidth: '100%' }} />
          </Box>
        </Modal>
      </CardContent>
    
    </>
  );
}
