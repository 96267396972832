import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// ... altri import necessari ...

const AutocompleteJSON = ({ field, fieldName, formType, isCreate, isEdit, isShow, values, fieldErrors, onChange, value }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const fieldRef = useRef(null);



    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const endpoint = process.env.REACT_APP_APP_ENDPOINT + `api/auth/getRecords/${field.relatedObject}`;
                const response = await axios.get(endpoint, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                if (Array.isArray(response.data)) {
                    let extractedOptions = [];

                    if (field.relatedObject === 'User') {
                        extractedOptions = response.data.map(user => ({
                            id: user.id,
                            label: `${user.name} (${user.email}) - ${user.temporaryRole}`,
                        }));
                    } else {
                        extractedOptions = response.data.map(item => ({
                            id: item.id,
                            label: item.title
                        }));
                    }

                    setOptions(extractedOptions);

                    if (value) {

                        const selected = extractedOptions.find(option => option.id == value);
                        console.log('Selected option:', selected);
                        setSelectedOption(selected || null);

                        onChange(fieldName, value);
                    }

                    if (values && values[fieldName]) {
                        const selected = extractedOptions.find(option => option.id === values[fieldName]);
                        console.log('Selected option:', selected);
                        setSelectedOption(selected || null);
                        onChange(fieldName, values[fieldName]);

                    }
                }
            } catch (error) {
                console.error('Errore nella chiamata all\'endpoint:', error);
            }
        };

        fetchOptions();
    }, [field, value]);

    const handleSelectionChange = (event, newValue) => {
        setSelectedOption(newValue);
        onChange(field.name, newValue ? newValue.id : null);
    };

    useEffect(() => {
        if (fieldErrors && fieldRef.current) {
            fieldRef.current.style.border = '2px solid red';
        }
    }, [fieldErrors]);

    const getOptionLabel = (option) => option.label;

    return (
        <Autocomplete

            sx={{ height: "50px" }}
            ref={fieldRef}
            multiple={false}
            name={field.name}
            options={options}
            placeholder="Clicca per scrivere"
            getOptionLabel={getOptionLabel}
            value={selectedOption}
            onChange={handleSelectionChange}
            renderInput={(params) => (
                <TextField {...params} label="Clicca per cercare" variant="standard" error={Boolean(fieldErrors)} helperText={fieldErrors || ''} />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />)
            }
        />
    );
};

export default AutocompleteJSON;
