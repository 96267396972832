import React, { useState, useEffect } from "react";
import Carousel, { slidesToShowPlugin } from "react-material-ui-carousel";
import { Grid, IconButton, Dialog, DialogContent, Hidden } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ImageCarousel = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    const safeImages = images || [];

    const filteredImages = safeImages[0] || [];

    setAllImages([...filteredImages]);
  }, [images]);

  const openLightbox = (index) => {
    setSelectedImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const handleArrowClick = (direction) => {
    const newIndex =
      direction === "forward" ? selectedImage + 1 : selectedImage - 1;
    setSelectedImage(
      (prevIndex) => (newIndex + allImages.length) % allImages.length
    );
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "start", margin: 0, width: "100%" }}
    >
      <Grid item xs={12} md={6} style={{ margin: 0, padding: 0 }}>
        <Carousel
          plugins={[
            "centered",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
          ]}
          animation="fade"
          indicators={false}
        >
          {allImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              onClick={() => openLightbox(index)}
              style={{ cursor: "pointer", width: "100%", objectFit: "cover" }}
            />
          ))}
        </Carousel>
      </Grid>
      <Hidden smDown mdDown>
        <Grid item xs={6} sx={{ paddingTop: "0!important" }}>
          <Grid
            container
            spacing={0}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
              width: "100%",
            }}
          >
            {allImages.slice(0, 4).map((image, index) => (
              <Grid key={index} item /* xs={6} md={6} */>
                <img
                  src={image}
                  alt={`Image ${index}`}
                  onClick={() => openLightbox(index)}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    padding: "0!important",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Hidden>
      <Dialog
        open={lightboxOpen}
        maxWidth="lg"
        onClose={closeLightbox}
        sx={{ overflow: "hidden" }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeLightbox}
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={() => handleArrowClick("back")}
            sx={{ position: "absolute", top: "50%", left: 8, zIndex: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => handleArrowClick("forward")}
            sx={{ position: "absolute", top: "50%", right: 8, zIndex: 1 }}
          >
            <ArrowForwardIcon />
          </IconButton>
          <img
            src={allImages[selectedImage]}
            alt={`Lightbox ${selectedImage}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              padding: 0,
              margin: 0,
            }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ImageCarousel;
