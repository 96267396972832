import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { itIT, DataGridPro } from "@mui/x-data-grid-pro";
import {
  Grid,
  TextField,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

const ListImmobileAgenzia = () => {
  const navigate = useNavigate();
  const [localLoader, setLocalLoader] = useState(false);
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [activeData, setActiveData] = useState([]);
  const [activeFilterText, setActiveFilterText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };
        setLocalLoader(true);
        const response = await axios.get(
          `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getRecords/immobile?owner=me`,
          { headers }
        );
        const responseData = response.data;
        setData(responseData);
        setActiveData(responseData.filter((item) => item.status === "Attiva"));
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLocalLoader(false);
      }
    };

    fetchData();
  }, []);

  const handleRowClick = (id) => {
    navigate(`/adminList/immobile/show/${id}`);
  };

  const handleFilterChange = (event, setter) => {
    setter(event.target.value);
  };

  const filterData = (data, filter) =>
    data.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(filter.toLowerCase())
      )
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 1,
                  py: 1,
                }}
              >
                <Typography variant="h5">Elenco Immobili</Typography>
                <TextField
                  label="Cerca"
                  value={filterText}
                  onChange={(e) => handleFilterChange(e, setFilterText)}
                  style={{ marginBottom: 10, padding: 3 }}
                />
              </Box>
              <FilteredDataGrid
                rows={filterData(data, filterText)}
                localLoader={localLoader}
                handleRowClick={handleRowClick}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 1,
                  py: 1,
                }}
              >
                <Typography variant="h5">Elenco Immobili Attivi</Typography>
                <TextField
                  label="Cerca"
                  value={activeFilterText}
                  onChange={(e) => handleFilterChange(e, setActiveFilterText)}
                  style={{ marginBottom: 10, padding: 3 }}
                />
              </Box>
              <FilteredDataGrid
                rows={filterData(activeData, activeFilterText)}
                localLoader={localLoader}
                handleRowClick={handleRowClick}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const FilteredDataGrid = ({ rows, localLoader, handleRowClick }) => {
  const columns = [
    { field: "title", headerName: "Titolo", flex: 1, sortable: true },
    { field: "type", headerName: "Tipologia", flex: 1, sortable: true },
    { field: "status", headerName: "Stato", flex: 1, sortable: true },
    {
      field: "firstImage",
      headerName: "Immagine",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Immagine"
          style={{ width: 100, height: 100 }}
        />
      ),
    },
    {
      field: "codiceImmobile",
      headerName: "Codice Immobile",
      flex: 1,
      sortable: true,
    },
    {
      field: "id",
      headerName: "Azioni",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{
            background: "#66C9BA",
            border: "solid",
            color: "#fff",
            padding: "5px 15px",
            lineHeight: "20px",
            fontSize: "14px",
            borderRadius: "5px",
            "&:hover": {
              background: "#fff",
              color: "#66C9BA",
              border: "solid",
            },
          }}
          onClick={() => handleRowClick(params.value)}
        >
          Dettagli
        </Button>
      ),
    },
  ];

  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      loading={localLoader}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5, 10, 20]}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
};

export default ListImmobileAgenzia;
