import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Skeleton,
  FormControlLabel,
  Drawer,
  useMediaQuery,
  Checkbox,
  Container,
} from "@mui/material";
import { NewReleases as NewReleasesIcon } from "@mui/icons-material";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "styled-components";
import { CardMedia } from "@mui/material";
import TempoScadenza from "components/icons/TempoScadenza";
import UltimaOfferta from "./UltimaOfferta";
import RilancioMinimo from "./RilancioMinimo";
import RilanciTotali from "components/icons/RilanciTotali";
import SuperficieIcon from "components/icons/Supercie";
import LocaliIcon from "components/icons/Locali";
import Header from "components/Header";
import { useLocation, useParams } from "react-router-dom";
import { Autocomplete, LoadScript } from "@react-google-maps/api";

const AstePubbliche = () => {
  const [cards, setCards] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMobileOrTablet = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [lookupOptions, setLookupOptions] = useState({});
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const autocompleteRef = useRef(null);

  const fetchLookupOptions = async () => {
    filterConfigs.forEach(async (filterConfig) => {
      if (
        filterConfig.filterType === "lookup" ||
        filterConfig.filterType === "multiplelookup"
      ) {
        try {
          const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
          };
          const response = await axios.get(
            process.env.REACT_APP_APP_ENDPOINT +
              `api/getRecordsView/${filterConfig.relatedObject}`,
            { headers: headers }
          );
          // Mappa l'array di risposta per creare un formato adatto al componente Select
          const options = response.data.map((item) => ({
            value: item.id, // Utilizza 'id' come valore
            label: item.title, // Utilizza 'title' come etichetta
          }));
          setLookupOptions((prev) => ({
            ...prev,
            [filterConfig.fieldName]: options,
          }));
        } catch (error) {
          console.error("Errore nel recupero delle opzioni di lookup:", error);
        }
      }
    });
  };

  const handleResetFilters = () => {
    // Resetta tutti i filtri ai valori di default
    const defaultFilters = {}; // Assumi che questo sia l'oggetto dei filtri di default
    setSelectedFilters(defaultFilters);

    // Resetta l'URL alla sua forma base o rimuovi i parametri specifici dei filtri
    navigate("/ricerca-pubblica");

    // Esegui una nuova ricerca con i filtri resettati
    setIsReadyToFetch(false); // Assicurati che i componenti siano pronti a gestire la fetch
    setTimeout(() => {
      setIsReadyToFetch(true); // Assicurati di impostare questo flag a true solo dopo che tutti i preparativi sono completi
      fetchData(defaultFilters); // Chiama la funzione di fetch passando i filtri di default o un oggetto vuoto
    }, 0);
  };

  useEffect(() => {
    const init = async () => {
      await fetchLookupOptions(); // Assicurati che questa funzione sia asincrona e attendi il suo completamento
      updateFiltersFromParams(); // Questa funzione imposta i filtri basati sui parametri dell'URL
      setIsReadyToFetch(true); // Imposta la prontezza solo dopo il completamento di tutte le operazioni necessarie
    };
    init();
  }, [filterConfigs]); // Potresti dover aggiustare questa dipendenza in base a come viene gestito il fetch delle opzioni

  useEffect(() => {
    fetchFilterConfigs("immobile");
    setLoading(false);
  }, []);

  const findIdByLabel = (filterName, label) => {
    const options = lookupOptions[filterName];
    const option = options?.find((option) => option.label === label);
    return option ? option.value : null;
  };

  useEffect(() => {
    if (
      Object.keys(lookupOptions).length ===
      filterConfigs.filter((config) => config.filterType === "lookup").length
    ) {
      updateFiltersFromParams();
    }
  }, [params, filterConfigs, lookupOptions]);

  const updateFiltersFromParams = () => {
    const updatedFilters = {};

    filterConfigs.forEach((filterConfig) => {
      const paramName = filterConfig.fieldName;
      const paramValue = params[paramName];

      // Se il parametro esiste e il filtro è di tipo lookup, cerca l'id corrispondente alla label

      if (paramValue !== undefined) {
        if (filterConfig.filterType === "lookup") {
          const id = findIdByLabel(paramName, paramValue);
          if (id) {
            updatedFilters[paramName] = id;
          }
        } else {
          // Per altri tipi di filtro, imposta direttamente il valore

          updatedFilters[paramName] = paramValue;
        }
      }
    });

    // Applica i filtri solo se ci sono valori validi
    setSelectedFilters((prev) => ({
      ...prev,
      ...updatedFilters,
    }));
  };

  useEffect(() => {
    if (isReadyToFetch) {
      fetchData(selectedFilters);
    }
  }, [selectedFilters, isReadyToFetch, lookupOptions, params]);

  const fetchFilterConfigs = async (
    objectName,
    relatedObjects = ["immobile"]
  ) => {
    try {
      setLoading(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/getFieldsForObjectForm/${objectName}`,
        { headers: headers }
      );

      let filtersForObject = [];

      // Estrazione dei campi filtrabili
      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const fields = response.data[key];
          const filteredFields = fields
            .filter((field) => field.isFiltrable)
            .map((field) => ({ ...field, objectName }));
          filtersForObject = filtersForObject.concat(filteredFields);
        }
      }

      setFilterConfigs((prev) => [...prev, ...filtersForObject]);

      // Recupero delle configurazioni per gli oggetti correlati
      relatedObjects.forEach((relatedObject) => {
        if (
          !filtersForObject.some(
            (config) => config.objectName === relatedObject
          )
        ) {
          fetchFilterConfigs(relatedObject);
        }
      });
    } catch (error) {
      console.error(
        `Errore nel recupero della configurazione dei filtri per ${objectName}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (selectedFilters) => {
    try {
      setLoadingCard(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: `application/json`,
        "Content-Type": `application/json`,
      };

      let filtersPayload = {
        immobile: {
          status: ["Attiva", "Conclusa", "Aggiudicata"],
        },
      };

      // Creazione del payload per i filtri
      filterConfigs.forEach((config) => {
        const objectName = config.objectName || "default";
        const fieldName = config.fieldName;
        const filterValue = selectedFilters[fieldName];

        if (filterValue !== undefined) {
          if (!filtersPayload[objectName]) {
            filtersPayload[objectName] = {};
          }

          switch (config.filterType) {
            case "range":
              if (!filtersPayload[objectName][fieldName]) {
                filtersPayload[objectName][fieldName] = {};
              }
              filtersPayload[objectName][fieldName].min = filterValue.min;
              filtersPayload[objectName][fieldName].max = filterValue.max;
              filtersPayload[objectName][fieldName].step = filterValue.step;
              break;
            default:
              // Se il campo già esiste, lo trasformiamo in un array e aggiungiamo il nuovo valore
              if (filtersPayload[objectName][fieldName]) {
                if (!Array.isArray(filtersPayload[objectName][fieldName])) {
                  filtersPayload[objectName][fieldName] = [
                    filtersPayload[objectName][fieldName],
                  ];
                }
                filtersPayload[objectName][fieldName].push(filterValue);
              } else {
                filtersPayload[objectName][fieldName] = filterValue;
              }
              break;
          }
        }
      });

      const queryString = `filterPayload=${encodeURIComponent(
        JSON.stringify(filtersPayload)
      )}`;
      const url = `${process.env.REACT_APP_APP_ENDPOINT}api/getRecordsView/immobile?${queryString}`;

      const response = await axios.get(url, { headers: headers });

      setCards(() => [...response.data]);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoadingCard(false);
    }
  };

  const getLabelFromId = (filterName, id) => {
    const options = lookupOptions[filterName];
    const option = options.find((option) => option.value === id);
    return option ? option.label : id;
  };

  const buildRouteFromFilters = (filters) => {
    let route = "/ricerca-pubblica";

    // Definizione dell'ordine e dei placeholder per gli attributi URL
    const attributesOrder = [
      { key: "houseType", placeholder: "Tipo-Casa" },
      { key: "type", placeholder: "Tipo-Contratto" }, // Assumo che 'type' corrisponda al tipo di contratto in questo contesto
      { key: "city", placeholder: "Citta" },
      { key: "condition", placeholder: "Condizione" }, // Aggiungi altri attributi qui se necessario
    ];

    // Costruzione dell'URL basata sugli attributi definiti
    attributesOrder.forEach((attr) => {
      if (filters[attr.key]) {
        // Controllo se il valore è una stringa o necessita di traduzione tramite getLabelFromId
        const value =
          typeof filters[attr.key] === "string"
            ? encodeURIComponent(filters[attr.key])
            : encodeURIComponent(getLabelFromId(attr.key, filters[attr.key]));
        route += `/${value}`;
      } else {
        // Includi il placeholder per attributi mancanti se desiderato
        // route += `/${attr.placeholder}`; // Decommenta se vuoi usare un placeholder
      }
    });

    return route;
  };

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters((prev) => {
      // Verifica se il valore è vuoto
      if (value === "" || value == null) {
        // Crea una copia dei filtri escludendo il filtro vuoto
        const { [filterName]: _, ...remainingFilters } = prev;
        return remainingFilters;
      } else {
        // Aggiorna il filtro con il nuovo valore
        return {
          ...prev,
          [filterName]: value,
        };
      }
    });

    setSelectedFilters((prev) => {
      const newFilters = value
        ? { ...prev, [filterName]: value }
        : Object.keys(prev).reduce((acc, key) => {
            if (key !== filterName) {
              acc[key] = prev[key];
            }
            return acc;
          }, {});

      // Costruzione e navigazione verso la nuova rotta
      const newRoute = buildRouteFromFilters(newFilters);
      navigate(newRoute);

      return newFilters;
    });
  };
  const onLoad = (autocompleteRef) => {
    console.log("Autocomplete caricato:", autocompleteRef);
  };

  const handlePlaceChange = (fieldName) => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place) {
        const city = place.address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        if (city) {
          handleFilterChange(fieldName, city);
        }
      }
    } else {
      console.log("Autocomplete non è ancora pronto.");
    }
  };

  const handleUpdateClick = () => {
    fetchData(selectedFilters);
  };

  const generateFilterComponent = (filterConfig) => {
    switch (filterConfig.filterType) {
      case "range":
        const minOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          filterConfig.step !== undefined ? filterConfig.step : 50
        );
        const maxOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          filterConfig.step !== undefined ? filterConfig.step : 50
        );

        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                value={selectedFilters[filterConfig.fieldName]?.min || ""}
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    min: e.target.value,
                  })
                }
                displayEmpty
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <MenuItem key="empty" value="" selected>
                  Seleziona un'opzione...
                </MenuItem>
                {minOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={selectedFilters[filterConfig.fieldName]?.max || ""}
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    max: e.target.value,
                  })
                }
                displayEmpty
                sx={{ width: "100%", borderRadius: "8px" }}
              >
                <MenuItem key="empty" value="" selected>
                  Seleziona un'opzione...
                </MenuItem>
                {minOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        );
      case "select":
        const options = filterConfig?.options?.split(",") || [];

        return (
          <Select
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
            displayEmpty
            sx={{ width: "100%", borderRadius: "8px" }}
          >
            <MenuItem key="default" value="">
              Seleziona
            </MenuItem>

            {options.map((option, index) => (
              <MenuItem key={index} value={option.trim()}>
                {option.trim()}
              </MenuItem>
            ))}
          </Select>
        );
      case "lookup":
        const lookupFilterOptions = lookupOptions[filterConfig.fieldName] || [];
        return (
          <Select
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
            displayEmpty
            sx={{ width: "100%", borderRadius: "8px" }}
          >
            <MenuItem key="default" value="">
              Seleziona
            </MenuItem>
            {lookupFilterOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      case "multiplelookup":
        const multipleLookupOptions =
          lookupOptions[filterConfig.fieldName] || [];
        return (
          <Select
            multiple
            value={selectedFilters[filterConfig.fieldName] || []}
            onChange={(e) => {
              // Gestisce l'aggiornamento per selezioni multiple
              const { value } = e.target;
              handleFilterChange(filterConfig.fieldName, value);
            }}
            renderValue={(selected) =>
              selected
                .map(
                  (val) =>
                    multipleLookupOptions.find((option) => option.value === val)
                      ?.label || val
                )
                .join(", ")
            }
            displayEmpty
            sx={{ width: "100%", borderRadius: "8px" }}
          >
            <MenuItem disabled value="">
              <em>Seleziona</em>
            </MenuItem>
            {multipleLookupOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                <Checkbox
                  checked={
                    selectedFilters[filterConfig.fieldName]?.indexOf(
                      option.value
                    ) > -1
                  }
                />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      case "autocompleteGoogle":
        return (
          <LoadScript
            googleMapsApiKey="AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA"
            libraries={["places"]} // Questo carica la libreria Places necessaria per Autocomplete
          >
            <Autocomplete
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
              }}
              onPlaceChanged={() => handlePlaceChange(filterConfig.fieldName)}
              restrictions={{ country: "it" }} // Esempio di restrizione per l'Italia
            >
              <TextField
                fullWidth
                label={filterConfig.label || "Seleziona una città"}
                variant="outlined"
                sx={{ width: "100%", borderRadius: "8px" }}
              />
            </Autocomplete>
          </LoadScript>
        );
      case "boolean":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!selectedFilters[filterConfig.fieldName]} // !! per convertire in booleano
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, e.target.checked)
                }
                name={filterConfig.fieldName}
                color="primary"
              />
            }
          />
        );

      default:
        return (
          <TextField
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
          />
        );
    }
  };

  function generateRangeOptions(min, max, step = 1) {
    const options = [];
    for (let i = min; i <= max; i += step) {
      options.push(i);
    }
    return options;
  }

  const goToRoute = (id) => {
    navigate(`/asta/${id}`);
  };

  // Componenti stilizzati con Styled Components
  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));
  const ApplyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#94d9cf",
    color: "#0d7267",
    borderRadius: "20px",
    padding: "10px 0 10px 0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));
  const ResetButton = styled(Button)(({ theme }) => ({
    backgroundColor: "red",
    color: "#94d9cf",
    borderRadius: "20px",
    padding: "10px 0 10px 0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));

  return (
    <>
      <Header />
      <Container maxWidth>
        <Grid
          container
          spacing={1}
          sx={{
            px: 4,
            py: 4,
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            },
          }}
        >
          {/* Colonna da 9 con le card */}
          <Grid item xs={12} sm={9}>
            <Box p={2}>
              <Grid container spacing={2}>
                {isSmallScreen && (
                  <Drawer
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                  >
                    <Card>
                      <CardHeader title="Filtra le aste disponibili" />
                      <CardContent>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <>
                            {filterConfigs.map((filterConfig, index) => (
                              <div key={index} style={{ marginBottom: "20px" }}>
                                <Typography variant="subtitle1">
                                  {filterConfig.label}
                                </Typography>
                                {generateFilterComponent(filterConfig)}
                              </div>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleUpdateClick}
                              sx={{ mt: 2 }}
                            >
                              {loading ? (
                                <Skeleton width={150} height={36} />
                              ) : (
                                "Applica Filtri"
                              )}
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{ ml: 1, mt: 2 }}
                            >
                              {loading ? (
                                <Skeleton width={100} height={36} />
                              ) : (
                                "Reset"
                              )}
                            </Button>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Drawer>
                )}

                <Grid item xs={12} container spacing={2}>
                  {loadingCard
                    ? Array.from({ length: 6 }).map((_, index) => (
                        <Grid item xs={4} key={index}>
                          <Card>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={200}
                              sx={{
                                borderRadius: "8px 8px 0 0",
                                backgroundColor: "#e0e0e0",
                              }}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                <Skeleton
                                  animation="wave"
                                  height={20}
                                  width="80%"
                                />
                              </Typography>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#e0e0e0",
                                    borderRadius: "50%",
                                    marginRight: "8px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="40%"
                                  />
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    : cards.map((card) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={card.id}>
                          <Card
                            sx={{
                              boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.15)",
                              borderRadius: "10px",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <CardMedia
                                component="img"
                                alt="Immagine dell'asta"
                                image={
                                  card?.firstImage ||
                                  "https://placehold.co/1200x1080?text=Nessun+immagine"
                                }
                                sx={{
                                  objectFit: "cover",
                                  maxHeight: 180,
                                  filter:
                                    "drop-shadow(2px 4px 11px rgba(0, 0, 0, 0.15))",
                                  borderRadius: "10px 10px 0px 0px",
                                }}
                              />
                              {card?.houseType && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    left: 10,
                                    background: "#66C9BA",
                                    color: "#01685D",
                                    borderRadius: 5,
                                    color: "black",
                                    padding: "0px 8px 2px 8px",
                                  }}
                                >
                                  {card?.houseType}
                                </span>
                              )}
                              {card?.newConstruction == 1 && (
                                <span
                                  style={{
                                    position: "absolute",
                                    display: "flex",
                                    top: 10,
                                    left: 140,
                                    color: "#ffffff",
                                    backgroundColor: "#66C9BA",
                                    borderRadius: "50%",
                                    padding: "0px",
                                  }}
                                >
                                  <NewReleasesIcon fontSize="small" />
                                </span>
                              )}
                              {card?.type && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    background: "#d32f2f",
                                    borderRadius: 5,
                                    color: "white",
                                    padding: "2px 8px 2px 8px",
                                  }}
                                >
                                  {card?.type}
                                </span>
                              )}
                            </div>
                            <CardContent sx={{ px: 3 }}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <b>{card.title}</b>
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <TempoScadenza />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Scadenza: {card.duration}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <SuperficieIcon
                                  fillColor="#FF0000"
                                  width="20"
                                  height="20"
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Superficie: {card.mq}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <LocaliIcon
                                  fillColor="#FF0000"
                                  width="20"
                                  height="20"
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Numero locali: {card.roomQty}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <UltimaOfferta />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Ultima Offerta:
                                  {card?.actualOffer?.toLocaleString("it-IT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <RilancioMinimo />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Base d'asta:
                                  {card &&
                                    card.actualOffer
                                      .toLocaleString("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })
                                      .replace("€", "")}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mb={2}
                                sx={{ gap: 1.5 }}
                              >
                                <RilanciTotali />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Quantità Offerte: {card.qtyTotalBidsAmount}
                                </Typography>
                              </Box>
                              <ColoredButton
                                sx={{
                                  border: "1.5px solid #66C9BA",
                                  background: "#66C9BA",
                                  color: "#01685D",
                                  borderRadius: "16px",
                                  width: "100%",
                                  "&:hover": {
                                    background: "#66C9BA",
                                    border: "solid #01685D",
                                    color: "#01685D",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                                  },
                                }}
                                size="small"
                                onClick={() => goToRoute(card.id)}
                              >
                                <b>Vai all'asta</b>
                              </ColoredButton>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Colonna da 3 con i filtri */}
          <Grid item xs={12} sm={12} md={3}>
            <Box sx={{ position: "sticky", top: 112 }}>
              {isMobileOrTablet ? (
                <Accordion
                  sx={{
                    borderRadius: "15px!important",
                    px: {
                      xs: 2,
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      sx={{
                        color: "#01685d",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                    >
                      FILTRA LE ASTE DISPONIBILI
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/*  <Card sx={{ borderRadius: "15px" }}> */}
                    {/* <CardHeader
                      sx={{ paddingBottom: 0 }}
                      title={
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#01685d",
                            fontSize: "1.1rem",
                            fontWeight: "700",
                          }}
                        >
                          FILTRA LE ASTE DISPONIBILI
                        </Typography>
                      }
                    /> */}
                    <CardContent sx={{ p: 0 }}>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        <>
                          {filterConfigs.map((filterConfig, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                              <Typography
                                variant="subtitle1"
                                fontWeight="700"
                                fontSize="1.1rem"
                              >
                                {filterConfig.label}
                              </Typography>
                              {generateFilterComponent(filterConfig)}
                            </div>
                          ))}
                          <Box display="flex" justifyContent="center">
                            <ApplyButton
                              variant="contained"
                              onClick={handleUpdateClick}
                              sx={{
                                mt: 2,
                                width: "150px",
                                marginRight: "10px",
                                fontWeight: "700",
                                ml: 1,
                                mt: 2,
                                width: "150px",
                                fontWeight: "700",
                                backgroundColor: "#66c9ba",
                                border: "2px solid transparent",
                                color: "#01685D",
                                borderRadius: "20px",
                                padding: "10px 0 10px 0",
                                "&:hover": {
                                  border: "2px  solid #66c9ba",
                                  backgroundColor: "#01685D",
                                  color: "#66c9ba",
                                },
                              }}
                            >
                              {loading ? (
                                <Skeleton width={150} height={36} />
                              ) : (
                                "FILTRA"
                              )}
                            </ApplyButton>
                            <ResetButton
                              variant="contained"
                              onClick={handleResetFilters} // Utilizza la funzione di reset qui
                              sx={{
                                mt: 2,
                                width: "150px",
                                fontWeight: "700",
                                backgroundColor: "#01685D",
                                border: "2px solid transparent",
                                color: "#66c9ba",
                                borderRadius: "20px",
                                padding: "10px 0 10px 0",
                                "&:hover": {
                                  border: "2px solid #01685D",
                                  backgroundColor: "#66c9ba",
                                  color: "#01685D",
                                },
                              }}
                            >
                              RESET
                            </ResetButton>
                          </Box>
                        </>
                      )}
                    </CardContent>
                    {/*   </Card> */}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Card sx={{ borderRadius: "15px" }}>
                  <CardHeader
                    title={
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#01685d",
                          fontSize: "1.1rem",
                          fontWeight: "700",
                        }}
                      >
                        FILTRA LE ASTE DISPONIBILI
                      </Typography>
                    }
                  />
                  <CardContent sx={{ py: 0 }}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {filterConfigs.map((filterConfig, index) => (
                          <div key={index} style={{ marginBottom: "20px" }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight="700"
                              fontSize="1.1rem"
                            >
                              {filterConfig.label}
                            </Typography>
                            {generateFilterComponent(filterConfig)}
                          </div>
                        ))}
                        <Box display="flex" justifyContent="center">
                          <ApplyButton
                            variant="contained"
                            onClick={handleUpdateClick}
                            sx={{
                              mt: 2,
                              width: "150px",
                              marginRight: "10px",
                              fontWeight: "700",
                              ml: 1,
                              mt: 2,
                              width: "150px",
                              fontWeight: "700",
                              backgroundColor: "#66c9ba",
                              border: "2px solid transparent",
                              color: "#01685D",
                              borderRadius: "20px",
                              padding: "10px 0 10px 0",
                              "&:hover": {
                                border: "2px  solid #66c9ba",
                                backgroundColor: "#01685D",
                                color: "#66c9ba",
                              },
                            }}
                          >
                            {loading ? (
                              <Skeleton width={150} height={36} />
                            ) : (
                              "FILTRA"
                            )}
                          </ApplyButton>
                          <ResetButton
                            variant="contained"
                            onClick={handleResetFilters} // Utilizza la funzione di reset qui
                            sx={{
                              mt: 2,
                              width: "150px",
                              fontWeight: "700",
                              backgroundColor: "#01685D",
                              border: "2px solid transparent",
                              color: "#66c9ba",
                              borderRadius: "20px",
                              padding: "10px 0 10px 0",
                              "&:hover": {
                                border: "2px solid #01685D",
                                backgroundColor: "#66c9ba",
                                color: "#01685D",
                              },
                            }}
                          >
                            RESET
                          </ResetButton>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AstePubbliche;
