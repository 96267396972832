import React, { useEffect, useState } from "react";
import axios from "axios";
import FieldsRenderer from "components/fields-renderer";
import {
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Button,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';

export default function DocumentForm({
  prefilledValues,
  objectType,
  recordId,
}) {
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const currentFormType = objectType
    ? objectType
    : location.pathname.split("/")[2];
  const currentRecordId = recordId ? recordId : location.pathname.split("/")[4];
  const [isEditMode, setIsEditMode] = useState(false);

  const fetchFormData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      const getRecord = axios.get(
        `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
        { headers }
      );
      const getFields = axios.get(
        `${endpoint}api/auth/getFieldsForObjectForm/${currentFormType}`,
        { headers }
      );

      const [recordResponse, fieldsResponse] = await Promise.all([
        getRecord,
        getFields,
      ]);

      setFormData(recordResponse.data);
      setFieldsData(fieldsResponse.data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il recupero dei dati.";
      toast.error(errorMessage);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentFormType && currentRecordId) {
      fetchFormData();
    }
  }, [currentFormType, currentRecordId]);

  const handleSave = async () => {
    try {
      const modeApi = !isEditMode
        ? "storeRecord/" + currentFormType
        : "updateRecord/" + currentFormType + "/" + currentRecordId;

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/" + modeApi,
        formData,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );

      fetchFormData();
      toast.success("Dati salvati con successo!");
      handleSetEdit();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il salvataggio dei dati.";
      toast.error(errorMessage);
    }
  };

  const handleSetEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };
  const renderFields = () => {
    return Object.entries(fieldsData).map(([sectionKey, fields]) => (
      <Grid container spacing={1} key={sectionKey}>
        {fields.map((field) => {
          const isReadOnly = Boolean(field.isReadOnly);
          if (isReadOnly || field.type !== "File") {
            return null;
          }
          return (
            <Grid item xs={12} md={12} key={field.id} sx={{ display: 'flex', alignItems: 'center' }}>
              <InsertDriveFileIcon sx={{ marginRight: 1 }} />
              {isReadOnly ? (
                <></>
              ) : (
                <>
                  <InputLabel htmlFor={field.name} sx={{ display: 'flex', alignItems: 'center' }}>
                    {field.label}
                  </InputLabel>
                  {field.type === "File" && formData[field.fieldName] ? (
                    <Typography sx={{ marginLeft: 1 }}>
                      <a
                        href={formData[field.fieldName]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize:"10px"}}>
                        Visualizza
                      </a>
                    </Typography>
                  ) : (
                    <Typography sx={{ marginLeft: 1, color: "red", fontSize:"12px" }}>{formData[field.fieldName]}
        Mancante
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    ));
  };
  

  return (
    <div sx={{ borderRadius: 0 }}>
      <CardContent>{renderFields()}</CardContent>
    </div>
  );
}
