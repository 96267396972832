import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const SelectCST = ({ field, fieldName, values, error, onChange, value }) => {
    const dispatch = useDispatch();
    const fieldRef = useRef(null);

    const handleInputChange = (e) => {
        onChange(field.fieldName, e.target.value);
    };


    const renderSelectOptions = () => {
        return field.options.split(',').map((option, index) => (
            <MenuItem key={index} value={option.trim()}>
                {option.trim()}
            </MenuItem>
        ));
    };

    return (
        <Select
            id={fieldName}
            name={fieldName}
            onChange={handleInputChange}
            error={Boolean(error)}
            helperText={error || ''}
            ref={fieldRef}
            fullWidth
            defaultValue={values && values[fieldName] ? values[fieldName] : (value ? value : '')}
            sx={{ marginTop: "15px" }}
        >
            <MenuItem value="">Seleziona...</MenuItem>
            {renderSelectOptions()}
        </Select>
    );
};

export default SelectCST;
