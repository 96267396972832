import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Container } from "@mui/material";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledContainer = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide > div {
    width: 100%;
    background-size: cover;
    object-fit: cover;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }
  .slick-slide img {
    display: flex;
    margin: auto;
    max-height: 400px;
    object-fit: cover;
  }
  @media (max-width: 480px) {
    .slick-slide img {
      max-height: 300px;
    }
    .slick-track {
      height: 150px;
    }
  }
`;

export default function SliderImage({ images }) {
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    const safeImages = images[0] || [];
    const filteredImages = safeImages.filter((image) => image);

    setAllImages([...filteredImages]);
  }, [images]);

  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: 20,
          zIndex: 9,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          zIndex: 9,
          left: 20,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <StyledContainer>
      <Slider {...settings}>
        {allImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            style={{ cursor: "pointer", width: "100%", objectFit: "cover" }}
          />
        ))}
      </Slider>
    </StyledContainer>
  );
}
