import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Button,
  Grid,
  Skeleton,
  useMediaQuery,
  useTheme,
  CardMedia,
  Stack,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GavelIcon from "@mui/icons-material/Gavel";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { styled } from "styled-components";

const ColoredButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#66c9ba",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#66c9ba",
  },
}));

const AstaCard = ({ card, goToRoute }) => (
  <Paper>
    <CardMedia
      component="img"
      alt="Immagine dell'asta"
      image={card.imageUrl || "https://placehold.co/1200x1080"}
      sx={{ objectFit: "cover", maxHeight: "200px" }}
    />
    <CardContent>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "break-space",
        }}
      >
        {card.title}
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <AccessTimeIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Scadenza: {card.duration}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <GavelIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Offerte Totali: {card.totalBidsAmount}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <FormatListNumberedIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Quantità Offerte: {card.qtyTotalBidsAmount}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <FormatListNumberedIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Base d'asta {card.baseAmount}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <FormatListNumberedIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Superficie:{" "}
          {
            card.customRelations.Lookup?.["Immobile all'asta"]
              ?.relatedRecords[0]?.relatedValues?.["Superficie"]
          }
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <FormatListNumberedIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          N° Locali:{" "}
          {
            card.customRelations.Lookup?.["Immobile all'asta"]
              ?.relatedRecords[0]?.relatedValues?.["N\u00b0 Locali"]
          }
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <FormatListNumberedIcon sx={{ mr: 1, color: "red" }} />
        <Typography variant="body2" color="text.secondary">
          Tipologia Immobile:{" "}
          {
            card.customRelations.Lookup?.["Immobile all'asta"]
              ?.relatedRecords[0]?.relatedValues?.["Tipologia Immobile"]
          }
        </Typography>
      </Box>
      <ColoredButton
        sx={{
          border: "1.5px solid #66c9ba",
          width: "100%",
        }}
        size="small"
        color="primary"
        onClick={() => goToRoute(card.id)}
      >
        Vai all'asta
      </ColoredButton>
    </CardContent>
  </Paper>
);

export default function LeMieAste() {
  const [sentCards, setSentCards] = useState([]);
  const [participatedCards, setParticipatedCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoadingCard(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: `application/json`,
        "Content-Type": `application/json`,
      };

      const url =
        process.env.REACT_APP_APP_ENDPOINT +
        `api/auth/getRecords/asta?owner=me`;

      const response = await axios.get(url, { headers: headers });

      setSentCards(response.data);
      setParticipatedCards(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoadingCard(false);
    }
  };

  useEffect(() => {
    // Chiamata API o logica necessaria per ottenere le aste partecipate
    // Esempio: fetchParticipatedAuctions();
  }, []);

  const goToRoute = (id) => {
    navigate(`/list/asta/show/${id}`);
  };
  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const renderCardGroups = (cards) => {
    const chunkedCards = chunkArray(cards, 3);
    return chunkedCards.map((group, index) => (
      <Grid container spacing={2} key={index}>
        {group.map((card) => (
          <Grid item xs={4} key={card.id}>
            <AstaCard card={card} goToRoute={goToRoute} />
          </Grid>
        ))}
      </Grid>
    ));
  };

  return (
    <>
      {!loadingCard && sentCards.length === 0 && (
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              Non hai ancora creato un'asta, comincia adesso!
            </Typography>
            <Typography variant="h5" sx={{ mb: 2 }}>
              <Link
                to="/Le-mie-inserzioni"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Le-mie-inserzioni"
                  sx={{ textTransform: "none" }}
                >
                  <HomeIcon sx={{ marginRight: 1 }} />
                  Vai agli Immobili
                </Button>
              </Link>
            </Typography>
          </Box>
        </Grid>
      )}

      {!loadingCard && sentCards.length > 0 && (
        <Stack>
          <Card
            sx={{
              margin: "30px",
              border: "solid 1.5px",
              borderRadius: "18px",
              boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15) ",
            }}
          >
            <CardHeader title="Le Mie Aste" />
            <CardContent>
              {loadingCard ? (
                <Skeleton />
              ) : (
                <Carousel
                  animation="slide"
                  interval={7000}
                  indicators={true}
                  navButtonsAlwaysVisible={true}
                  itemsToShow={3}
                  onChange={(index) => setCurrentSlide(index)}
                >
                  {renderCardGroups(sentCards)}
                </Carousel>
              )}
            </CardContent>
          </Card>
        </Stack>
      )}
    </>
  );
}
