import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  Skeleton,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DynamicWizard from "../../components/dynamic-wizard";
import { Dialog, DialogContent } from "@mui/material";
import toast from "react-hot-toast";
import SimpleDynamicForm from "../../components/simpleDynamicform";
import { styled } from "@mui/material/styles";
import MyCards from "./MyCards";

export default function LeMieInserzioni() {
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [localLoader, setLocalLoader] = useState(true);
  const [isModalNewInserzione, setIsModalNewInserzione] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [agencyResponse, setAgencyResponse] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModalCreate = () => setIsModalCreateOpen(true);
  const closeModalCreate = () => setIsModalCreateOpen(false);

  const navigate = useNavigate();
  const general = useSelector((state) => state.general);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      setLocalLoader(true);

      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/getRecords/immobile?owner=me",
        { headers }
      );

      const agencyResponseCall = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/getRecords/agenzie",
        { headers }
      );

      const fetchedCards = response.data.map((item, index) => ({
        id: item.id,
        title: item.houseType + " in " + item.title || `Immobile ${index + 1}`,
        description: item.duration,
        status: item.status || "#",
        imageUrl:
          item.firstImage ||
          "https://via.placeholder.com/800?text=Nessun+immagine",
        codiceImmobile: item.codiceImmobile,
      }));
      setCards(fetchedCards);
      setAgencyResponse(agencyResponseCall.data);

      toast.success("Dati salvati con successo!");
    } catch (error) {
      toast.error("Errore durante il recupero dei dati: " + error.message);
      console.error("Error fetching data: ", error);
    } finally {
      setLocalLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goToRoute = (id) => {
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };

  const createNewInserzione = () => {
    navigate("/dashboard/immobile/create");
  };
  const handleCreateNewInserzione = () => {
    setIsModalNewInserzione(true);
  };
  const handleCardClick = (e, id) => {
    e.stopPropagation();
    setSelectedRecordId(id);
    setIsModalOpen(true);
  };
  const handleCreateAsta = (e, id) => {
    e.stopPropagation();
    setSelectedRecordId(id);
    setIsModalCreateOpen(true);
  };
  const handleEditImmobile = (e, id) => {
    e.stopPropagation();
    setSelectedRecordId(id);
    setIsModalNewInserzione(true);
    setIsEditMode(true);
  };

  const handleAgencyChange = (event) => {
    const selectedAgencyValue = event.target.value;
    setSelectedAgency(selectedAgencyValue);
  };

  const handleRemoveFilters = () => {
    setSelectedAgency("");
  };

  const ColoredButton = styled(Button)(({ theme }) => ({
    size: "small",
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));
  const ModifyButton = styled(Button)(({ theme }) => ({
    size: "small",
    "&:hover": {
      backgroundColor: "transparent",
      color: `${theme.palette.secondary.main} !important`,
    },
  }));

  if (general.temporaryRole == "Customer") {
    return (
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography variant="h3" component="h1" color="primary">
            Coming Soon!
          </Typography>
          <Typography variant="body1" color="textSecondary" mt={2}>
            Stiamo lavorando duramente per portarti qualcosa di fantastico.
          </Typography>
        </Box>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          <Grid container spacing={0}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ pt: "20px" }}
            >
              {general.temporaryRole == "Admin" && (
                <Grid item xs={6}>
                  <FormControl>
                    <Select
                      value={selectedAgency}
                      onChange={handleAgencyChange}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Seleziona un'agenzia
                      </MenuItem>

                      {agencyResponse?.map((agency) => (
                        <MenuItem key={agency.id} value={agency.title}>
                          {agency.title}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {selectedAgency && (
                    <Button
                      onClick={handleRemoveFilters}
                      variant="contained"
                      sx={{
                        marginLeft: 2,
                        mt: 0.5,
                        borderRadius: "5px",
                        backgroundColor: "#94d9cf",
                        color: "#01685d",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        "&:hover": {
                          backgroundColor: "#01685d",
                          color: "#94d9cf",
                        },
                      }}
                    >
                      Rimuovi filtri
                    </Button>
                  )}
                </Grid>
              )}
              <Grid
                container
                xs={general.temporaryRole == "Admin" ? 6 : 12}
                spacing={2}
                justifyContent="end"
                alignItems="center"
                sx={{ pt: "20px" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleCreateNewInserzione()}
                  disabled
                >
                  Carica nuovo Immobile
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {!localLoader && cards.length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Non hai ancora inviato le tue inserzioni, comincia adesso!
                </Typography>
              </Box>
            </Grid>
          )}
          <MyCards selectedAgency={selectedAgency} />
        </Grid>

        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {selectedRecordId && (
              <SimpleDynamicForm
                objectType="immobile"
                recordId={selectedRecordId}
                title="Visualizza"
                showButtons={false}
              />
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={isModalCreateOpen}
          onClose={() => setIsModalCreateOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {selectedRecordId && (
              <DynamicWizard
                objectType="asta"
                recordId={selectedRecordId}
                title="Crea Asta"
                onClose={() => setIsModalCreateOpen(false)}
                reloadRecords={fetchData}
                prefilledValues={{ title: selectedRecordId, status: "Attiva" }}
                hiddenFields={{ title: true, status: true, actualOffer: true }}
              />
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={isModalNewInserzione}
          onClose={() => setIsModalNewInserzione(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {isEditMode ? (
              <SimpleDynamicForm
                objectType="immobile"
                onClose={() => setIsModalNewInserzione(false)}
                recordId={selectedRecordId}
                editMode={true}
                reloadRecords={fetchData}
                hiddenFields={{ status: true }}
              />
            ) : (
              <DynamicWizard
                objectType="immobile"
                onClose={() => setIsModalNewInserzione(false)}
                recordId={selectedRecordId}
                isEditMode={isEditMode}
                reloadRecords={fetchData}
                prefilledValues={{ status: "Verifica in Corso" }}
                hiddenFields={{ status: true }}
              />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
