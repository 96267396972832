import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  IconButton,
  Drawer,
  Hidden,
  TransitionGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const ImageGallery = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    const safeImages = images || [];
    const filteredImages = safeImages[0] || [];

    setAllImages([...filteredImages]);
  }, [images]);

  const openLightbox = (index) => {
    setSelectedImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const handleArrowClick = (direction) => {
    const newIndex =
      direction === "forward" ? selectedImage + 1 : selectedImage - 1;
    setSelectedImage(
      (prevIndex) => (newIndex + allImages.length) % allImages.length
    );
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 37) {
        // Arrow Left
        handleArrowClick("back");
      } else if (event.keyCode === 39) {
        // Arrow Right
        handleArrowClick("forward");
      }
    },
    [handleArrowClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "stretch",
          margin: 0,
          width: "100%",
          display: "flex",
          p: 3,
        }}
      >
        <Grid item xs={12} md={8} style={{ margin: 0, padding: 0 }}>
          <img
            src={allImages[0]}
            alt={`Image 0`}
            onClick={() => openLightbox(0)}
            style={{
              cursor: "pointer",
              width: "100%",
              objectFit: "cover",
              maxHeight: "500px",
              borderRadius: 20,
            }}
          />
        </Grid>
        <Hidden smDown mdDown>
          <Grid item xs={4} sx={{ paddingTop: "0!important" }}>
            <Grid
              container
              spacing={0}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "10px",
                width: "100%",
                alignItems: "stretch",
              }}
            >
              {allImages.slice(1, 4).map((image, index) => (
                <Grid
                  key={index}
                  item
                  style={{ maxHeight: "160px", position: "relative" }}
                >
                  <img
                    src={
                      image ||
                      `https://placehold.co/1200x600?text=Placeholder-${index}`
                    }
                    alt={`Image ${index}`}
                    onClick={() => openLightbox(index + 1)}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      padding: "0!important",
                      borderRadius: 20,
                      objectFit: "cover",
                    }}
                  />
                  {index === 2 && (
                    <div
                      onClick={() => openLightbox(index + 1)}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        inset: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        padding: "4px 8px",
                        borderRadius: "0px 0px 0px 20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          padding: 15,
                          height: 50,
                          width: 50,
                          background: "rgb(0 0 0 / 56%)",
                          borderRadius: 50,
                          gap: 5,
                          justifyContent: "center",
                          color: "#fff",
                          fontSize: 20,
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginRight: "4px" }}>
                          {allImages.length - 3}
                        </span>
                        <AddAPhotoIcon />
                      </div>
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <Drawer
        anchor="bottom"
        open={lightboxOpen}
        onClose={closeLightbox}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            transition: "transform 0.5s ease",
            transform: `translateY(${lightboxOpen ? 0 : "100%"})`,
          },
        }}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeLightbox}
            style={{ alignSelf: "flex-end", margin: "8px" }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <img
          src={
            allImages[selectedImage] ||
            `https://placehold.co/120x200?text=Placeholder-${selectedImage}`
          }
          alt={`Image ${selectedImage}`}
          style={{
            borderRadius: 20,
            maxHeight: "100%",
            objectFit: "contain",
            overflow: "hidden",
          }}
        />

        <div
          style={{
            overflowY: "auto",
            margin: "8px",
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={() => handleArrowClick("back")}
            style={{
              position: "absolute",
              top: "50%",
              left: "8px", // Posiziona la freccia a sinistra
              transform: "translateY(-50%)", // Centra verticalmente rispetto al contenitore
              background: "#fff",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => handleArrowClick("forward")}
            style={{
              position: "absolute",
              top: "50%",
              right: "8px", // Posiziona la freccia a destra
              transform: "translateY(-50%)", // Centra verticalmente rispetto al contenitore
              background: "#fff",
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
          {allImages.map((image, index) => (
            <img
              key={index}
              src={
                image ||
                `https://placehold.co/1200x600?text=Placeholder-${index}`
              }
              alt={`Thumbnail ${index}`}
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
              onClick={() => setSelectedImage(index)}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default ImageGallery;
