import React from "react";
import Header from "./Header";
import { Layout } from "./layout/Layout";
import { Grid, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <Grid
        component="container"
        sx={{
          paddingTop: "100.5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ fontSize: "80px", color: "#01685D" }}>404 Not Found</h1>
        <p style={{ fontSize: "20px" }}>
          La pagina che stai cercando non è presente sul nostro sito o non è più
          disponibile.
        </p>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          <Button
            variant="contained"
            onClick={handleGoBack}
            sx={{
              fontFamily: "Chakra Petch, sans-serif",
              fontSize: "16px",
              borderRadius: "3px",
              p: "12px 24px",
              background: "#6CC4B4",
              lineHeight: 1,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "none",
                background: "#01685D",
              },
            }}
          >
            Torna indietro
          </Button>
          <Link to="/dashboard">
            <Button
              variant="contained"
              sx={{
                background: "red",
                fontFamily: "Chakra Petch, sans-serif",
                fontSize: "16px",
                borderRadius: "3px",
                background: "#d32f2f",
                color: "#fff",
                p: "12px 24px",
                lineHeight: 1,
                textDecoration: "none",
                "&:hover": {
                  background: "#c62828",
                  textDecoration: "none",
                },
              }}
            >
              Vai alla Home
            </Button>
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default NotFound;
