import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Stack,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  InputLabel,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Impostazioni() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [affittiScaglioni, setAffittiScaglioni] = useState([]);
  const [venditaScaglioni, setVenditaScaglioni] = useState([]);
  const [monete, setMonete] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSaveTokenValue = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint =
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/updateSetting";

      const response = await axios.post(
        endpoint,
        {
          setting: "valore_token",
          value: monete,
        },
        { headers }
      );

      if (response.status == 200) {
        toast.success("Valore del token salvato con successo!");
      } else {
        toast.error("Errore durante il salvataggio del valore del token.");
      }
    } catch (error) {
      console.error("Errore durante la richiesta HTTP:", error);
      toast.error("Errore durante la richiesta al server.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniAffitto",
          { headers }
        );
        const responseVendita = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniVendita",
          { headers }
        );

        const responseMonete = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/auth/getAllSettings",
          { headers }
        );

        if (
          responseMonete.data.settings &&
          responseMonete.data.settings.length > 0
        ) {
          const valoreTokenSetting = responseMonete.data.settings.find(
            (setting) => setting.setting === "valore_token"
          );

          if (valoreTokenSetting) {
            setMonete(valoreTokenSetting.value);
          }
        }

        setAffittiScaglioni(response.data.scaglioni);
        setVenditaScaglioni(responseVendita.data.scaglioni);

        setIsLoading(false);
      } catch (error) {
        console.error("Errore durante la richiesta HTTP:", error);
        toast.error("Errore durante la richiesta al server.");
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // scaglioni Affitto
  const handleFieldChange = (id, field, value) => {
    const updatedAffittiScaglioni = affittiScaglioni.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setAffittiScaglioni(updatedAffittiScaglioni);
  };

  const handleSaveRecordAffitto = async (id, saveAll = false) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/updateScaglioneAffitto/${id}`;

      // Trova il record corrente dallo stato locale
      const scaglione = affittiScaglioni.find((item) => item.id === id);

      // Invia solo il record corrente al backend
      const response = await axios.post(endpoint, scaglione, { headers });

      if (response.status == 200) {
        toast.success("Scaglione affitto aggiornato con successo!");
      } else {
        toast.error("Errore durante l'aggiornamento dello scaglione affitto.");
      }
    } catch (error) {
      console.error("Errore durante la richiesta HTTP:", error);
      toast.error("Errore durante la richiesta al server.");
    }
  };

  // scaglioni Vendita

  const handleFieldChangeVendita = (id, field, value) => {
    const updatedVenditaScaglioni = venditaScaglioni.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setVenditaScaglioni(updatedVenditaScaglioni);
  };

  const handleSaveRecordVendita = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/updateScaglioneVendita/${id}`;

      // Trova il record corrente dalla stato locale
      const scaglioneVendita = venditaScaglioni.find((item) => item.id === id);

      // Invia solo il record corrente al backend
      const response = await axios.post(endpoint, scaglioneVendita, {
        headers,
      });

      if (response.status == 200) {
        toast.success("Scaglione vendita aggiornato con successo!");
      } else {
        toast.error("Errore durante l'aggiornamento dello scaglione vendita.");
      }
    } catch (error) {
      console.error("Errore durante la richiesta HTTP:", error);
      toast.error("Errore durante la richiesta al server.");
    }
  };

  const [campi, setCampi] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        // Fetch tipologieImmobili data from the API
        const response = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT +
            "api/auth/getRecords/tipologieImmobili",
          { headers }
        );

        // Extract titles from the response
        const campi = response.data.map((immobile) => immobile.title);

        // Update state with the received data
        setCampi(campi);
        setIsLoading(false);
      } catch (error) {
        console.error("Errore durante la richiesta HTTP:", error);
        toast.error("Errore durante la richiesta al server.");
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderCampoCards = () => {
    return campi.map((campo, index) => {
      const scaglioniByType = affittiScaglioni.filter(
        (scaglione) => scaglione.type === campo
      );

      return (
        <Accordion key={`${campo}-${index}`} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${campo}-content`}
            id={`${campo}-header`}
          >
            <Typography variant="h6">Affitto {campo}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {campo === "Abilità" ? (
              <FormControl fullWidth>
                <InputLabel htmlFor={`${campo}-abilita`}>Abilità</InputLabel>
                <Checkbox id={`${campo}-abilita`} />
              </FormControl>
            ) : (
              <div>
                <Typography variant="h6">Scaglioni di Affitto</Typography>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Tipologie immobile
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Min
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Max
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Primo Rilancio Minimo €
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Rilancio Minimo €
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Azioni
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scaglioniByType.map((scaglione) => (
                      <tr key={`${scaglione.type}-${scaglione.id}`}>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          {scaglione.type}
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Min`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.min}
                            onChange={(e) =>
                              handleFieldChange(
                                scaglione.id,
                                "min",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Max`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.max}
                            onChange={(e) =>
                              handleFieldChange(
                                scaglione.id,
                                "max",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Primo Rilancio Minimo €`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.primo_rilancio_minimo}
                            onChange={(e) =>
                              handleFieldChange(
                                scaglione.id,
                                "primo_rilancio_minimo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Rilancio Minimo €`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.rilancio_minimo}
                            onChange={(e) =>
                              handleFieldChange(
                                scaglione.id,
                                "rilancio_minimo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              background: "#66C9BA",
                              color: "#fff",
                              "&:hover": { background: "#03685d" },
                            }}
                            onClick={() =>
                              handleSaveRecordAffitto(scaglione.id)
                            }
                          >
                            Salva
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };
  const renderCampoCardsVendite = () => {
    return campi.map((campo, index) => {
      const scaglioniVenditaByType = venditaScaglioni.filter(
        (scaglione) => scaglione.type === campo
      );

      return (
        <Accordion key={`${campo}-${index}`} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${campo}-content`}
            id={`${campo}-header`}
          >
            <Typography variant="h6">Vendita {campo}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {campo === "Abilità" ? (
              <FormControl fullWidth>
                <InputLabel htmlFor={`${campo}-abilita`}>Abilità</InputLabel>
                <Checkbox id={`${campo}-abilita`} />
              </FormControl>
            ) : (
              <div>
                <Typography variant="h6">Scaglioni di Vendita</Typography>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Tipologie immobile
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Min
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Max
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Primo Rilancio Minimo €
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Rilancio Minimo €
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Azioni
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scaglioniVenditaByType.map((scaglione) => (
                      <tr key={`${scaglione.type}-${scaglione.id}`}>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          {scaglione.type}
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Min`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.min}
                            onChange={(e) =>
                              handleFieldChangeVendita(
                                scaglione.id,
                                "min",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Max`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.max}
                            onChange={(e) =>
                              handleFieldChangeVendita(
                                scaglione.id,
                                "max",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Primo Rilancio Minimo €`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.primo_rilancio_minimo}
                            onChange={(e) =>
                              handleFieldChangeVendita(
                                scaglione.id,
                                "primo_rilancio_minimo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            label={`Rilancio Minimo €`}
                            variant="outlined"
                            margin="dense"
                            value={scaglione.rilancio_minimo}
                            onChange={(e) =>
                              handleFieldChangeVendita(
                                scaglione.id,
                                "rilancio_minimo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              background: "#66C9BA",
                              color: "#fff",
                              "&:hover": { background: "#03685d" },
                            }}
                            onClick={() =>
                              handleSaveRecordVendita(scaglione.id)
                            }
                          >
                            Salva
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Grid container padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={3}>
          <Card sx={{ borderRadius: "8px" }}>
            <CardHeader title="Impostazioni"></CardHeader>
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              indicatorColor="transparent"
              value={value}
              onChange={handleChange}
              sx={{
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                  alignItems: "flex-start",
                }, // singolo tab
                "& .MuiTab-root": {
                  margin: 1,
                  borderRadius: 2,
                  color: "#000",
                  transition: "all 0.3s",
                  boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15)",
                  "&:hover": {
                    boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15)",
                    backgroundColor: "#03685d",
                  },
                },
                "& .Mui-selected": {
                  backgroundColor: "#65c9ba",
                  boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15)",
                  color: "#fff!important",
                },
              }}
            >
              <Tab label="Generale" />
              <Tab label="Aste" />
            </Tabs>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{ display: "flex", width: "100%" }}
        >
          <div
            style={{
              width: "100%",
            }}
            role="tabpanel"
            hidden={value !== 0} // Modifica la condizione hidden
            id={`vertical-tabpanel-0`} // Assicurati che corrisponda all'id del tab corrente
            aria-labelledby={`vertical-tab-0`}
          >
            {value === 0 && (
              <Card
                sx={{
                  p: 0,
                  overflowY: "auto",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <CardHeader title="Generale"></CardHeader>
                <CardContent>
                  <FormControl fullWidth sx={{ gap: 1 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label={`Valore in euro del singolo Token €`}
                      variant="outlined"
                      margin="dense"
                      value={monete || ""}
                      onChange={(e) => setMonete(e.target.value)}
                    />
                    <Button
                      sx={{
                        background: "#66C9BA",
                        color: "#fff",
                        "&:hover": { background: "#03685d" },
                      }}
                      onClick={handleSaveTokenValue}
                    >
                      invia
                    </Button>
                  </FormControl>
                </CardContent>
              </Card>
            )}
          </div>

          <div
            style={{
              width: "100%",
            }}
            role="tabpanel"
            hidden={value !== 1} // Modifica la condizione hidden
            id={`vertical-tabpanel-1`} // Assicurati che corrisponda all'id del tab corrente
            aria-labelledby={`vertical-tab-1`}
          >
            {value === 1 && (
              <Card
                sx={{
                  p: 0,
                  overflowY: "auto",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CardHeader title="Aste"></CardHeader>
                <CardContent>
                  <Tabs
                    orientation="horizontal"
                    indicatorColor="transparent"
                    value={subValue}
                    onChange={(event, newValue) => setSubValue(newValue)}
                    aria-label="horizontal tabs example"
                    variant="fullWidth"
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("sm")]: {
                        alignItems: "flex-start",
                      },
                    }}
                  >
                    <Tab label="Gestione Affitti" />
                    <Tab label="Gestione Vendite" />
                  </Tabs>
                  {/* Utilizza il valore di `asteTabValue` per mostrare il contenuto corrispondente */}
                  {subValue === 0 && <>{renderCampoCards()}</>}

                  {subValue === 1 && <>{renderCampoCardsVendite()}</>}
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
