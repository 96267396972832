import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { styled } from "@mui/material/styles";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const ColoredButton = styled(Button)(({ theme }) => ({
  size: "large",
  backgroundColor: "#94d9cf",
  color: "#01685d",
  paddingLeft: "20px",
  paddingRight: "20px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#66c9ba",
  },
}));

const ApprButton = styled(Button)(({ theme, status }) => ({
  height: "30px",
  width: "120px",
  padding: "0 25px 2px 25px",
  fontWeight: "bold",
  fontSize: "15px",
  backgroundColor: (() => {
    switch (status) {
      case "Approvato":
        return "green";
      case "Rifiutato":
        return "red";
      case "Modifica":
        return "orange";
      case "Verifica":
        return "lightorange";
      default:
        return "inherit";
    }
  })(),
  color: "white",
}));

function MyCards({ selectedAgency }) {
  const [cards, setCards] = useState([]);
  const [localLoader, setLocalLoader] = useState(true);
  const [filteredCards, setFilteredCards] = useState([]);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      setLocalLoader(true);
      // Modifica l'URL per ottenere solo le tue inserzioni
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/getRecords/immobile?owner=me",
        { headers }
      );

      const fetchedCards = response.data.map((item, index) => ({
        id: item.id,
        title: item.houseType + " in " + item.title || `Immobile ${index + 1}`,
        actualOffer: item.actualOffer,
        description: item.shortDescription,
        status: item.status || "#",
        imageUrl: item.firstImage || "https://via.placeholder.com/400x300",
        mq: item.mq,
        roomQty: item.roomQty,
        wcQty: item.wcQty,
        codiceImmobile: item.codiceImmobile,
      }));
      setCards(fetchedCards);

      toast.success("Dati salvati con successo!");
    } catch (error) {
      toast.error("Errore durante il recupero dei dati: " + error.message);

      console.error("Error fetching data: ", error);
    } finally {
      setLocalLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredCards = selectedAgency
      ? cards.filter((card) => card.codiceImmobile.includes(selectedAgency))
      : cards;
    setFilteredCards(filteredCards);
  }, [selectedAgency, cards]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {filteredCards.length === 0 ? (
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              Nessun risultato trovato per questo filtro.
            </Typography>
          </Box>
        </Grid>
      ) : (
        filteredCards.map((card) => (
          <ThemeProvider theme={theme} key={card.id}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{ display: "flex", borderRadius: "20px" }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <CardMedia
                      component="img"
                      image={card.imageUrl}
                      height="300"
                      alt="Immagine Inserzione"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderRadius: "2rem",
                        padding: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={8}>
                    <CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            alignItems: "flex-start",
                          }}
                        >
                          {card.title}
                        </Typography>
                        <Box>
                          <ApprButton
                            status={card.status}
                            sx={{
                              backgroundColor:
                                card.status === "Attiva"
                                  ? "green"
                                  : card.status === "Sospesa"
                                  ? "orange"
                                  : "red",
                              borderRadius: "5rem",
                              marginRight: "1rem",
                              alignItems: "flex-end",
                              textAlign: "center",
                              marginBottom: isSmallScreen ? "1rem" : 0,
                            }}
                            size="small"
                            variant="contained"
                          >
                            {card.status}
                          </ApprButton>
                          <Box>
                            {card.status === "Richiesta Modifica" && (
                              <ColoredButton
                                sx={{
                                  borderRadius: "5px",
                                  height: "30px",
                                  minWidth: "30px",
                                  padding: 0,
                                }}
                                size="small"
                                color="primary"
                                variant="contained"
                              >
                                <ModeEditOutlineOutlinedIcon
                                  sx={{ width: "30px" }}
                                />
                              </ColoredButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        €{" "}
                        {card &&
                          card.actualOffer
                            .toLocaleString("it-IT", {
                              style: "currency",
                              currency: "EUR",
                            })
                            .replace("€", "")}
                      </Typography>
                      <Typography>
                        {card &&
                        card.description &&
                        card.description.length > 30
                          ? card.description.substring(0, 250) + "..."
                          : card.description}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection={isSmallScreen ? "column" : "row"}
                        alignItems="center"
                        justifyContent={
                          isSmallScreen ? "flex-start" : "space-between"
                        }
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mr: 2 }}
                          >
                            <UnfoldMoreIcon
                              sx={{
                                mr: 2,
                                color: "red",
                                height: "2rem",
                                width: "2rem",
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "1.10rem" }}
                            >
                              {card.mq} MQ
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mr: 2 }}
                          >
                            <KingBedOutlinedIcon
                              sx={{
                                mr: 2,
                                color: "red",
                                height: "2rem",
                                width: "2rem",
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "1.10rem" }}
                            >
                              {card.roomQty} Locali
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mr: 2 }}
                          >
                            <BathtubOutlinedIcon
                              sx={{
                                mr: 2,
                                color: "red",
                                height: "2rem",
                                width: "2rem",
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "1.10rem" }}
                            >
                              {card.wcQty} Bagni
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Link to={`/asta/${card.id}`} target="_blank">
                            <ColoredButton
                              sx={{
                                borderRadius: "5rem",
                                textAlign: "center",
                              }}
                              size="large"
                              color="primary"
                              textAlign="center"
                            >
                              VISUALIZZA
                            </ColoredButton>
                          </Link>
                        </Box>
                      </Box>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </ThemeProvider>
        ))
      )}
    </>
  );
}

export default MyCards;
