import React from "react";

const BagniIcon = ({ fillColor = "#E1251B", width = 23, height = 26 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6701 24.9567H6.42122C3.9524 24.8966 1.89728 22.8066 1.59035 20.055L0.869719 14.5218C0.722924 13.4994 1.32345 12.5371 2.2309 12.3266C2.31097 12.3266 2.40439 12.3115 2.48446 12.3115H20.5936C21.501 12.3266 22.235 13.1836 22.2217 14.2061C22.2217 14.3113 22.2217 14.4166 22.2083 14.5218L21.501 20.055C21.1941 22.8216 19.139 24.9116 16.6701 24.9567Z"
        stroke={fillColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55273 12.2966V2.82401C4.55273 1.75646 5.3134 0.899414 6.26089 0.899414C6.71462 0.899414 7.155 1.09488 7.47528 1.45574L8.74305 2.88416"
        stroke={fillColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.87695 2.83887L9.63762 6.05656L11.7328 3.69592L8.87695 2.83887Z"
        stroke={fillColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BagniIcon;
