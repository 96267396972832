import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  CircularProgress,
  Backdrop,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfModal = ({ rilancioData, auctionData }) => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [checked, setChecked] = useState(false);
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpCooldown, setOtpCooldown] = useState(0);
  const pdfContainerRef = useRef(null);
  const location = useLocation();
  const currentRecordId = location.pathname.split("/")[2];
  const pdfUrl = "/bando_proposta(proprietario_venditore).pdf";
  const baseAmount = auctionData?.baseAmount;
  const cinquePercento = baseAmount ? (5 / 100) * baseAmount : null;
  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });

  const formattedCinquePercento = formatter.format(cinquePercento);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      const loadingTask = pdfjs.getDocument(pdfUrl);
      loadingTask.promise.then(
        (pdfDocument) => {
          setNumPages(pdfDocument.numPages);
          renderPages(pdfDocument);
        },
        (error) => {
          if (error instanceof pdfjs.InvalidPDFException) {
            console.error("Invalid PDF structure: ", error.message);
          } else if (error instanceof pdfjs.MissingPDFException) {
            console.error("Missing PDF: ", error.message);
          } else {
            console.error("Error loading PDF: ", error.message);
          }
        }
      );
    }
  }, [open]);

  const renderPages = async (pdfDocument) => {
    if (pdfContainerRef.current) {
      pdfContainerRef.current.innerHTML = "";
      const containerWidth = pdfContainerRef.current.clientWidth;

      for (
        let pageNumber = 1;
        pageNumber <= pdfDocument.numPages;
        pageNumber++
      ) {
        const page = await pdfDocument.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1 });
        const scale = containerWidth / viewport.width;
        const scaledViewport = page.getViewport({ scale });

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        await page.render({ canvasContext: context, viewport: scaledViewport })
          .promise;
        pdfContainerRef.current.appendChild(canvas);
      }
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      setCheckboxEnabled(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setOtpSent(false);
    }
  };

  const handleSendOtp = async () => {
    try {
      const token = localStorage.getItem("token");
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;
      await axios.post(
        `${endpoint}api/auth/otp/contratti`,
        {
          asta_id: currentRecordId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOtpSent(true);
      setOtpCooldown(60);
      toast.success("OTP inviato con successo!");
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Errore durante l'invio dell'OTP");
    }
  };

  useEffect(() => {
    if (otpCooldown > 0) {
      const timer = setInterval(() => {
        setOtpCooldown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [otpCooldown]);

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;
      await axios.post(
        `${endpoint}api/auth/verify-otp`,
        { otp: otpInput, asta_id: currentRecordId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(
        `Ti confermiamo che hai accettato le condizioni di vendita. Ti ricordiamo che per partecipare all'asta devi versare la caparra confirmatoria di: ${formattedCinquePercento}`
      );

      setTimeout(() => {
        window.location.reload();
      }, 4500);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Errore durante la verifica dell'OTP");
      setLoading(false);
    }
  };

  return (
    <div>
      <Toaster />
      <Button
        variant="contained"
        onClick={handleOpen}
        color="primary"
        sx={{
          marginTop: 2,
          width: "100%",
          background: "#66C9BA",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
          color: "#01685D",
          border: "solid transparent",
          borderRadius: "20px",
          "&:hover": {
            background: "#66C9BA",
            border: "solid #01685D",
          },
        }}
      >
        Partecipa all'asta
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            p: 4,
            borderRadius: "12px",
            bgcolor: "background.paper",
            margin: "auto",
            maxWidth: "50%",
            maxHeight: "90vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          <h1 style={{ textAlign: "center", marginTop: 0 }}>
            Contratto Proposta Di Vendita
          </h1>
          <h4 style={{ textAlign: "center" }}>
            Per partecipare all'asta è necessaio sottoscrivere il contratto e
            versare la caparra confirmatoria. Leggi il contratto e firmalo
            tramite OTP
          </h4>
          {loading && (
            <Backdrop
              sx={{
                color: "#fff",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          <div
            ref={pdfContainerRef}
            style={{
              minHeight: "200px",
              maxHeight: "50vh",
              textAlign: "center",
              overflow: "auto",
              border: "1px solid rgb(101 201 186)",
              padding: 0,
            }}
            onScroll={handleScroll}
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    disabled={!checkboxEnabled}
                  />
                }
                label="Accetta contratto"
              />
            </Grid>
            <Grid item>
              <span style={{ color: "#66C9BA" }}>
                {cinquePercento && (
                  <h5>Caparra da versare: {formattedCinquePercento}</h5>
                )}
              </span>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleSendOtp}
            disabled={!checked || otpCooldown > 0}
            color="primary"
            sx={{
              marginTop: 2,
              width: "100%",
              background: "#66C9BA",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
              color: "#01685D",
              border: "solid transparent",
              borderRadius: "20px",
              "&:hover": {
                background: "#66C9BA",
                border: "solid #01685D",
              },
            }}
          >
            {otpCooldown > 0 ? `Attendi ${otpCooldown}s` : "Invia OTP"}
          </Button>
          {otpSent && (
            <Box sx={{ mt: 2 }}>
              <h5>
                Verificando l'OTP accetterai il contratto e le sue condizioni
              </h5>
              <TextField
                label="Inserisci OTP"
                value={otpInput}
                onChange={(e) => setOtpInput(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleVerifyOtp}
                disabled={loading}
                color="primary"
                sx={{
                  marginTop: 2,
                  width: "100%",
                  background: "#66C9BA",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                  color: "#01685D",
                  border: "solid transparent",
                  borderRadius: "20px",
                  "&:hover": {
                    background: "#66C9BA",
                    border: "solid #01685D",
                  },
                }}
              >
                Verifica e Accetta
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PdfModal;
