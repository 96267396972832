import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ImpostazioniAgenzia() {
  const [affittiScaglioni, setAffittiScaglioni] = useState([]);
  const [venditaScaglioni, setVenditaScaglioni] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        const responseAffitto = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniAffitto",
          { headers }
        );

        const responseVendita = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniVendita",
          { headers }
        );

        setAffittiScaglioni(responseAffitto.data.scaglioni);
        setVenditaScaglioni(responseVendita.data.scaglioni);
      } catch (error) {
        console.error("Errore durante la richiesta HTTP:", error);
      }
    };

    fetchData();
  }, []);

  const renderCampoCards = (scaglioni) => {
    const groupedScaglioni = {};

    // Raggruppa i tipi di scaglioni
    scaglioni.forEach((campo) => {
      if (!groupedScaglioni[campo.type]) {
        groupedScaglioni[campo.type] = [];
      }
      // Converti i valori da stringa a numero decimale
      campo.min = parseFloat(campo.min);
      campo.max = parseFloat(campo.max);
      campo.primo_rilancio_minimo = parseFloat(campo.primo_rilancio_minimo);
      campo.rilancio_minimo = parseFloat(campo.rilancio_minimo);

      groupedScaglioni[campo.type].push(campo);
    });

    return Object.keys(groupedScaglioni).map((type, index) => (
      <Accordion key={type} sx={{ marginBottom: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${type}-content`}
          id={`${type}-header`}
        >
          <Typography variant="h6"> {type}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipologie immobile</TableCell>
                    <TableCell align="center">Min</TableCell>
                    <TableCell align="center">Max</TableCell>
                    <TableCell align="center">
                      Primo Rilancio Minimo €
                    </TableCell>
                    <TableCell align="center">Rilancio Minimo €</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedScaglioni[type].map((campo, idx) => (
                    <TableRow key={`${type}-${idx}`}>
                      <TableCell>{campo.type}</TableCell>
                      <TableCell align="center">
                        {campo.min.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {campo.max.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {campo.primo_rilancio_minimo.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {campo.rilancio_minimo.toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Grid container padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={3}>
          <Card sx={{ borderRadius: "8px" }}>
            <CardHeader title="Impostazioni"></CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <Grid item xs={12}>
            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="scaglioni-affitto-content"
                id="scaglioni-affitto-header"
              >
                <Typography variant="h6">Scaglioni di Affitto</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>{renderCampoCards(affittiScaglioni)}</CardContent>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="scaglioni-vendita-content"
                id="scaglioni-vendita-header"
              >
                <Typography variant="h6">Scaglioni di Vendita</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>{renderCampoCards(venditaScaglioni)}</CardContent>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
