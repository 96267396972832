import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Modal,
  Backdrop,
  Fade,
  Paper,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

export default function TabellaRilanci() {
  const [affittiScaglioni, setAffittiScaglioni] = useState([]);
  const [venditaScaglioni, setVenditaScaglioni] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        const responseAffitto = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniAffitto",
          { headers }
        );

        const responseVendita = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/getAllScaglioniVendita",
          { headers }
        );

        setAffittiScaglioni(
          responseAffitto.data.scaglioni.map((scaglione) => ({
            ...scaglione,
            tipo: "Affitto",
          }))
        );
        setVenditaScaglioni(
          responseVendita.data.scaglioni.map((scaglione) => ({
            ...scaglione,
            tipo: "Vendita",
          }))
        );
      } catch (error) {
        console.error("Errore durante la richiesta HTTP:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "tipo",
      headerName: "Tipo",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "Tipologia Immobile",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "min",
      headerName: "Min",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "max",
      headerName: "Max",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "primo_rilancio_minimo",
      headerName: "Primo Rilancio Minimo €",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "rilancio_minimo",
      headerName: "Rilancio Minimo €",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Grid container padding={0}>
      <Grid item xs={12}>
        <CardContent sx={{ py: "0!important" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginTop: 2,
              width: "100%",
              background: "#66C9BA",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
              color: "#01685D",
              border: "solid transparent",
              borderRadius: "20px",
              "&:hover": {
                background: "#66C9BA",
                border: "solid #01685D",
              },
            }}
            onClick={handleOpen}
          >
            Visualizza rilanci
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
            }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: "30px 100px",
                margin: 5,
                overflow: "auto",
                position: "absolute",
                inset: 0,
                zIndex: 9999,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Card>
                <CardHeader title="Tabella Rilanci" />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Scaglioni di Affitto
                  </Typography>
                  <div style={{ height: 400, width: "100%", marginTop: 16 }}>
                    <DataGrid
                      rows={affittiScaglioni}
                      columns={columns}
                      autoPageSize={false}
                      pagination
                      pageSizeOptions={[
                        25,
                        50,
                        100,
                        { label: "Tutte", value: -1 },
                      ]}
                      pageSize={pageSize}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize },
                        },
                      }}
                    />
                  </div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: 16 }}
                  >
                    Scaglioni di Vendita
                  </Typography>
                  <div style={{ height: 400, width: "100%", marginTop: 16 }}>
                    <DataGrid
                      rows={venditaScaglioni}
                      columns={columns}
                      autoPageSize={false}
                      pagination
                      pageSizeOptions={[
                        25,
                        50,
                        100,
                        { label: "Tutte", value: -1 },
                      ]}
                      pageSize={pageSize}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize },
                        },
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Modal>
        </CardContent>
      </Grid>
    </Grid>
  );
}
