import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const File = ({ field, onChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Legge il file e lo converte in una stringa base64

      reader.onload = () => {
        const base64String = reader.result;
        console.log(base64String); // Puoi rimuovere questo log dopo il testing
        setSelectedFile(file); // Imposta il file selezionato per visualizzazioni o operazioni future
        onChange(field.name, base64String); // Invia la stringa base64 come valore
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onChange(field.fieldName, null); // Rimuove il file dallo state del componente padre
  };

  return (
    <div
      style={{ display: "grid", flexDirection: "column", alignItems: "left" }}
    >
      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        sx={{
          backgroundColor: "#66C9BA",
          mt: 0.5,
          borderRadius: "10px",
          color: "#fff",
          transition: "background .2s ease-in-out",
          "&:hover": {
            backgroundColor: "#01685D",
          },
        }}
      >
        Seleziona File
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
      {selectedFile && (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
        >
          <span style={{ fontSize: "0.8rem", marginRight: "4px" }}>
            {selectedFile.name}
          </span>
          <Button
            onClick={handleRemoveFile}
            style={{ minWidth: "auto", padding: "0", height: "auto" }}
          >
            <span style={{ fontSize: "0.8rem" }}>X</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default File;
