import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Unstable_Grid2 as Grid,
  TextField,
  IconButton
} from '@mui/material';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Date = ({ field, fieldName, isCreate, isEdit, isShow, value, fieldErrors, onChange }) => {
    const [formValues, setFormValues] = useState({});
    const dispatch = useDispatch();
    const fieldRef = useRef(null);
    const pageForm = localStorage.getItem('formType')

    const handleInputChange = (e) => {
      onChange(field.fieldName, e.target.value);
    };
  
 
 

  return (
    <>
  <TextField
    type="date"
    id={fieldName}
    name={fieldName}
    onChange={(e) => handleInputChange(e, fieldName)}
    fullWidth
    defaultValue={ value}
    helperText={fieldErrors || ''}
    ref={fieldRef} 
    />
  </>
  );
};

export default Date;