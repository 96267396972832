import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { thunks } from "../thunks/general";

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [tokens, setTokens] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    const data = new FormData(event.currentTarget);
    const dataSubmit = {
      email: data.get("email"),
      password: data.get("password"),
    };

    fetch(process.env.REACT_APP_APP_ENDPOINT + "api/auth/login", {
      method: "POST",
      body: JSON.stringify(dataSubmit),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const result = await response.json();
        // Stop loading after handling response

        if (result.errors) {
          Object.values(result.errors).map((error) => {
            error.map((message) => {
              toast.error(message);
            });
          });
        }

        dispatch(thunks.setUsername(result.userData.name));
        dispatch(thunks.setUserId(result.userData.id));
        //dexter fix ;)
        dispatch(thunks.setRole(result.userData.temporaryRole));
        dispatch(thunks.setAgency(result.userData.agency_id));

        dispatch(thunks.setStatus(result.userData.status));
        dispatch(thunks.setTokens(result.availableTokens));

        localStorage.setItem("token", result.token);
        setLoading(false);

        if (result.userData.status === "Pending") {
          navigate("/verification-opt");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        setLoading(false); // Stop loading on error

        if (error.response) {
          console.error("Errore nella risposta:", error.response.data);
          toast.error("Errore nella risposta della richiesta");
        } else if (error.request) {
          console.error(
            "Nessuna risposta ricevuta dalla richiesta:",
            error.request
          );
          toast.error("Nessuna risposta ricevuta dalla richiesta");
        } else {
          console.error("Errore durante la richiesta:", error.message);
          toast.error("Errore durante la richiesta");
        }
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "#d44432",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            px: 5, // Aggiunge un po' di padding laterale per non attaccare il testo ai bordi
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: "bold", // Rende il testo dell'intestazione più spesso
            }}
          >
            LA NUOVA ASTA IMMOBILIARE PRIVATA E NON GIUDIZIARIA
          </Typography>
          <Typography component="h2" variant="h5" align="center">
            Accedi ed inizia a vendere o comprare immobili
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <svg
              width="200"
              viewBox="0 0 80 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 39L40.2 0L79.8 39L61.8 78H16.8L0 39Z" fill="white" />
              <path
                d="M15.6001 40.2L24.0001 31.8L39.6001 47.4L70.2001 16.8L78.6001 25.2L39.6001 64.2L15.6001 40.2Z"
                fill="#D44432"
              />
              <path
                d="M78.6 25.2L63 40.8L54 33L70.2 16.8L78.6 25.2Z"
                fill="#14B8A6"
              />
            </svg>
            <Typography component="h1" variant="h5">
              Accedi
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Indirizzo email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Ricordami"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgb(20, 184, 166)",
                  "&:hover": {
                    // Scurire leggermente il colore per l'hover
                    backgroundColor: "rgb(15, 174, 156)",
                  },
                }}
                disabled={loading}
              >
                {loading ? "Caricamento..." : "Accedi"}
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Password dimenticata?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link href="/register" variant="body2">
                    Registrati
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
