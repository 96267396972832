import React, { useState, useEffect, useMemo, startTransition } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Modal,
  CardActions,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { lazy, Suspense } from "react";
import { toast, ToastContainer } from "react-toastify";
import { thunks } from "../../thunks/general";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import NewList from "pages/Adminlist/NewList/NewList";
const StripePaymentForm = lazy(() => import("../Stripe/StripePaymentForm"));

const showToast = (message, type) => {
  switch (type) {
    case "success":
      toast.success(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case "error":
      toast.error(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    default:
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }
};

const Wallet = () => {
  const [saldoToken, setSaldoToken] = useState(0);
  const [quantitaToken, setQuantitaToken] = useState("");
  const [metodoPagamento, setMetodoPagamento] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalBonifico, setIsModalBonifico] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [transazioni, setTransazioni] = useState([]);
  const [totale, setTotale] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const general = useSelector((state) => state.general);

  const appearance = {
    theme: "stripe",
  };

  const fetchSaldo = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/wallet/saldo",
        { headers: headers }
      );
      setSaldoToken(response.data.saldoWallet);
      dispatch(thunks.setTokens(response.data.saldoWallet));
    } catch (error) {
      console.error("Error fetching saldo: ", error);
    }
  };

  const fetchTransazioni = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/getRecords/transazione?owner=me",
        { headers }
      );
      setTransazioni(response.data.transazioni);
    } catch (error) {
      console.error("Errore nel recupero delle transazioni: ", error);
    }
  };
  useEffect(() => {
    fetchTransazioni();
    fetchSaldo();
  }, []);

  useEffect(() => {
    // Aggiorna il totale ogni volta che cambia quantitaToken
    setTotale(quantitaToken * 3); // 1 token = 100 euro
  }, [quantitaToken]);

  const handlePaymentMethodChange = (event) => {
    setMetodoPagamento(event.target.value);
  };

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleBankTransferConfirm = async () => {
    try {
      const newRandomString = generateRandomString(10);
      const tokenInt = parseInt(quantitaToken);

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/storeRecord/ordine",
        {
          ammontare_token_ordine: tokenInt,
          metodo_di_pagamento: "Bonifico",
          status: "Da evadere",
          title: newRandomString,
          owner_id: general.userId,
          valore_ordine: totale,
        },
        { headers }
      );

      showToast("Ordine ricevuto con successo", "success");
      setIsModalBonifico(false);
      setIsSuccessModalOpen(true);
    } catch (error) {
      showToast("Errore durante il tuo ordine", "error");
    }
    setQuantitaToken("");
  };

  const openSuccessModal = () => {
    setIsModalBonifico(true);
  };

  const closeSuccessModal = () => {
    setIsModalBonifico(false);
  };

  const handlePayButtonClick = () => {
    // Verifica se è stata selezionata una quantità valida di token
    if (!quantitaToken || parseInt(quantitaToken) <= 0) {
      showToast("Seleziona una quantità valida di BIDS.", "error");
      return;
    }

    // Verifica se è stato selezionato il metodo di pagamento
    if (!metodoPagamento) {
      showToast("Seleziona un metodo di pagamento.", "error");
      return;
    }

    // Verifica se il metodo di pagamento è bonifico e se ci sono token selezionati
    if (metodoPagamento === "bonifico" && parseInt(quantitaToken) > 0) {
      openSuccessModal();
    } else {
      showToast(
        "Seleziona una quantità valida di BIDS per il bonifico.",
        "error"
      );
    }
  };

  // Stile per il modale (puoi personalizzarlo)
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Stile per il modale (puoi personalizzarlo)
  const bonifico = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    borderRadius: 2,
    textAlign: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const createOrder = (data, actions) => {
    // Assicurati che totale sia maggiore di zero
    if (parseFloat(totale) <= 0) {
      console.error(
        "Il totale deve essere maggiore di zero per procedere con il pagamento."
      );
      return Promise.reject(
        new Error("Il totale deve essere maggiore di zero.")
      );
    }

    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: "EUR", // Sostituisci con la tua valuta
              value: totale.toString(),
            },
          },
        ],
      })
      .then((orderId) => {
        console.log("Order ID:", orderId); // Log dell'ID dell'ordine
        fetchTransazioni();
        fetchSaldo();
        return orderId; // Restituisce l'ID dell'ordine creato
      })
      .catch((error) => {
        console.error("Errore nella creazione dell'ordine:", error);
        // Gestisci qui l'errore
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      const name = details.payer.name.given_name;
      try {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        // Invio dei dati dell'ordine al backend
        await axios.post(
          process.env.REACT_APP_APP_ENDPOINT + "api/auth/saveOrder",
          {
            orderDetails: details,
            tokenAmount: quantitaToken,
          },
          { headers }
        );

        // Mostra toast di successo
        showToast(`Transazione completata da: ${name}`, "success");

        // Aggiorna il saldo del wallet
        await fetchSaldo();
      } catch (error) {
        console.error("Errore durante l'invio dell'ordine al backend:", error);
        // Mostra toast di errore
        showToast("Errore durante la transazione", "error");
      }
      setQuantitaToken("");
      setMetodoPagamento("");
    });
  };

  const initialOptions = {
    clientId:
      "ARTlXODwyOTLT3ETzqARSFcHtzTQRRsUF5BasQJH7MjR27gJa5U5U0tFwRvCv_FRzwlROsUyUMOg3225",
    currency: "EUR",
    intent: "capture",
    amount: quantitaToken,
    disableFunding: "card,mybank",
  };

  const stripePromise = useMemo(
    () =>
      loadStripe(
        "pk_test_51Oo0zlBMn1M0gyIjde5JRPLnjy7VcndiSyA6PIJCTTPFMxf3jCcA6qPyPY3Ms1DXD1cnaiszoNiQjFltghuoK62S00ppe5KAx2"
      ),
    []
  );

  return (
    <>
      <>
        {general.temporaryRole == "Agenzia" && (
          <Grid container spacing={2} sx={{ padding: "20px" }}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  p: 1,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Typography>
                    La tua agenzia ha un saldo di {saldoToken} BIDS
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined" // Opzionale, per uno stile più definito
                  >
                    Ricarica Conto
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  p: 1,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Typography>Richiedi Ricarica all'amministratore</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined" // Opzionale, per uno stile più definito
                  >
                    Richiedi
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  p: 1,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Typography>Effettua un Giroconto verso un utente</Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to={"/my-customers"}
                    sx={{ marginLeft: "auto" }} // Allinea il pulsante a destra
                  >
                    <Button
                      rel="noopener noreferrer"
                      variant="contained" // Opzionale, per uno stile più definito
                    >
                      Effettua Giroconto
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
      </>

      {general.temporaryRole === "Customer" && (
        <PayPalScriptProvider options={initialOptions}>
          <Elements stripe={stripePromise} options={{ appearance }}>
            <Grid container spacing={2} sx={{ padding: "20px" }}>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    p: 2,
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={5} sx={{ padding: "5px" }}>
                      <Grid item md={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h5" component="div" gutterBottom>
                          Saldo del Wallet
                        </Typography>
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          sx={{ mb: 3 }}
                        >
                          <div>
                            {" "}
                            <strong style={{ fontSize: "4rem" }}>
                              {saldoToken}
                            </strong>{" "}
                          </div>
                          BIDS
                        </Typography>
                      </Grid>

                      <Grid item md={9}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={6}
                            sx={{ borderLeft: "1px dotted black" }}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              gutterBottom
                            >
                              Acquista BIDS
                            </Typography>
                            <TextField
                              label="Quantità"
                              variant="outlined"
                              fullWidth
                              value={quantitaToken}
                              onChange={(e) => setQuantitaToken(e.target.value)}
                              type="number"
                              margin="normal"
                            />
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "2rem", marginTop: 5 }}
                            >
                              <strong>Totale:</strong> {totale} €
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography
                              variant="h5"
                              component="div"
                              gutterBottom
                            >
                              Metodo di Pagamento
                            </Typography>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                aria-label="metodo di pagamento"
                                name="row-radio-buttons-group"
                                value={metodoPagamento}
                                onChange={handlePaymentMethodChange}
                                sx={{ justifyContent: "start" }}
                              >
                                {/*     <FormControlLabel
                          value="paypal"
                          control={<Radio />}
                          label="PayPal"
                        />*/}
                                <FormControlLabel
                                  value="stripe"
                                  control={<Radio />}
                                  label="Carta di Credito"
                                />
                                <FormControlLabel
                                  value="bonifico"
                                  control={<Radio />}
                                  label="Bonifico Bancario"
                                />
                              </RadioGroup>
                            </FormControl>

                            {metodoPagamento === "paypal" && (
                              <Suspense fallback={<div>Loading...</div>}>
                                <PayPalButtons
                                  createOrder={createOrder}
                                  onApprove={onApprove}
                                />
                              </Suspense>
                            )}
                            {metodoPagamento === "stripe" && (
                              <Suspense fallback={<div>Loading...</div>}>
                                <StripePaymentForm
                                  totale={totale}
                                  totalToken={quantitaToken}
                                  appearance={appearance}
                                  updateWallet={fetchSaldo}
                                />
                              </Suspense>
                            )}
                            {metodoPagamento === "bonifico" && (
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={handlePayButtonClick}
                              >
                                Paga
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {/*<ModalDialog
              color="success"
              layout="center"
              size="md"
              variant="plain"
            > */}
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Messaggio
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {modalMessage}
                  </Typography>
                </Box>
                {/*  </ModalDialog> */}
              </Modal>

              <Modal
                open={isModalBonifico}
                onClose={closeSuccessModal}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
              >
                <Box sx={bonifico}>
                  <Typography variant="h6" component="h2" sx={{ mb: 5 }}>
                    Conferma Pagamento
                  </Typography>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Account: {general.username}
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                      BIDS: {quantitaToken}
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Totale: {totale} €
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Pagamento: bonifico bancario
                    </Typography>
                  </Box>
                  {toast.isActive("success") && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Il tuo ordine è stato effettuato con successo.
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 5 }}
                    onClick={handleBankTransferConfirm}
                  >
                    Conferma
                  </Button>
                </Box>
              </Modal>

              <Modal
                open={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Ordine ricevuto,
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Controlla la tua email con le indicazioni per effettuare il
                    bonifico.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => setIsSuccessModalOpen(false)}
                  >
                    Chiudi
                  </Button>
                </Box>
              </Modal>
            </Grid>
          </Elements>
        </PayPalScriptProvider>
      )}

      <Grid container spacng={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Le Tue Transazioni
              </Typography>
              <NewList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Wallet;
