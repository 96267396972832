import React, { useState, useEffect } from "react";
import axios from "axios";
import FieldsRenderer from "./fields-renderer";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  InputLabel,
  Card,
  CardContent,
  Typography,
  Modal,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useLocation } from "react-router-dom";

function DynamicWizarHoriontal({
  prefilledValues,
  completeAndRedirect,
  onClose,
  reloadRecords,
  hiddenFields,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [formData, setFormData] = useState(prefilledValues || {});
  const [skipped, setSkipped] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { object: urlObjectType } = useParams();
  const objectType = urlObjectType;
  const navigate = useNavigate(); // Hook per navigare
  const [updatedHiddenFields, setUpdatedHiddenFields] = useState();
  const [updatedPrefilledValues, setUpdatedPrefilledValues] = useState();

  useEffect(() => {
    // Aggiorna gli hiddenFields in base al valore di objectType
    const updatedHiddenFields = {
      ...hiddenFields,
      ...(objectType === "ordine" ? { title: true, status: "Da evadere" } : {}),
    };

    const updatedPrefilled = {
      ...prefilledValues,
      ...(objectType === "ordine" ? { status: "Da evadere" } : ""),
    };

    // Esegui l'azione necessaria con i nuovi hiddenFields
    // Ad esempio, puoi impostarli in uno stato o utilizzarli direttamente dove necessario
    setUpdatedHiddenFields(updatedHiddenFields);
    setUpdatedPrefilledValues(updatedPrefilled);
  }, [objectType, hiddenFields, prefilledValues]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getFieldsForObjectForm/${objectType}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setStepsData(response.data);
      })
      .catch((error) => {
        console.error("Errore nel recupero dei dati:", error);
      });
  }, [objectType]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleApiCall = async (completeAndRedirect) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/storeRecord/" +
          objectType,
        formData,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );

      if (completeAndRedirect) {
        toast.success("Dati salvati con successo!");
        setIsModalOpen(false);
        onClose && onClose(); // Additional callback if provided
        reloadRecords && reloadRecords(); // Additional callback if provided
        navigate(-1); // Torna alla pagina precedente
      } else {
        toast.success("Dati salvati con successo!");
        setModalMessage("Dati salvati con successo!");
        setIsModalOpen(false);
        reloadRecords && reloadRecords(); // Additional callback if provided
        onClose && onClose(); // Additional callback if provided
        navigate(-1); // Torna alla pagina precedente
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il salvataggio dei dati.";
      if (completeAndRedirect) {
        toast.error(errorMessage);
      } else {
        toast.error(errorMessage);

        setModalMessage(errorMessage);
        setIsModalOpen(true);
      }
    }
  };

  const handleSave = () => {
    handleApiCall(completeAndRedirect);
  };

  // Logica per gestire il salvataggio dei dati
  // (mantenuta invariata dal tuo componente originale)

  const renderStepContent = (stepKey) => {
    const fields = stepsData[stepKey] || [];

    return (
      <Grid container spacing={1}>
        {fields.map((field) => {
          const isReadOnly = Boolean(field.isReadOnly);
          if (
            isReadOnly ||
            (updatedHiddenFields &&
              Object.keys(updatedHiddenFields).includes(field.fieldName))
          ) {
            return null;
          }

          return (
            <Grid item xs={12} md={3} key={field.id}>
              <InputLabel htmlFor={field.name}>
                {field.label}
                {field.required && <span style={{ color: "red" }}> *</span>}
              </InputLabel>
              <FieldsRenderer
                columns={3}
                field={field}
                value={formData[field.fieldName]}
                onChange={handleInputChange}
                prefilledValues={updatedPrefilledValues}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const steps = Object.keys(stepsData);

  return (
    <Box sx={{ margin: 1, padding: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Tutti i passaggi completati - hai finito
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* Renderizza il contenuto dello step attuale */}
          <Card>
            <CardContent>{renderStepContent(steps[activeStep])}</CardContent>
          </Card>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Indietro
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep < steps.length - 1 ? (
                <Button onClick={handleNext}>Avanti</Button>
              ) : (
                <Button onClick={handleSave}>Completa ed Invia</Button>
              )}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

export default DynamicWizarHoriontal;
