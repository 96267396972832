import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Card,
  Avatar,
  CardHeader,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Icona per l'account

export default function AccountDipendenti({ account }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Resetta alla prima pagina con il nuovo numero di righe per pagina
  };

  // Calcola il range di dati da visualizzare
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - account?.length) : 0;
  const slicedData = account?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Card sx={{ marginBottom: 1 }}>
        <TableContainer component={Paper}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <AccountCircleIcon />
              </Avatar>
            }
            title="Account Associati"
            titleTypographyProps={{ variant: "h6" }}
          />
          <Table>
            <TableBody>
              {slicedData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <b>{row.username}</b>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <a
                      href={`mailto:${row.email}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {row.email}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={account?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </>
  );
}
