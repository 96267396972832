import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import DoorbellIcon from "@mui/icons-material/Doorbell";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Description as DescriptionIcon } from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SearchIcon from "@mui/icons-material/Search";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddHomeIcon from "@mui/icons-material/AddHome";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

const SidebarTypography = styled(Typography)`
  font-family: "Inter SemiBold", sans-serif;
`;

export const customer = (
  <>
    <ListItemButton
      component={Link}
      to="/dashboard"
      sx={{
        background: "rgba(255, 255, 255, 0.4)",
        marginInline: 2,
        borderRadius: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        marginBottom: 5,
      }}
    >
      <ListItemIcon>
        <DashboardIcon sx={{ color: "#E1251B" }} />
      </ListItemIcon>
      <SidebarTypography
        variant="body2"
        style={{ color: "#E1251B", fontWeight: "bold" }}
      >
        Dashboard
      </SidebarTypography>
    </ListItemButton>

    <ListItemText
      disableTypography
      primary={
        <Typography
          variant="body2"
          style={{ color: "#ffffff", fontWeight: "bold" }}
          sx={{ ml: 2 }}
        >
          INSERZIONI & ASTE
        </Typography>
      }
    />
    <ListItemButton
      component={Link}
      to="/Le-mie-inserzioni"
      sx={{ marginInline: 2, borderRadius: "5px" }}
    >
      <ListItemIcon>
        <HomeWorkIcon sx={{ color: "#01685D" }} />
      </ListItemIcon>
      <ListItemText primary="I miei immobili" sx={{ color: "#01685D" }} />
    </ListItemButton>

    <ListItemButton
      component={Link}
      to="/ricerca-pubblica"
      target="_blank" // Apre il link in una nuova scheda
      rel="noopener noreferrer" // Migliora la sicurezza e l'accessibilità
      sx={{ marginInline: 2, borderRadius: "5px" }}
    >
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
      <ListItemText primary="Ricerca" sx={{ color: "#01685D" }} />
      <ListItemIcon>
        <OpenInNewIcon sx={{ fontSize: "1rem" }} />
      </ListItemIcon>
    </ListItemButton>

    <ListItemText
      disableTypography
      primary={
        <Typography
          variant="body2"
          style={{ color: "#ffffff", fontWeight: "bold" }}
          sx={{ ml: 2 }}
        >
          ACCOUNT
        </Typography>
      }
    />
    <ListItemButton
      component={Link}
      to="/mio-profilo"
      sx={{ marginInline: 2, borderRadius: "5px" }}
    >
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Il mio Profilo" sx={{ color: "#01685D" }} />
    </ListItemButton>
    <ListItemButton
      component={Link}
      to="/wallet"
      sx={{ marginInline: 2, borderRadius: "5px" }}
    >
      <ListItemIcon>
        <AccountBalanceWalletIcon />
      </ListItemIcon>
      <ListItemText primary="Conto BIDS" sx={{ color: "#01685D" }} />
    </ListItemButton>
  </>
);

export const agenzia = [
  { ...customer },

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        AGENZIA
      </Typography>
    }
  />,

  <ListItemButton
    component={Link}
    to="/clienti-operatori"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Clienti Agenzia" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/contratti"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DescriptionIcon />
    </ListItemIcon>
    <ListItemText primary="Contratti" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/my-customers"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Giroconto BIDS" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/User/create/"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Nuovo Cliente" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <InsertChartIcon />
    </ListItemIcon>
    <ListItemText primary="Immobili" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile/create"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <AddHomeIcon />
    </ListItemIcon>
    <ListItemText primary="Carica Immobile" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/settingsAgency"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Generali" sx={{ color: "#01685D" }} />
  </ListItemButton>,
];

export const superAgency = [
  { ...customer },

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        AGENZIA
      </Typography>
    }
  />,

  <ListItemButton
    component={Link}
    to="/clienti-operatori"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Clienti Agenzia" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/contratti"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DescriptionIcon />
    </ListItemIcon>
    <ListItemText primary="Contratti" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/my-customers"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Giroconto BIDS" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/User/create/"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Nuovo Cliente" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <InsertChartIcon />
    </ListItemIcon>
    <ListItemText primary="Immobili" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile/create"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <AddHomeIcon />
    </ListItemIcon>
    <ListItemText primary="Carica Immobile" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/settingsAgency"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Generali" sx={{ color: "#01685D" }} />
  </ListItemButton>,
];

export const master = [
  { ...customer },

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        MASTER
      </Typography>
    }
  />,
  <ListItemButton
    component={Link}
    to="/my-customers"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Giroconto BIDS" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/User/create/"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DoorbellIcon />
    </ListItemIcon>
    <ListItemText primary="Nuovo Cliente" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <InsertChartIcon />
    </ListItemIcon>
    <ListItemText primary="Immobili" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile/create"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <AddHomeIcon />
    </ListItemIcon>
    <ListItemText primary="Carica Immobile" sx={{ color: "#01685D" }} />
  </ListItemButton>,
];

export const admin = [
  { ...customer },

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        MASTER
      </Typography>
    }
  />,
  <ListItemButton
    component={Link}
    to="/my-customers"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SyncAltIcon />
    </ListItemIcon>
    <ListItemText primary="Giroconto BIDS" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/User/create/"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <PersonAddIcon />
    </ListItemIcon>
    <ListItemText primary="Nuovo Cliente" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        AMMINISTRAZIONE
      </Typography>
    }
  />,

  <ListItemButton
    component={Link}
    to="/adminList/immobile"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <ListAltIcon />
    </ListItemIcon>
    <ListItemText primary="Lista Immobili" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/immobile/create"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <AddHomeIcon />
    </ListItemIcon>
    <ListItemText primary="Carica Immobile" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/tipologieImmobili"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Tipologie Immobili" sx={{ color: "#01685D" }} />
  </ListItemButton>,

  <ListItemButton
    component={Link}
    to="/adminList/extras"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Opzioni Extra" sx={{ color: "#01685D" }} />
  </ListItemButton>,

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        UTENTI E AGENZIE
      </Typography>
    }
  />,
  <ListItemButton
    component={Link}
    to="/adminList/User"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <ListAltIcon />
    </ListItemIcon>
    <ListItemText primary="Lista Utenti" sx={{ color: "#01685D" }} />
  </ListItemButton>,

  <ListItemButton
    component={Link}
    to="/adminList/agenzie"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <ListAltIcon />
    </ListItemIcon>
    <ListItemText primary="Lista Agenzie" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/playlist"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Playlist" sx={{ color: "#01685D" }} />
  </ListItemButton>,

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        CONTABILITA
      </Typography>
    }
  />,

  <ListItemButton
    component={Link}
    to="/adminList/ordine"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Ordini" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/contratti"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <DescriptionIcon />
    </ListItemIcon>
    <ListItemText primary="Contratti" sx={{ color: "#01685D" }} />
  </ListItemButton>,
  <ListItemButton
    component={Link}
    to="/adminList/commission"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <MonetizationOnIcon />
    </ListItemIcon>
    <ListItemText primary="Commissioni" sx={{ color: "#01685D" }} />
  </ListItemButton>,

  <ListItemText
    disableTypography
    primary={
      <Typography
        variant="body2"
        style={{ color: "#ffffff", fontWeight: "bold" }}
        sx={{ ml: 2 }}
      >
        IMPOSTAZIONI
      </Typography>
    }
  />,
  <ListItemButton
    component={Link}
    to="/settings"
    sx={{ marginInline: 2, borderRadius: "5px" }}
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary="Generali" sx={{ color: "#01685D" }} />
  </ListItemButton>,
];
