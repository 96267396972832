import React from "react";
import { Card, CardContent, CardHeader, Avatar, Typography, Chip, Button } from "@mui/material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function UserWalletCard({ saldo }) {

  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));

  return (
    <Card sx={{ marginBottom: 2, boxShadow: 3 }}>
      <CardHeader

        avatar={
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <AccountBalanceWalletIcon />
          </Avatar>
        }
        title="Conto BIDS"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ paddingTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body1">Il Saldo di questo account è di: <b>{saldo}</b> Crediti</Typography>
      </CardContent>
      <Link to={"/my-customers"}>
        <ColoredButton
          sx={{ border: "1.5px solid #66c9ba", width: "100%" }}
          size="small"
          color="primary">
          Ricarica
        </ColoredButton>
      </Link>
    </Card>
  );
}
