import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardHeader,
  Avatar,
  Grid,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CustomCheckbox from "components/customInputs/Checkbox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Lightbox from "react-modal-image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useLocation } from "react-router-dom";
import SimpleDynamicForm from "../../components/simpleDynamicform";
import AccountProprietario from "./User/AccountProprietario";
import UserWalletCard from "./User/UserWalletCard";
import AccountDipendenti from "./User/AccountDipendenti";
import Inserzioni from "./User/Inserzioni";
import Ordini from "./User/Ordini";
import DocumentForm from "components/layout/DocumentForm";
import PhotoForm from "./../../components/layout/PhotoForm";
import axios from "axios";
import toast from "react-hot-toast";
import GetDataAgency from "./show/GetDataAgency";
import { useSelector } from "react-redux";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

function Show() {
  const location = useLocation();
  const currentFormType = location.pathname.split("/")[2];
  const [openImage, setOpenImage] = useState(null);
  const openLightbox = (src) => setOpenImage(src);
  const [account, setAccount] = useState([]);
  const currentRecordId = location.pathname.split("/")[4];
  const [rilanciData, setRilanciData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowsData, setFormFieldsRows] = useState([]);
  const general = useSelector((state) => state.general);
  const [checkboxState, setCheckboxState] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [modificaText, setModificaText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;
        const response = await axios.get(
          `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
          { headers }
        );
        setAccount(response.data);
      } catch (error) {
        console.error("Errore nella chiamata API", error);
      }
    };

    fetchData();
  }, [currentFormType, currentRecordId]);
  useEffect(() => {
    // Assumendo che account sia già stato impostato dal fetch precedente
    const rilanci =
      account.customRelations?.MasterDetail?.Rilanci?.relatedRecords;
    if (rilanci) {
      // Aggiorna lo stato con i dati dei rilanci
      setRilanciData(rilanci);
    }
  }, [account]); // Dipende da account, aggiornato dalla fetch

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    actionType: "",
  });

  const [editDialog, setEditDialog] = useState({
    open: false,
    actionType: "",
  });

  const editHandleAction = (actionType) => {
    setEditDialog({ open: true, actionType });
  };

  const handleAction = (actionType) => {
    setConfirmDialog({ open: true, actionType });
  };

  const handleConfirmApprove = async () => {
    const { actionType } = confirmDialog;
    // Costruisci l'URL e il payload in base all'azione
    const endpoint = process.env.REACT_APP_APP_ENDPOINT;
    const url = `${endpoint}api/auth/editHouseStatus`;
    const payload = {
      id: currentRecordId,
      status: actionType,
    };

    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(url, payload, { headers });

      if (response.status === 200) {
        const updatedRows = rowsData.map((row) => {
          if (row.id === currentRecordId) {
            return { ...row, status: payload.status };
          }
          return row;
        });

        setFormFieldsRows(updatedRows);

        toast.success("Stato aggiornato con successo");
      } else {
        throw new Error("Errore nella risposta API");
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento dello stato:", error);
      toast.error("Errore nell'aggiornamento dello stato: " + error.message);
    } finally {
      setConfirmDialog({ open: false, actionType: "" });
    }
  };

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCheckboxState({ checkbox1: false, checkbox2: false, checkbox3: false });
    setModificaText("");
  };

  const handleCheckboxChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleModificaTextChange = (event) => {
    setModificaText(event.target.value);
  };

  const handleEditDialogSubmit = async () => {
    if (!modificaText.trim()) {
      toast.error("Il campo di testo per la modifica è obbligatorio.");
      return;
    }

    try {
      const response = await axios.post("Your_API_Endpoint", {
        checkboxes: checkboxState,
        modificaText: modificaText,
      });
      if (response.status === 200) {
        toast.success("Modifica richiesta con successo!");
      } else {
        toast.error("Qualcosa è andato storto.");
      }
    } catch (error) {
      toast.error("Errore durante l'esecuzione dell'azione.");
    }
    handleEditDialogClose();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 1 }}>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <SimpleDynamicForm
                isShow="true"
                hiddenFields={{
                  password: true,
                  ...(general.temporaryRole === "Agenzia"
                    ? { username: true }
                    : {}),
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          {currentFormType === "immobile" && (
            <Card sx={{ marginBottom: 1 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    <PhotoCameraIcon />
                  </Avatar>
                }
                title="Accetta immobile"
                titleTypographyProps={{ variant: "h6" }}
                subheader=""
              ></CardHeader>
              <CardContent>
                <Button
                  color="success"
                  onClick={() => handleAction("Approvato")} // Passa lo stato "approved"
                  startIcon={<CheckCircleOutlinedIcon />}
                >
                  Approva
                </Button>

                <Button
                  color="error"
                  onClick={() => handleAction("Rifiutato")} // Passa lo stato "rejected"
                  startIcon={<ClearOutlinedIcon />}
                >
                  Rifiuta
                </Button>
                <Button
                  color="warning"
                  onClick={() => handleAction("Richiesta Modifica")}
                  startIcon={<ModeEditOutlinedIcon />}
                >
                  Richiedi Modifica
                </Button>

                <Button
                  color="success"
                  onClick={() => handleAction("Attiva")}
                  startIcon={<PlayCircleOutlineIcon fontSize="small" />}
                >
                  Attiva
                </Button>

                <Button
                  color="error"
                  onClick={() => handleAction("Sospesa")}
                  startIcon={<PauseCircleOutlineIcon fontSize="small" />}
                >
                  Sospendi
                </Button>
              </CardContent>
            </Card>
          )}
          {currentFormType === "User" && (
            <>
              {account.temporaryRole == "Customer" && (
                <>
                  <UserWalletCard
                    account={account}
                    saldo={account?.saldoWallet?.original?.saldoWallet}
                  />
                  <AccountProprietario account={account.parent_account} />
                  <Inserzioni account={account} />

                  <Card sx={{ marginBottom: 1 }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: "primary.main" }}>
                          <PhotoCameraIcon />
                        </Avatar>
                      }
                      title="Documentazione"
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      {/* Lista di documenti */}
                      <DocumentForm isShow="true" />
                    </CardContent>
                  </Card>
                </>
              )}

              {account.temporaryRole != "Customer" &&
                account.temporaryRole != "Agenzia" && (
                  <Card sx={{ marginBottom: 1 }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: "primary.main" }}>
                          <PhotoCameraIcon />
                        </Avatar>
                      }
                      title="Agenzie"
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>{/* Lista di documenti */}</CardContent>
                  </Card>
                )}
              {account.temporaryRole != "Customer" &&
                account.temporaryRole != "Admin" && (
                  <AccountDipendenti account={account.subordinates} />
                )}
            </>
          )}

          {currentFormType === "agenzie" && (
            <>
              <GetDataAgency />
            </>
          )}

          {currentFormType === "immobile" && (
            <>
              <Card sx={{ marginBottom: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      <PhotoCameraIcon />
                    </Avatar>
                  }
                  title="Galleria Foto"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  <PhotoForm />
                </CardContent>
              </Card>

              <Card sx={{ marginBottom: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      <PhotoCameraIcon />
                    </Avatar>
                  }
                  title="Documentazione"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  {/* Lista di documenti */}
                  <DocumentForm isShow="true" />
                </CardContent>
              </Card>
            </>
          )}

          {currentFormType === "immobile" && (
            <>
              <Card sx={{ marginBottom: 1 }}>
                <CardHeader
                  avatar={<Avatar sx={{ bgcolor: "primary.main" }}></Avatar>}
                  title="Storico Rilanci"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  {rilanciData.length > 0 && (
                    <Table aria-label="Cronologia Rilanci">
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Utente</TableCell>
                          <TableCell>Importo</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rilanciData.map((rilancio) => (
                          <TableRow key={rilancio.id}>
                            <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                              {rilancio.relatedValues["Creato il"]}
                            </TableCell>

                            <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                              {rilancio.relatedValues.Proprietario}
                            </TableCell>
                            <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                              {new Intl.NumberFormat("it-IT", {
                                style: "currency",
                                currency: "EUR",
                              }).format(rilancio.relatedValues.Importo)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}

                  {rilanciData.length < 1 && <>Nessun dato disponibile</>}
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, actionType: "" })}
      >
        <DialogTitle>Conferma Azione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler procedere con questa azione?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialog({ open: false, actionType: "" })}
          >
            Annulla
          </Button>
          <Button onClick={handleConfirmApprove} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Richiedi Modifiche</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox1}
                onChange={handleCheckboxChange}
                name="checkbox1"
              />
            }
            label="Dati anagrafici"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox2}
                onChange={handleCheckboxChange}
                name="checkbox2"
              />
            }
            label="Documentazione"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox3}
                onChange={handleCheckboxChange}
                name="checkbox3"
              />
            }
            label="Foto o video"
          />
          <TextField
            margin="dense"
            id="modificaText"
            label="Dettagli Modifica"
            type="text"
            fullWidth
            variant="outlined"
            value={modificaText}
            onChange={handleModificaTextChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Annulla</Button>
          <Button onClick={handleEditDialogSubmit}>Invia</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Show;
