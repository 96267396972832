import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  Avatar,
  CardHeader,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HomeIcon from '@mui/icons-material/Home'; // Icona per l'immobile

export default function Immobili({ account }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const immobili = account?.immobili ?? [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));

  return (
    <Card sx={{ marginBottom: 1 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <HomeIcon />
          </Avatar>
        }
        title="Immobili"
        titleTypographyProps={{ variant: "h6" }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {immobili
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((immobile, index) => {
                const indirizzo = immobile.related_values.find(val => val.custom_field.fieldName === 'title')?.value || 'N/A';
                const stato = immobile.related_values.find(val => val.custom_field.fieldName === 'status')?.value || 'N/A';
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}><b>{indirizzo}</b></TableCell>
                    <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{stato}</TableCell>
                  </TableRow>
                );
              })}

            {immobili.length === 0 && (
              <TableRow>
                <TableCell>Nessun Immobile trovato</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={immobili.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
