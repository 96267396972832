import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "styled-components";
import axios from "axios";
import {
  Typography,
  Box,
  Grid,
  Stack,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";

const CustomInputLabel = (props) => (
  <label htmlFor={props.htmlFor} id={props.id} {...props.sx}>
    {props.children}
  </label>
);

function MySelectField({ id, label, value, placeholder, onChange, children }) {
  const theme = useTheme();

  return (
    <TextField
      id={id}
      select
      label={
        <CustomInputLabel
          htmlFor={id}
          id={`${id}-label`}
          sx={{
            position: "relative",
            top: value ? 0 : "50%",
          }}
        >
          {label}
        </CustomInputLabel>
      }
      variant="outlined"
      fullWidth
      margin="normal"
      InputLabelProps={{ shrink: true }}
      value={value}
      onChange={onChange}
      SelectProps={{
        displayEmpty: true,
        renderValue: (value) =>
          value === "" ? (
            <span style={{ color: "#616161" }}>{placeholder}</span>
          ) : (
            value
          ),
      }}
      placeholder={placeholder}
      InputProps={{
        sx: {
          fontSize: theme.breakpoints.up("sm") ? "1.5rem" : "1rem",
          width: "100%",
          font: "menu",
        },
      }}
    >
      {children}
    </TextField>
  );
}

export default function IlMioProfilo() {
  const theme = useTheme();
  const [userData, setUserData] = useState({});
  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const general = useSelector((state) => state.general);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint =
          process.env.REACT_APP_APP_ENDPOINT +
          "api/auth/user/" +
          general.userId;

        const response = await axios.get(endpoint, { headers });
        setUserData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Errore nel recupero dei dati utente:", error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    console.log("seuId:", general.userId);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/user/${general.userId}`;

      const response = await axios.post(endpoint, userData, { headers });

      setSnackbarMessage("Modifiche salvate con successo!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      console.log("Risposta dalla richiesta POST:", response.data);
    } catch (error) {
      console.error("Errore nella richiesta POST:", error);
      setSnackbarMessage(
        "Si è verificato un errore durante il salvataggio delle modifiche."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [id]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileChange = (e) => {
    const { id } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.onloadend = () => {
        const base64Data = reader.result;

        setUserData((prevUserData) => ({
          ...prevUserData,
          ...prevUserData.files,
          [id]: base64Data,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));

  return (
    <Grid container padding={2}>
      <Box
        sx={{
          width: "100%",
          background: "#fff",
          borderRadius: "18px",
          boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15) ",
        }}
      >
        <Box
          sx={{
            p: 3,
            overflowY: "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h6" sx={{ mb: "1rem" }}>
            Informazioni Personali
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Nome</Typography>
              <TextField
                fullWidth
                id="name"
                value={userData.name}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Cognome</Typography>
              <TextField
                fullWidth
                id="name"
                value={userData.cognome}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                id="email"
                value={userData.email}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Username</Typography>
              <TextField
                fullWidth
                id="username"
                value={userData.username}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Telefono</Typography>
              <TextField
                fullWidth
                id="telefono"
                value={userData.telefono}
                placeholder="+39"
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography>Indirizzo</Typography>
              <TextField
                fullWidth
                id="address"
                value={userData.address}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography>Regione</Typography>
              <TextField
                fullWidth
                id="regione"
                value={userData.region}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Provincia</Typography>
              <TextField
                fullWidth
                id="provincia"
                value={userData.province}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Città</Typography>
              <TextField
                fullWidth
                id="citta"
                value={userData.city}
                placeholder=""
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Stack
            direction={theme.breakpoints.up("sm") ? "row" : "column"}
            spacing={2}
            justifyContent="center"
            mt={2}
            sx={{ justifyContent: "flex-end" }}
          >
            <ColoredButton
              size="large"
              sx={{
                border: "1px solid",
              }}
              onClick={handleSave}
            >
              Salva
            </ColoredButton>
          </Stack>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity={snackbarSeverity === "success" ? "success" : "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
