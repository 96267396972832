import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Skeleton,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GavelIcon from "@mui/icons-material/Gavel";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { styled } from "styled-components";
import { CardMedia } from "@mui/material";
import TempoScadenza from "components/icons/TempoScadenza";
import UltimaOfferta from "./UltimaOfferta";
import RilancioMinimo from "./RilancioMinimo";
import RilanciTotali from "components/icons/RilanciTotali";
import SuperficieIcon from "components/icons/Supercie";
import LocaliIcon from "components/icons/Locali";

const TutteLeAstePage = () => {
  const [cards, setCards] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  useEffect(() => {
    // Chiamata per recuperare la configurazione dei filtri
    fetchFilterConfigs("immobile");
    setLoading(false);
  }, []);

  useEffect(() => {
    // Chiamata per recuperare i dati in base ai filtri selezionati
    fetchData(selectedFilters);
  }, [selectedFilters]);

  const fetchFilterConfigs = async (
    objectName,
    relatedObjects = ["immobile"]
  ) => {
    try {
      setLoading(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getFieldsForObjectForm/${objectName}`,
        { headers: headers }
      );

      let filtersForObject = [];

      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const fields = response.data[key];
          const filteredFields = fields
            .filter((field) => field.isFiltrable)
            .map((field) => ({ ...field, objectName }));
          filtersForObject = filtersForObject.concat(filteredFields);
        }
      }

      setFilterConfigs((prev) => [...prev, ...filtersForObject]);

      // Recupero delle configurazioni per gli oggetti correlati
      relatedObjects.forEach((relatedObject) => {
        if (
          !filtersForObject.some(
            (config) => config.objectName === relatedObject
          )
        ) {
          fetchFilterConfigs(relatedObject);
        }
      });
    } catch (error) {
      console.error(
        `Errore nel recupero della configurazione dei filtri per ${objectName}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (selectedFilters) => {
    try {
      setLoadingCard(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: `application/json`,
        "Content-Type": `application/json`,
      };

      let filtersPayload = {};

      // Creazione del payload per i filtri
      filterConfigs.forEach((config) => {
        const objectName = config.objectName || "default";
        const fieldName = config.fieldName;
        const filterValue = selectedFilters[fieldName];

        if (filterValue !== undefined) {
          if (!filtersPayload[objectName]) {
            filtersPayload[objectName] = {};
          }

          switch (config.filterType) {
            case "range":
              filtersPayload[objectName][fieldName] = {
                min: filterValue.min,
                max: filterValue.max,
                step: filterValue.step,
              };
              break;
            default:
              filtersPayload[objectName][fieldName] = filterValue;
              break;
          }
        }
      });

      const queryString = `filterPayload=${encodeURIComponent(
        JSON.stringify(filtersPayload)
      )}`;
      const url = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getRecords/immobile?${queryString}`;

      const response = await axios.get(url, { headers: headers });

      setCards(() => [...response.data]);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoadingCard(false);
    }
  };

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const handleUpdateClick = () => {
    fetchData(selectedFilters);
  };

  const generateFilterComponent = (filterConfig) => {
    const options = filterConfig?.options?.split(",") || [];

    switch (filterConfig.filterType) {
      case "range":
        const minOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          filterConfig.step !== undefined ? filterConfig.step : 50
        );
        const maxOptions = generateRangeOptions(
          filterConfig.min || 0,
          filterConfig.max || 2000,
          filterConfig.step !== undefined ? filterConfig.step : 50
        );

        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                value={
                  selectedFilters[filterConfig.fieldName]?.min ||
                  filterConfig.min ||
                  0
                }
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    min: e.target.value,
                  })
                }
                displayEmpty
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                {minOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={
                  selectedFilters[filterConfig.fieldName]?.max ||
                  filterConfig.max ||
                  100
                }
                onChange={(e) =>
                  handleFilterChange(filterConfig.fieldName, {
                    ...selectedFilters[filterConfig.fieldName],
                    max: e.target.value,
                  })
                }
                displayEmpty
                sx={{ width: "100%", borderRadius: "8px" }}
              >
                {maxOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        );
      case "select":
        return (
          <Select
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
            displayEmpty
            sx={{ width: "100%", borderRadius: "8px" }}
          >
            <MenuItem key="default" value="">
              Seleziona
            </MenuItem>

            {options.map((option, index) => (
              <MenuItem key={index} value={option.trim()}>
                {option.trim()}
              </MenuItem>
            ))}
          </Select>
        );
      case "multiselect":
        return (
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clicca per cercare"
                variant="standard"
              />
            )}
            sx={{ width: "100%", borderRadius: "8px" }}
          />
        );
      default:
        return (
          <TextField
            value={selectedFilters[filterConfig.fieldName] || ""}
            onChange={(e) =>
              handleFilterChange(filterConfig.fieldName, e.target.value)
            }
          />
        );
    }
  };

  function generateRangeOptions(min, max, step = 1) {
    const options = [];
    for (let i = min; i <= max; i += step) {
      options.push(i);
    }
    return options;
  }

  const goToRoute = (id) => {
    navigate(`/list/immobile/show/${id}`);
  };

  // Componenti stilizzati con Styled Components
  const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));
  const ApplyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#94d9cf",
    color: "#0d7267",
    borderRadius: "20px",
    padding: "10px 0 10px 0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));
  const ResetButton = styled(Button)(({ theme }) => ({
    backgroundColor: "red",
    color: "#94d9cf",
    borderRadius: "20px",
    padding: "10px 0 10px 0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#66c9ba",
    },
  }));

  return (
    <Grid
      container
      spacing={1}
      sx={{
        px: 4,
        py: 4,
        flexDirection: {
          xs: "column-reverse",
          sm: "column-reverse",
          md: "row",
        },
      }}
    >
      {/* Colonna da 9 con le card */}
      <Grid item xs={12} sm={8}>
        <Box p={2}>
          <Grid container spacing={2}>
            {isSmallScreen && (
              <Drawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              >
                <Card>
                  <CardHeader title="Filtra le aste disponibili" />
                  <CardContent>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {filterConfigs.map((filterConfig, index) => (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1">
                              {filterConfig.label}
                            </Typography>
                            {generateFilterComponent(filterConfig)}
                          </div>
                        ))}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateClick}
                          sx={{ mt: 2 }}
                        >
                          {loading ? (
                            <Skeleton width={150} height={36} />
                          ) : (
                            "Applica Filtri"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ ml: 1, mt: 2 }}
                        >
                          {loading ? (
                            <Skeleton width={100} height={36} />
                          ) : (
                            "Reset"
                          )}
                        </Button>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Drawer>
            )}

            <Grid item xs={12} container spacing={2}>
              {loadingCard
                ? Array.from({ length: 6 }).map((_, index) => (
                    <Grid item xs={4} key={index}>
                      <Card>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={200}
                          sx={{
                            borderRadius: "8px 8px 0 0",
                            backgroundColor: "#e0e0e0",
                          }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            <Skeleton
                              animation="wave"
                              height={20}
                              width="80%"
                            />
                          </Typography>
                          <Box display="flex" alignItems="center" mb={2}>
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="40%"
                              />
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                : cards.map((card) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={card.id}>
                      <Card
                        sx={{
                          boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.15)",
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <CardMedia
                            component="img"
                            alt="Immagine dell'asta"
                            image={
                              card?.customRelations?.Lookup?.Immobile
                                ?.relatedRecords?.[0]?.relatedValues?.[
                                "Foto Principale"
                              ] ||
                              "https://placehold.co/1200x1080?text=Nessun+immagine"
                            }
                            sx={{
                              objectFit: "cover",
                              maxHeight: 180,
                              filter:
                                "drop-shadow(2px 4px 11px rgba(0, 0, 0, 0.15))",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          />
                          {card?.houseType && (
                            <span
                              style={{
                                position: "absolute",
                                top: 10,
                                left: 10,
                                background: "#66C9BA",
                                color: "#01685D",
                                borderRadius: 5,
                                padding: "2px 8px",
                              }}
                            >
                              {card?.houseType}
                            </span>
                          )}
                          {card?.type && (
                            <span
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                background: "#d32f2f",
                                borderRadius: 5,
                                padding: "2px 8px",
                              }}
                            >
                              {card?.type}
                            </span>
                          )}
                        </div>
                        <CardContent sx={{ px: 3 }}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b>{card.title}</b>
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <TempoScadenza />
                            <Typography variant="body2" color="text.secondary">
                              Scadenza: {card.duration}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <SuperficieIcon
                              fillColor="#FF0000"
                              width="20"
                              height="20"
                            />
                            <Typography variant="body2" color="text.secondary">
                              Superficie: {card.mq}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <LocaliIcon
                              fillColor="#FF0000"
                              width="20"
                              height="20"
                            />
                            <Typography variant="body2" color="text.secondary">
                              Numero locali: {card.roomQty}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <UltimaOfferta />
                            <Typography variant="body2" color="text.secondary">
                              Ultima Offerta:
                              {card?.actualOffer?.toLocaleString("it-IT", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <RilancioMinimo />
                            <Typography variant="body2" color="text.secondary">
                              Base d'asta:
                              {card &&
                                card.actualOffer
                                  .toLocaleString("it-IT", {
                                    style: "currency",
                                    currency: "EUR",
                                  })
                                  .replace("€", "")}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{ gap: 1.5 }}
                          >
                            <RilanciTotali />
                            <Typography variant="body2" color="text.secondary">
                              Quantità Offerte: {card.qtyTotalBidsAmount}
                            </Typography>
                          </Box>
                          <ColoredButton
                            sx={{
                              border: "1.5px solid #66C9BA",
                              background: "#66C9BA",
                              color: "#01685D",
                              borderRadius: "16px",
                              width: "100%",
                              "&:hover": {
                                background: "#66C9BA",
                                border: "solid #01685D",
                                color: "#01685D",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                              },
                            }}
                            size="small"
                            onClick={() => goToRoute(card.id)}
                          >
                            <b>Vai all'asta</b>
                          </ColoredButton>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Colonna da 3 con i filtri */}
      <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ position: "sticky", top: 112 }}>
          <Card sx={{ borderRadius: "15px" }}>
            <CardHeader
              sx={{ paddingBottom: 0 }}
              title={
                <Typography
                  variant="h6"
                  sx={{
                    color: "#01685d",
                    fontSize: "1.1rem",
                    fontWeight: "700",
                  }}
                >
                  FILTRA LE ASTE DISPONIBILI
                </Typography>
              }
            />
            <CardContent>
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {filterConfigs.map((filterConfig, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="700"
                        fontSize="1.1rem"
                      >
                        {filterConfig.label}
                      </Typography>
                      {generateFilterComponent(filterConfig)}
                    </div>
                  ))}
                  <Box display="flex" justifyContent="center">
                    <ApplyButton
                      variant="contained"
                      onClick={handleUpdateClick}
                      sx={{
                        mt: 2,
                        width: "150px",
                        marginRight: "10px",
                        fontWeight: "700",
                        ml: 1,
                        mt: 2,
                        width: "150px",
                        fontWeight: "700",
                        backgroundColor: "#66c9ba",
                        border: "2px solid transparent",
                        color: "#01685D",
                        borderRadius: "20px",
                        padding: "10px 0 10px 0",
                        "&:hover": {
                          border: "2px  solid #66c9ba",
                          backgroundColor: "#01685D",
                          color: "#66c9ba",
                        },
                      }}
                    >
                      {loading ? (
                        <Skeleton width={150} height={36} />
                      ) : (
                        "FILTRA"
                      )}
                    </ApplyButton>
                    <ResetButton
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: "150px",
                        fontWeight: "700",
                        backgroundColor: "#01685D",
                        border: "2px solid transparent",
                        color: "#66c9ba",
                        borderRadius: "20px",
                        padding: "10px 0 10px 0",
                        "&:hover": {
                          border: "2px solid #01685D",
                          backgroundColor: "#66c9ba",
                          color: "#01685D",
                        },
                      }}
                    >
                      {loading ? <Skeleton width={100} height={36} /> : "RESET"}
                    </ResetButton>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TutteLeAstePage;
