import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  temporaryRole: "Customer",
  username: "",
  userId: null,
  tokens: 0,
  agencyId: null
};

const reducers = {
  setAgency(state, action) {
    return {
      ...state,
      agencyId: action.payload,
    };
  },
  setRole(state, action) {
    return {
      ...state,
      temporaryRole: action.payload,
    };
  },
  setStatus(state, action) {
    return {
      ...state,
      status: action.payload,
    };
  },
  setTokens(state, action) {
    return {
      ...state,
      tokens: action.payload,
    };
  },
  setUserId(state, action) {
    return {
      ...state,
      userId: action.payload,
    };
  },
  setUsername(state, action) {
    return {
      ...state,
      username: action.payload,
    };
  },
  resetState() {
    return initialState;
  },
};

export const slice = createSlice({
  name: "general",
  initialState,
  reducers,
});

export const { reducer } = slice;
