import { slice } from "../slices/general";

const setRole = (params) => async (dispatch) => {
  dispatch(slice.actions.setRole(params));
};
const setTokens = (params) => async (dispatch) => {
  dispatch(slice.actions.setTokens(params));
};
const setStatus = (params) => async (dispatch) => {
  dispatch(slice.actions.setStatus(params));
};
const setUserId = (params) => async (dispatch) => {
  dispatch(slice.actions.setUserId(params));
};
const setAgency = (params) => async (dispatch) => {
  dispatch(slice.actions.setAgency(params));
};
const setUsername = (params) => async (dispatch) => {
  dispatch(slice.actions.setUsername(params));
};
const resetState = () => async (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const thunks = {
  setRole,
  setUsername,
  setStatus,
  setUserId,
  setTokens,
  resetState,
  setAgency,
};
