import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// ... altri import necessari ...

const AutocompleteMultipleLookup = ({
  field,
  fieldName,
  formType,
  isCreate,
  isEdit,
  isShow,
  values,
  fieldErrors,
  onChange,
  value,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const fieldRef = useRef(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const endpoint =
          process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getRecords/${field.relatedObject}`;
        const response = await axios.get(endpoint, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        if (Array.isArray(response.data)) {
          let extractedOptions = response.data.map((item) => ({
            id: item.id,
            label:
              field.relatedObject === "User"
                ? `${item.name} (${item.email}) - ${item.temporaryRole}`
                : item.title,
          }));

          setOptions(extractedOptions);

          // Qui gestisci il valore iniziale (o i valori) se presente
          if (value) {
            const selected = extractedOptions.filter((option) =>
              value.includes(option.id)
            );
            setSelectedOptions(selected);
          } else if (values && values[fieldName]) {
            const selected = extractedOptions.filter((option) =>
              values[fieldName].includes(option.id)
            );
            setSelectedOptions(selected);
          }
        }
      } catch (error) {
        console.error("Errore nella chiamata all'endpoint:", error);
      }
    };

    fetchOptions();
  }, [field, value, values, fieldName]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);

    // Converti l'array di id in una stringa JSON
    const newValueIdsString = JSON.stringify(newValue.map((item) => item.id));

    // Qui passi la stringa JSON al metodo onChange
    onChange(field.name, newValueIdsString);
  };

  return (
    <Autocomplete
      sx={{ height: "50px" }}
      ref={fieldRef}
      multiple={true} // Modifica qui per abilitare la selezione multipla
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Clicca per cercare"
          variant="standard"
          error={Boolean(fieldErrors)}
          helperText={fieldErrors || ""}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option.label} {...getTagProps({ index })} />
        ))
      }
    />
  );
};

export default AutocompleteMultipleLookup;
