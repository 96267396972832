import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  TablePagination,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation } from "react-router-dom";

function ClientiOperatori() {
  const location = useLocation();
  const general = useSelector((state) => state.general);
  const [clienti, setClienti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Gestione della paginazione per la tabella dei clienti
  const [pageClients, setPageClients] = useState(0);
  const [rowsPerPageClients, setRowsPerPageClients] = useState(5);

  const handleChangePageClients = (event, newPage) => {
    setPageClients(newPage);
  };

  const handleChangeRowsPerPageClients = (event) => {
    setRowsPerPageClients(parseInt(event.target.value, 10));
    setPageClients(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;
        const response = await axios.get(
          `${endpoint}api/auth/getAgencyData/${general.agencyId}`,
          { headers }
        );

        // Estrai tutti i clienti dagli operatori con il nome e il cognome dell'operatore collegato
        const allClienti = response.data.operators.reduce((acc, operator) => {
          operator.clienti.forEach((cliente) => {
            acc.push({
              ...cliente,
              operatorName: `${operator.name} ${operator.cognome}`,
            });
          });
          return acc;
        }, []);

        setClienti(allClienti);
        setError(null);
      } catch (error) {
        console.error("Failed to fetch data users", error.response);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [general.agencyId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ marginBottom: 1 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <PhotoCameraIcon />
              </Avatar>
            }
            title="Clienti"
            titleTypographyProps={{ variant: "h6" }}
          />
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Cognome</TableCell>
                    {/* <TableCell>Email</TableCell> */}
                    <TableCell>Operatore</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clienti && clienti.length > 0 ? (
                    clienti
                      .slice(
                        pageClients * rowsPerPageClients,
                        pageClients * rowsPerPageClients + rowsPerPageClients
                      )
                      .map((cliente) => (
                        <TableRow key={cliente.id}>
                          <TableCell>{cliente.name}</TableCell>
                          <TableCell>{cliente.cognome}</TableCell>
                          {/*  <TableCell>{cliente.email}</TableCell> */}
                          <TableCell>{cliente.operatorName}</TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        Nessun cliente disponibile
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={clienti ? clienti.length : 0}
              rowsPerPage={rowsPerPageClients}
              page={pageClients}
              onPageChange={handleChangePageClients}
              onRowsPerPageChange={handleChangeRowsPerPageClients}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ClientiOperatori;
