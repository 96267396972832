import React from "react";
import { Card, CardContent, Typography, CardHeader, Avatar, Skeleton } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Icona per l'account

export default function AccountProprietario({ account }) {
  if (!account) {
    return (
      <></>
    );
  }

  return (
    <Card sx={{ marginBottom: 1 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <AccountCircleIcon />
          </Avatar >
        }
        title="Operatore Referente"
        titleTypographyProps={{ variant: "h6" }}

      />
      <CardContent sx={{ paddingTop: 0 }}>
        {account.name ? (
          <Typography variant="body2"><b>Account:</b> {account.name}</Typography>
        ) : (
          <Skeleton variant="text" width={200} />
        )}
        {account.email ? (
          <Typography variant="body2"><b>Email:</b> {account.email}</Typography>
        ) : (
          <Skeleton variant="text" width={200} />
        )}
      </CardContent>
    </Card>
  );
}