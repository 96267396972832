import React, { useState, useEffect, useMemo, Suspense } from "react";
import { itIT, DataGridPro } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";

import axios from "axios";

import { Row, Col } from "reactstrap";
import useMounted from "react-use-mounted";

import { CircularProgress } from "@mui/material";

export const NewList = () => {
  const [state, setState] = useState([]); // Inizializza come array vuoto

  const currentFormType = "transazione";

  const [rowsData, setFormFieldsRows] = useState([]);

  const [columnsData, setColumnsData] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [visibilityFields, setVisibilityFields] = useState({});

  const [localLoader, setLocalLoader] = useState(false);
  const isMounted = useMounted();

  async function getJsonField() {
    try {
      setLocalLoader(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getFieldsForObjectTable/${currentFormType}` +
          "?owner=me",
        { headers: headers }
      );

      if (Array.isArray(response.data)) {
        setState(response.data);
      } else {
        // Gestisci il caso in cui i dati non sono un array
        console.error("Expected an array of data");
        return;
      }

      if (response.data === "No data available") {
        setFormFieldsRows([]);
        return;
      }

      if (Array.isArray(response.data)) {
        const formFieldColumn = [];
        const fieldNamesSet = new Set();

        response.data.forEach((field) => {
          const fieldName = field.fieldName;
          const fieldLabel = field.label;
          const columnVisibility = field.showInTable === 1;

          visibilityFields[fieldName] = columnVisibility;

          if (!fieldNamesSet.has(fieldName)) {
            fieldNamesSet.add(fieldName);
            formFieldColumn.push({
              field: fieldName,
              headerName: fieldLabel,
              width: 200,
              hide: !columnVisibility,
              renderCell: (row) => (
                <>
                  <Row>
                    <Col>
                      {row.row[fieldName] ? <>{row.row[fieldName]}</> : "--"}
                    </Col>
                  </Row>
                </>
              ),
            });
          }
        });

        const responseRecords = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT +
            `api/auth/getRecords/transazione?owner=me`,
          { headers: headers }
        );
        console.log("formFieldColumn", responseRecords);
        setColumnsData(formFieldColumn);
        setFormFieldsRows(responseRecords.data);
        setVisibilityFields(visibilityFields);
      } else {
        throw new Error("Formato di risposta non valido");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLocalLoader(false);
    }
  }

  useEffect(() => {
    getJsonField();
  }, [currentFormType, isMounted]);

  const getApprovalColumn = () => {
    if (currentFormType !== "immobile") {
      return null;
    }
  };

  const columnsWithEditIcon = useMemo(
    () =>
      [
        ...columnsData,
        {
          field: "status",
          headerName: "Status",
          width: 150,
        },
        getApprovalColumn(),
      ].filter(Boolean),
    [columnsData, currentFormType]
  );

  return (
    <>
      <Suspense
        fallback={
          <div style={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress className="cirle" />
          </div>
        }>
        {" "}
        {/* Controlla sia localLoader che rowsData */}
        {localLoader ? (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}>
            <Typography style={{ marginBottom: "1rem" }} variant="body1">
              Caricamento...
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ height: "50vh", width: "auto" }}>
            {/* Contenuto della tabella */}
            <DataGridPro
              rows={rowsData}
              columns={columnsWithEditIcon}
              getRowId={(row) => row.id}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              rowHeight={40}
              disableDensitySelector
            />
          </div>
        )}
      </Suspense>
    </>
  );
};

export default NewList;
