import { React, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { thunks } from "../thunks/general";

export default function OptPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpCode, setOtpCode] = useState("");
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const generateAndSendOtp = async () => {
      try {
        const token = localStorage.getItem("token");
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;

        const response = await axios.post(
          `${endpoint}api/auth/otp/generateAndSendOtp`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const userResponse = await axios.get(`${endpoint}api/auth/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserEmail(userResponse.data.email);
        // Gestisci qui la risposta di successo
        alert("OTP inviato con successo!");
      } catch (error) {
        console.error("Si è verificato un errore:", error.response);
        // Gestisci qui gli errori
        alert("Errore nell'invio dell'OTP.");
      }
    };

    generateAndSendOtp();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      const response = await axios.post(
        `${endpoint}api/auth/otp/validate`,
        { token: otpCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // Gestisci la risposta positiva
      console.log(response.data.status);
      if (response.data.status == true) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              process.env.REACT_APP_APP_ENDPOINT + `api/auth/user`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const result = response.data;
            dispatch(thunks.setStatus(result.status));
            dispatch(thunks.setUsername(result.name));
            dispatch(thunks.setUsername(result.email));
            dispatch(thunks.setUserId(result.id));
            dispatch(thunks.setRole(result.temporaryRole));
            dispatch(thunks.setAgency(result.agency_id));
            dispatch(thunks.setTokens(result.availableTokens));
            navigate("/dashboard");
          } catch (error) {
            console.error("Error fetching status:", error);
          }
        };
        fetchData();
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Errore durante la validazione dell'OTP:", error);
      setMessage("Errore nella validazione dell'OTP.");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", p: 5 }}>
      <img src="/favicon.png" alt="" width={80} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <img src="/mail-bidding.png" alt="" width={250} />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary" mt={2}>
            Abbiamo inviato un codice <b>OTP</b> alla seguente email{" "}
            <b>{userEmail}</b>
          </Typography>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="otpCode"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            label="Inseirisci qui il tuo codcie OTP"
            name="otpCode"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "#6CC4B4",
              "&:hover": { background: "#01685D", color: "#fff" },
            }}
          >
            Verifica
          </Button>

          <Typography variant="body2" color="text.secondary" mt={2}>
            Non hai ricevuto il tuo codice <b>OTP</b> <Link>Rinvia Email</Link>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      ></Box>
    </Container>
  );
}
