import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  TablePagination,
  Grid,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation } from "react-router-dom";

function GetDataAgency() {
  const location = useLocation();
  const currentFormType = location.pathname.split("/")[4];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Stato per gestire gli errori

  // Gestione della paginazione per la tabella degli operatori
  const [pageOperators, setPageOperators] = useState(0);
  const [rowsPerPageOperators, setRowsPerPageOperators] = useState(5);

  // Gestione della paginazione per la tabella dei clienti
  const [pageClients, setPageClients] = useState(0);
  const [rowsPerPageClients, setRowsPerPageClients] = useState(5);

  // Gestione della paginazione per la tabella delle transazioni
  const [pageTransactions, setPageTransactions] = useState(0);
  const [rowsPerPageTransactions, setRowsPerPageTransactions] = useState(5);

  const handleChangePageOperators = (event, newPage) => {
    setPageOperators(newPage);
  };

  const handleChangeRowsPerPageOperators = (event) => {
    setRowsPerPageOperators(parseInt(event.target.value, 10));
    setPageOperators(0);
  };

  const handleChangePageClients = (event, newPage) => {
    setPageClients(newPage);
  };

  const handleChangeRowsPerPageClients = (event) => {
    setRowsPerPageClients(parseInt(event.target.value, 10));
    setPageClients(0);
  };

  const handleChangePageTransactions = (event, newPage) => {
    setPageTransactions(newPage);
  };

  const handleChangeRowsPerPageTransactions = (event) => {
    setRowsPerPageTransactions(parseInt(event.target.value, 10));
    setPageTransactions(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;
        const response = await axios.get(
          `${endpoint}api/auth/getAgencyData/${currentFormType}`,
          { headers }
        );

        setData(response.data);
        setError(null); // Resetta lo stato degli errori se i dati sono stati ottenuti con successo
      } catch (error) {
        console.error("Failed to fetch data users", error.response);
        setError("Failed to fetch data"); // Imposta lo stato degli errori se si verificano errori durante il recupero dei dati
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentFormType]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>; // Mostra un messaggio di errore se si verifica un errore durante il recupero dei dati
  }

  return (
    <>
      <Grid container spacing={2}>
        {/* Proprietario */}

        {/* Tabella degli operatori */}
        <Grid item xs={12}>
          <Card sx={{ marginBottom: 1 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "primary.main" }}>
                  <PhotoCameraIcon />
                </Avatar>
              }
              title="Operatori"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ border: 0, boxShadow: "none" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.operators && data.operators.length > 0 ? (
                      data.operators
                        .slice(
                          pageOperators * rowsPerPageOperators,
                          pageOperators * rowsPerPageOperators +
                            rowsPerPageOperators
                        )
                        .map((operator) => (
                          <TableRow key={operator.id}>
                            <TableCell>{operator.name}</TableCell>
                            <TableCell>{operator.email}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.operators ? data.operators.length : 0}
                rowsPerPage={rowsPerPageOperators}
                page={pageOperators}
                onPageChange={handleChangePageOperators}
                onRowsPerPageChange={handleChangeRowsPerPageOperators}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Tabella dei clienti */}
        <Grid item xs={12}>
          <Card sx={{ marginBottom: 1 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "primary.main" }}>
                  <PhotoCameraIcon />
                </Avatar>
              }
              title="Clienti"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Cognome</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.operators && data.operators.length > 0 ? (
                      data.operators
                        .slice(
                          pageClients * rowsPerPageClients,
                          pageClients * rowsPerPageClients + rowsPerPageClients
                        )
                        .map((operator) =>
                          operator.clienti && operator.clienti.length > 0 ? (
                            operator.clienti
                              .slice(0, rowsPerPageClients - 1)
                              .map((cliente) => (
                                <TableRow key={cliente.id}>
                                  <TableCell>{operator.name}</TableCell>
                                  <TableCell>{cliente.name}</TableCell>
                                  <TableCell>{cliente.email}</TableCell>
                                </TableRow>
                              ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                No data available
                              </TableCell>
                            </TableRow>
                          )
                        )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  data.operators
                    ? data.operators.reduce(
                        (acc, curr) =>
                          acc + (curr.clienti ? curr.clienti.length : 0),
                        0
                      )
                    : 0
                }
                rowsPerPage={rowsPerPageClients}
                page={pageClients}
                onPageChange={handleChangePageClients}
                onRowsPerPageChange={handleChangeRowsPerPageClients}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Tabella delle transazioni */}
        <Grid item xs={12}>
          <Card sx={{ marginBottom: 1 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "primary.main" }}>
                  <PhotoCameraIcon />
                </Avatar>
              }
              title="Ultime Transazioni"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* Genera le intestazioni delle colonne. Assumendo che tutti gli oggetti transazione abbiano lo stesso schema di campi */}
                      {data.transazioni &&
                        data.transazioni.length > 0 &&
                        data.transazioni[0].related_values.map(
                          (relatedValue, index) => (
                            <TableCell key={index}>
                              {relatedValue.custom_field.label}
                            </TableCell>
                          )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.transazioni && data.transazioni.length > 0 ? (
                      data.transazioni
                        .slice(
                          pageTransactions * rowsPerPageTransactions,
                          pageTransactions * rowsPerPageTransactions +
                            rowsPerPageTransactions
                        )
                        .map((transazione) => (
                          <TableRow key={transazione.id}>
                            {transazione.related_values.map(
                              (relatedValue, index) => (
                                <TableCell key={index}>
                                  {relatedValue.value}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={1}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.transazioni ? data.transazioni.length : 0}
                rowsPerPage={rowsPerPageTransactions}
                page={pageTransactions}
                onPageChange={handleChangePageTransactions}
                onRowsPerPageChange={handleChangeRowsPerPageTransactions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default GetDataAgency;
