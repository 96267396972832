import React from "react";

const SuperficieIcon = ({
  fillColor = "#E1251B",
  width = "22",
  height = "21",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9401 0C19.9904 0.0657954 20.0702 0.0394772 20.1353 0.0582759C20.7494 0.238273 21.2115 0.808343 21.2143 1.44797C21.2229 3.46319 21.2205 5.47793 21.2167 7.49315C21.2158 7.92646 20.8714 8.25403 20.4497 8.24839C20.0294 8.24275 19.7055 7.91142 19.7045 7.47106C19.7017 5.94038 19.7031 4.40923 19.7031 2.87855C19.7031 2.79301 19.7031 2.70748 19.7031 2.60315C19.6048 2.63181 19.5597 2.70748 19.5032 2.76294C17.7497 4.49617 15.9968 6.22941 14.2467 7.96547C14.0107 8.19951 13.7475 8.31888 13.416 8.21502C12.895 8.05194 12.7136 7.43676 13.0622 7.02131C13.1126 6.96162 13.171 6.90804 13.2265 6.85306C14.9562 5.14097 16.6864 3.42888 18.4165 1.71726C18.4769 1.65757 18.5414 1.60165 18.6516 1.49966C18.5115 1.49966 18.4298 1.49966 18.3477 1.49966C16.7928 1.49966 15.2383 1.5006 13.6834 1.49872C13.3101 1.49872 13.0318 1.30416 12.9231 0.980351C12.82 0.673463 12.9254 0.337906 13.1914 0.14804C13.2721 0.0902336 13.3643 0.0488766 13.4512 0C15.614 0 17.7768 0 19.9396 0L19.9401 0Z"
        fill={fillColor}
      />
      <path
        d="M0.000474926 13.3116C0.249336 12.8534 0.549015 12.6767 0.924206 12.7669C1.33217 12.8652 1.51596 13.1565 1.51596 13.705C1.51596 15.1892 1.51596 16.6733 1.51596 18.1575V18.4282C1.6005 18.3502 1.66177 18.2975 1.71876 18.2407C3.47171 16.5065 5.22466 14.7728 6.97524 13.0367C7.20368 12.8102 7.45776 12.6894 7.78404 12.7848C8.31976 12.9413 8.51115 13.5635 8.15306 13.9865C8.09749 14.0523 8.03385 14.1111 7.97258 14.1721C6.24148 15.8838 4.51037 17.5949 2.77879 19.3061C2.7256 19.3587 2.66766 19.4066 2.5646 19.4988H2.86095C4.41586 19.4988 5.97077 19.4969 7.52568 19.4997C8.10414 19.5006 8.48456 20.0077 8.28414 20.512C8.1635 20.8156 7.92699 20.9947 7.59502 20.9956C5.54286 21.0003 3.49071 21.0041 1.43855 20.9928C0.795501 20.989 0.250286 20.536 0.0541416 19.902C0.0408436 19.8592 0.0527168 19.8061 0 19.7803V13.3121L0.000474926 13.3116Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SuperficieIcon;
