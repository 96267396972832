import React, { useState, useEffect } from "react";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { CheckCircle } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TableHead,
  Table,
  TableBody,
  Modal,
  TablePagination,
  Divider,
  TextField,
  TableRow,
  TableCell,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MapComponent from "components/Mappa";
import LightBoxSlider from "components/LightBoxSlider";
import SuperficieIcon from "components/icons/Supercie";
import LocaliIcon from "components/icons/Locali";
import BagniIcon from "components/icons/Bagni";
import RilanciTotali from "components/icons/RilanciTotali";
import TempoScadenza from "components/icons/TempoScadenza";
import UltimaOfferta from "./UltimaOfferta";
import RilancioMinimo from "./RilancioMinimo";
import { useSelector } from "react-redux";
import SliderImage from "components/SliderImage";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function ShowAstaPrivate({ values }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState(values || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bidAmount, setBidAmount] = useState();
  const location = useLocation();
  const [astaData, setAstaData] = useState();

  const [loading, setLoading] = useState(false);
  const [rilancioData, setRilancioData] = useState(null);
  const currentRecordId = location.pathname.split("/")[4];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [errorState, setErrorState] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [page, setPage] = useState(0); // La paginazione inizia da 0
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const general = useSelector((state) => state.general);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #0066FF",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Ritorna alla prima pagina con il nuovo numero di righe per pagina
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const fetchAstaData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;
      const response = await axios.get(
        `${endpoint}api/auth/getRecord/immobile/${currentRecordId}`,
        { headers }
      );

      setAstaData(response.data);
      setTargetDateTime(astaData.deadline);
      // You might want to update some states based on the received data
    } catch (error) {
      console.error("Failed to fetch rilancio data", error);
      // Handle error (e.g., set error message state)
    } finally {
      setLoading(false);
    }
  };
  const fetchRilancioData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;
      const response = await axios.get(
        `${endpoint}api/getRilancioData/${currentRecordId}`,
        { headers }
      );

      setRilancioData(response.data);
      setBidAmount(0);
      // You might want to update some states based on the received data
    } catch (error) {
      console.error("Failed to fetch rilancio data", error);
      // Handle error (e.g., set error message state)
    } finally {
      setLoading(false);
    }
  };
  // Fetch rilancio data when modal opens
  useEffect(() => {
    fetchRilancioData();
    if (isModalOpen) {
      fetchRilancioData();
    }

    fetchAstaData();
  }, [isModalOpen, currentRecordId]);

  const handleInviaOfferta = async () => {
    const bidData = {
      myTotalBid: astaData?.actualOffer,
      myBid: bidAmount,
    };
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const endpoint = process.env.REACT_APP_APP_ENDPOINT;

    try {
      const response = await axios.post(
        `${endpoint}api/auth/sendBid/${currentRecordId}`,
        bidData,
        { headers }
      );
      toast.success("Offerta inviata con successo!");
      setBidAmount(0);
      setErrorState(false);

      console.log("Bid inviato con successo:", response.data);
      handleCloseModal();
      setShowSuccessModal(true);
    } catch (error) {
      // Controlla se l'errore proviene dall'API e ha un corpo di risposta
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
        toast.error(error.response.data.error);
        // Imposta lo stato di errore per mostrare il messaggio nella modale
        setErrorState(error.response.data.error);
      } else {
        // Gestisci altri tipi di errori (ad esempio errori di rete)
        console.error("Failed to send bid", error);
        setErrorMessage("Errore nell'invio dell'offerta. Riprova.");
        toast.error("Errore nell'invio dell'offerta. Riprova.");
        // Imposta lo stato di errore per mostrare il messaggio nella modale
        setErrorState("Errore nell'invio dell'offerta. Riprova.");
      }
    }

    fetchAstaData();
    fetchRilancioData();
  };

  const goBack = () => {
    navigate(-1);
  };
  const [timeLeft, setTimeLeft] = useState("");

  const [targetDateTime, setTargetDateTime] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const distance = new Date(targetDateTime) - now;

      if (distance < 0) {
        clearInterval(timer);

        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft(
        `${days} giorni ${hours} ore ${minutes} minuti ${seconds} secondi`
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDateTime]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBidChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setBidAmount(value);
      setErrorMessage();
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ marginTop: 0, boxShadow: "1px 1px 13px #ececec" }}
      >
        <Grid item xs={12} md={8}>
          {/* <LightBoxSlider images={[astaData?.firstImage] || []} /> */}
          <SliderImage images={[astaData?.iamges] || []} />
        </Grid>
        <Grid item md={4}>
          <MapComponent
            coordinates={{
              lat: parseFloat(astaData?.latitude),
              lng: parseFloat(astaData?.longitude),
            }}
          />
        </Grid>
      </Grid>
      <Container maxWidth>
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
          <Grid item xs={12} md={8}>
            <CardContent>
              <Typography variant="h5" component="p" sx={{ marginBottom: 0 }}>
                {astaData?.actualOffer.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Typography>

              <Typography
                component="b"
                sx={{
                  fontFamily: "Inter SemiBold",
                  display: "inline",
                }}
              >
                {astaData &&
                  astaData?.title &&
                  astaData.houseType + " in " + astaData.title}
              </Typography>

              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={0} sx={{ marginTop: 2 }}>
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <IconButton color="inherit" aria-label="home">
                            <SuperficieIcon
                              fillColor="#FF0000"
                              width="20"
                              height="20"
                            />
                          </IconButton>
                          {astaData && astaData?.mq} mq
                        </CardContent>
                      </Grid>

                      {/* Box for rooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <IconButton color="inherit" aria-label="home">
                            <LocaliIcon
                              fillColor="#FF0000"
                              width="20"
                              height="20"
                            />
                          </IconButton>
                          {astaData && astaData?.roomQty} Locali
                        </CardContent>
                      </Grid>

                      {/* Box for bathrooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <IconButton color="inherit" aria-label="home">
                            <BagniIcon
                              fillColor="#FF0000"
                              width="20"
                              height="20"
                            />
                          </IconButton>
                          {astaData && astaData?.wcQty} Bagno
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={0} sx={{ marginTop: 2 }}>
                      <Grid item xs={12} md={12}>
                        <Button
                          variant="contained"
                          sx={{
                            background: "rgba(102, 201, 186, 0.7)",
                            boxShadow: "none",
                            color: "#01685D",
                            borderRadius: "20px",
                            border: "solid transparent",
                            marginRight: 2,
                            "&:hover": {
                              background: "#66C9BA",
                              border: "solid #01685D",
                            },
                          }}
                        >
                          SALVA
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            background: "rgba(102, 201, 186, 0.7)",
                            boxShadow: "none",
                            color: "#01685D",
                            borderRadius: "20px",
                            "&:hover": {
                              background: "#66C9BA",
                              border: "solid #01685D",
                            },
                          }}
                        >
                          CONDIVIDI
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Description */}
              <Box>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          mt: 2,
                          fontFamily: "Inter",
                        }}
                      >
                        {astaData && astaData?.shortDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#01685D",
                          mt: 3,
                          mb: 1,
                          fontFamily: "Inter",
                        }}
                      >
                        <b>INFORMAZIONI GENERALI</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Prima Riga */}
              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Tipologia</Typography>
                          <Typography>
                            <b> {astaData && astaData?.type}</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for rooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Superficie</Typography>
                          <Typography>
                            <b>{astaData && astaData?.mq} mq</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for bathrooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Locali</Typography>
                          <Typography>
                            <b>{astaData && astaData?.roomQty}</b>
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Seconda Riga */}
              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Bagni</Typography>
                          <Typography>
                            <b>{astaData && astaData?.wcQty}</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for rooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Terrazzo</Typography>
                          <Typography>
                            <b>N/A</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for bathrooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Piano</Typography>
                          <Typography>
                            <b>N/A</b>
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Terza Riga */}
              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Stato</Typography>
                          <Typography>
                            <b>{astaData && astaData?.status}</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for rooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Ascensore</Typography>
                          <Typography>
                            <b>N/A</b>
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "#01685D",
                          mt: 3,
                          mb: 1,
                          fontFamily: "Inter",
                        }}
                      >
                        <b>SPESE E CATASTO</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Prima Riga */}
              <Box sx={{ display: "flex" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Contratto</Typography>
                          <Typography>
                            <b>Vendita all’asta (N/A)</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for rooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Prezzo</Typography>
                          <Typography>
                            <b>€ 150.000,00</b>
                          </Typography>
                        </CardContent>
                      </Grid>

                      {/* Box for bathrooms */}
                      <Grid item xs={4}>
                        <CardContent sx={{ padding: "0!important" }}>
                          <Typography>Prezzo mq</Typography>
                          <Typography>
                            <b>€ 1.689/mq (N/A)</b>
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Fine colonnad a 8 */}
            </CardContent>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                borderRadius: 4,
                p: 1,
                border: "none",
                boxShadow: "rgba(0, 0, 0, 0.15)",
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    color: "#01685D",
                    mb: 1,
                    fontFamily: "Inter",
                  }}
                >
                  <b>DETTAGLI ASTA</b>
                </Typography>
                <Grid container spacing={0} sx={{ marginTop: 0 }}>
                  {/* Total Bids */}
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    sx={{ py: 1.5 }}
                    alignItems="center"
                  >
                    <Grid xs={1}>
                      <RilanciTotali
                        fillColor="#FF0000"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    <Grid xs={10}>
                      N. {astaData?.qtyTotalBidsAmount} Rilanci Totali
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />

                  {/* Time Left */}
                  <Grid
                    container
                    xs={12}
                    spacing={0}
                    alignItems="center"
                    sx={{ py: 1.5 }}
                  >
                    <Grid xs={1}>
                      <TempoScadenza
                        fillColor="#FF0000"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    <Grid xs={10}>
                      Tempo alla scadenza: <b>{timeLeft}</b>
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />

                  {/* High Bid */}
                  <Grid
                    container
                    xs={12}
                    spacing={0}
                    alignItems="center"
                    sx={{ py: 1.5 }}
                  >
                    <Grid xs={1}>
                      <UltimaOfferta
                        fillColor="#FF0000"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    <Grid xs={10}>
                      Ultima Offerta:{" "}
                      {astaData?.actualOffer.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />

                  {/* Rilancio minimo */}
                  <Grid
                    container
                    xs={12}
                    spacing={0}
                    alignItems="center"
                    sx={{ py: 1.5 }}
                  >
                    <Grid xs={1}>
                      <RilancioMinimo
                        fillColor="#FF0000"
                        width="20"
                        height="20"
                      />
                    </Grid>
                    <Grid xs={10}>
                      Rilancio minimo: € {rilancioData?.rilancio_corrente}
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    marginTop: 2,
                    width: "100%",
                    background: "#66C9BA",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                    color: "#01685D",
                    borderRadius: "20px",
                    "&:hover": {
                      background: "#66C9BA",
                      border: "solid #01685D",
                    },
                  }}
                  onClick={handleOpenModal}
                >
                  <b>FAI UN RILANCIO</b>
                </Button>
              </CardContent>

              {/* Rilancio Asta Button */}
            </Card>

            <Card
              sx={{
                mt: 3,
                borderRadius: 4,
                p: 1,
                border: "none",
                boxShadow: "rgba(0, 0, 0, 0.15)",
              }}
            >
              <CardContent sx={{ p: 0 }}>
                <Typography
                  sx={{
                    color: "#01685D",
                    mb: 1,
                    fontFamily: "Inter",
                    px: "16px",
                    pt: "16px",
                  }}
                >
                  <b>STORICO RILANCI</b>
                </Typography>
                {astaData?.customRelations?.MasterDetail?.Rilanci
                  ?.relatedRecords &&
                astaData.customRelations.MasterDetail.Rilanci.relatedRecords
                  .length > 0 ? (
                  <>
                    <Table aria-label="Cronologia Rilanci">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ py: 0 }}>Data</TableCell>
                          <TableCell sx={{ py: 0 }}>Utente</TableCell>
                          <TableCell sx={{ py: 0 }}>Importo</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {astaData.customRelations.MasterDetail.Rilanci.relatedRecords
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((rilancio) => (
                            <TableRow key={rilancio.id}>
                              <TableCell
                                sx={{ paddingTop: 1, paddingBottom: 1 }}
                              >
                                {rilancio.relatedValues["Creato il"]}
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 1, paddingBottom: 1 }}
                              >
                                {rilancio.relatedValues.Proprietario}
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 1, paddingBottom: 1 }}
                              >
                                {new Intl.NumberFormat("it-IT", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(
                                  rilancio.relatedValues["Offerta Complessiva"]
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ paddingTop: 1, paddingBottom: 1 }}
                              >
                                {new Intl.NumberFormat("it-IT", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(rilancio.relatedValues.Importo)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]} // Numero di opzioni per 'righe per pagina'
                      component="div"
                      count={
                        astaData.customRelations.MasterDetail.Rilanci
                          .relatedRecords.length
                      } // Numero totale di righe
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>Nessun dato disponibile</>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Modal for Rilancio Asta */}
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{ width: 600, margin: "auto" }}
          fullWidth
        >
          <DialogContent sx={{ paddingInline: 0 }}>
            <DialogTitle
              sx={{
                position: "relative",
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <b>Effettua un rilancio</b>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                sx={{
                  position: "absolute",
                  right: 20,
                  top: 0,
                  padding: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Container>
              {errorState && (
                <Typography color="error" sx={{ marginTop: 2 }}>
                  {errorState}
                </Typography>
              )}
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    sx={{ mt: 1, fontSize: "16px", fontFamily: "Inter" }}
                  >
                    BID DISPONIBILI: <b> {general.tokens} </b>
                    <Typography
                      sx={{ mt: 0, fontSize: "12px", fontFamily: "Inter" }}
                    >
                      COSTO OFFERTA: <b> 1 BID </b>
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{ mt: 1, color: "#01685D", fontFamily: "Inter" }}
                  >
                    <b>UTENTE: {general.username}</b>
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="body1" sx={{ paddingTop: 2 }}>
                      <strong>Rilancia di:</strong>
                    </Typography>
                    <TextField
                      type="number"
                      defaultValue={bidAmount}
                      onChange={handleBidChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      sx={{ borderRadius: 2, borderColor: "#ccc" }}
                    />

                    <Typography
                      variant="body1"
                      sx={{ paddingTop: 2, fontSize: "12px" }}
                    >
                      Rilancio minimo di: {rilancioData?.rilancio_corrente}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ textAlign: "center", paddingTop: 0 }}>
                      <Typography variant="body1">
                        <b>La Tua Offerta:</b>
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "24px",
                          marginTop: 1,
                          color:
                            parseInt(astaData?.actualOffer) +
                              parseInt(bidAmount) >=
                            parseInt(astaData?.actualOffer) +
                              parseInt(rilancioData?.rilancio_corrente)
                              ? "green"
                              : "red",
                        }}
                      >
                        {(
                          parseInt(astaData?.actualOffer) + parseInt(bidAmount)
                        ).toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                      <hr />
                      <Typography variant="body1">Offerta Minima</Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "24px",
                          marginTop: 0,
                        }}
                      >
                        {(
                          parseInt(astaData?.actualOffer) +
                          parseInt(rilancioData?.rilancio_corrente)
                        ).toLocaleString("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Stack direction="row" spacing={2} mt={2}>
                  <Button
                    variant="contained"
                    onClick={handleInviaOfferta}
                    sx={{
                      background: "#66C9BA",
                      boxShadow: "rgba(0, 0, 0, 0.25)",
                      color: "#01685D",
                      borderRadius: "20px",
                    }}
                  >
                    Invia Offerta
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCloseModal}
                    sx={{
                      background: "#66C9BA",
                      boxShadow: "rgba(0, 0, 0, 0.25)",
                      color: "#01685D",
                      borderRadius: "20px",
                    }}
                  >
                    Annulla
                  </Button>
                </Stack>
              </Box>
            </Container>
          </DialogContent>
        </Dialog>
      </Container>

      <Modal
        open={showSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "absolute",
              width: 100, // Imposta la larghezza del Box
              height: 100, // Imposta l'altezza del Box
              borderRadius: "50%", // Rendi il Box circolare
              backgroundColor: "white", // Imposta il colore di sfondo bianco
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              left: "38%",
              bottom: "80%",
            }}
          >
            <CheckCircle
              sx={{
                fontSize: 150,
                color: "#4caf50",
              }}
            />
          </Box>
          <Typography
            sx={{ paddingTop: "5rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Success
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La Tua Offerta è stata inviata con successo!
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
