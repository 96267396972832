import React, { useState, useEffect, useMemo } from "react";
import {
  itIT,
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import DynamicWizard from "../../components/dynamic-wizard";

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Row, Col } from "reactstrap";
import useMounted from "react-use-mounted";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BuildIcon from "@mui/icons-material/Build";
import { useSelector } from "store";
import CloseIcon from "@mui/icons-material/Close";

export const MyCustomers = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const general = useSelector((state) => state.general);
  const [state, setState] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [rowsData, setFormFieldsRows] = useState([]);
  const [formFieldsColumn, setFormFieldsColumn] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [columnsData, setColumnsData] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [visibilityFields, setVisibilityFields] = useState({});
  const [error, setError] = useState(null);

  const [localLoader, setLocalLoader] = useState(false);
  const isMounted = useMounted();

  const handleOpenDialog = (rowId, actionType) => {
    if (actionType === "delete") {
      setDeleteConfirmDialog({ open: true, rowId });
    } else {
      setConfirmDialog({ open: true, rowId });
    }
  };
  const formType = location.pathname.split("/")[2];

  async function getJsonField() {
    try {
      setLocalLoader(true);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const response = await axios.get(
        process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getFieldsForObjectTable/User`,
        { headers: headers }
      );

      if (Array.isArray(response.data)) {
        setState(response.data);
      } else {
        // Gestisci il caso in cui i dati non sono un array
        console.error("Expected an array of data");
        return;
      }

      if (response.data === "No data available") {
        setFormFieldsRows([]);
        return;
      }

      if (Array.isArray(response.data)) {
        const formFieldColumn = [];
        const fieldNamesSet = new Set();

        response.data.forEach((field) => {
          const fieldName = field.fieldName;
          const fieldLabel = field.label;
          const columnVisibility = field.showInTable === 1;

          visibilityFields[fieldName] = columnVisibility;

          if (!fieldNamesSet.has(fieldName)) {
            fieldNamesSet.add(fieldName);
            formFieldColumn.push({
              field: fieldName,
              headerName: fieldLabel,
              width: 200,
              hide: !columnVisibility,
              renderCell: (row) => (
                <>
                  <Row>
                    <Col>
                      {row.row[fieldName] ? (
                        <>
                          {fieldName === "title" || fieldName === "name" ? (
                            <Link to={"/adminList/User/show/" + row.id}>
                              {row.row[fieldName]}
                            </Link>
                          ) : (
                            row.row[fieldName]
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </Col>
                  </Row>
                </>
              ),
            });
          }
        });

        const responseRecords = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT +
            `api/auth/subordinates/` +
            general.userId +
            "?includeAll=true",
          { headers: headers }
        );
        //console.log("formFieldColumn", responseRecords);
        setFormFieldsColumn(formFieldColumn);
        setColumnsData(formFieldColumn);
        setFormFieldsRows(responseRecords.data);
        setVisibilityFields(visibilityFields);
      } else {
        throw new Error("Formato di risposta non valido");
      }
    } catch (e) {
      setError(e.message);
      console.error(e);
    } finally {
      setLocalLoader(false);
    }
  }

  useEffect(() => {
    getJsonField();
  }, [isMounted]);

  const ToolbarButtonStyle = {
    background: "#66C9BA",
    border: "solid",
    color: "#fff",
    padding: "5px 15px",
    lineHeight: "20px",
    fontSize: "14px",
    borderRadius: "5px",
    "&:hover": {
      background: "#fff",
      color: "#66C9BA",
      border: "solid",
    },
  };

  const renderActionButtons = () => {
    const isAnyRowSelected = checkboxSelected !== 0;

    return (
      <>
        <Button
          color="success"
          onClick={handleOpenModal}
          sx={ToolbarButtonStyle}
        >
          Nuovo Giroconto BIDS
        </Button>
      </>
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsModalOpen(false);
    setConfirmDialog({ open: false, rowId: null, newState: null });
    setDeleteConfirmDialog({ open: false, rowId: null, newState: null });
  };

  const handleSelectionChange = (newSelection) => {
    setCheckboxSelected(newSelection);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  function StatusCell(props) {
    const { status } = props;

    let color = "default";
    let icon = null;
    let label = "Stato";

    switch (status) {
      case "Approvato":
        color = "green";
        icon = <CheckCircleIcon />;
        label = "Approvato";
        break;
      case "Rifiutato":
        color = "red";
        icon = <CancelIcon />;
        label = "Rifiutato";
        break;
      case "Richiesta Modifica":
        color = "orange";
        icon = <BuildIcon />;
        label = "Modifica";
        break;
      case "Nuovo":
        color = "darkblue";
        icon = <BuildIcon />;
        label = "Nuovo";
        break;
      default:
        label = status;
    }

    return (
      <Button
        startIcon={icon}
        label="status"
        disabled
        variant="contained"
        style={{
          backgroundColor: color,
          color: "white",
          cursor: "default",
          padding: "3px 10px",
          textTransform: "none",
        }}
      >
        {label}
      </Button>
    );
  }

  function StatusCellRenderer(params) {
    return <StatusCell status={params.row.status} rowId={params.row.id} />;
  }

  const columnsWithEditIcon = useMemo(
    () =>
      [
        ...columnsData.map((column) => {
          if (column.field === "status") {
            return {
              ...column,
              renderCell: StatusCellRenderer,
            };
          }
          return column;
        }),
      ].filter(Boolean),
    [columnsData]
  );

  const CustomToolbar = () => {
    const ToolbarButtonStyle = {
      background: "#66C9BA",
      border: "solid",
      color: "#fff",
      padding: "5px 15px",
      lineHeight: "20px",
      fontSize: "14px",
      borderRadius: "5px",
      "&:hover": {
        background: "#fff",
        color: "#66C9BA",
        border: "solid",
      },
    };

    const renderHeading = () => {
      // Verifica che currentFormType sia una stringa valida prima di accedere a charAt

      // Se currentFormType non è una stringa valida, puoi gestirlo in modo appropriato
      return (
        <>
          <Typography variant="h5" sx={{ paddingLeft: 0 }}>
            <AccountBoxIcon
              fontSize="large"
              style={{ verticalAlign: "middle", marginRight: 15 }}
            />
            I Miei Clienti
          </Typography>
        </>
      );

      return (
        <>
          <Typography variant="h5" sx={{ paddingLeft: 0 }}>
            <AccountBoxIcon
              fontSize="large"
              style={{ verticalAlign: "middle" }}
            />{" "}
          </Typography>
        </>
      );
    };

    return (
      <GridToolbarContainer
        sx={{
          padding: "20px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Contenuto allineato a sinistra */}
        <div>{renderHeading()}</div>

        {/* Contenuto allineato a destra */}
        <div style={{ display: "flex", alignItems: "middle" }}>
          <GridToolbarDensitySelector />
        </div>
      </GridToolbarContainer>
    );
  };

  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const newRandomString = generateRandomString(10);

  const handleConfirmApprove = async () => {
    const { rowId, status } = confirmDialog;
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/editHouseStatus",
        { id: rowId, status: status },
        { headers: headers }
      );

      if (response.status === 200) {
        // Aggiorna lo stato del componente con i nuovi dati

        // Aggiorna la riga corrispondente con il nuovo stato
        const updatedRows = rowsData.map((row) => {
          if (row.id === rowId) {
            // Assumiamo che 'status' sia il campo da aggiornare
            return { ...row, status: status };
          }
          return row;
        });

        setFormFieldsRows(updatedRows);

        toast.success("Stato aggiornato con successo");
      } else {
        throw new Error("Errore nella risposta API");
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento dello stato:", error);
      toast.error("Errore nell'aggiornamento dello stato: " + error.message);
    } finally {
      setConfirmDialog({ open: false, rowId: null, status: null });
    }
  };
  const NoRowsOverlay = () => {
    console.log("Rendering NoRowsOverlay");
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Typography variant="body1">Nessun cliente trovato</Typography>
      </div>
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ margin: 3, borderRadius: "18px" }}>
          <CardContent sx={{ p: 2 }}>
            {rowsData.length === 0 ? (
              <DataGridPro
                rows={rowsData}
                columns={columnsWithEditIcon}
                getRowId={(row) => row.id}
                initialState={{ pinnedColumns: { right: ["actions"] } }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rowsPerPageOptions={[5, 10, 20]}
                pagination
                rowHeight={40}
                onRowSelectionModelChange={handleSelectionChange}
                disableRowSelectionOnClick
                disableDensitySelector
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                  width: isSmallDevice ? "100vh" : "auto",
                  maxHeight: "100vh",
                  overflowX: "auto",
                  display: "block",
                  border: "none",
                }}
                autoHeight
              />
            ) : (
              <DataGridPro
                rows={rowsData}
                columns={columnsWithEditIcon}
                getRowId={(row) => row.id}
                initialState={{ pinnedColumns: { right: ["actions"] } }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rowsPerPageOptions={[5, 10, 20]}
                pagination
                rowHeight={40}
                onRowSelectionModelChange={handleSelectionChange}
                disableRowSelectionOnClick
                disableDensitySelector
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{
                  width: isSmallDevice ? "100vh" : "auto",
                  maxHeight: "100vh",
                  overflowX: "auto",
                  display: "block",
                  border: "none",
                }}
                autoHeight
              />
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: "0px",
          },
        }}
      >
        <DialogTitle>
          <>Effettua un nuovo ordine</>
        </DialogTitle>
        <DialogContent>
          <DynamicWizard
            objectType="ordine"
            onClose={handleCloseModal}
            reloadRecords={getJsonField}
            prefilledValues={{
              data: general.userId,
              status: "Da evadere",
              title: newRandomString,
              ordinante: general.userId,
              tipologia_ordine: "Ricarica Cliente",
            }}
            hiddenFields={{
              valore_ordine: true,
              title: true,
              codice_valuta_unita_acquisto: true,
              status: true,
              ordinante: true,
              tipologia_ordine: true,
            }}
          />
        </DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  );
};

export default MyCustomers;
