import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";

function AddressRegister({ field, value, onChange, label = "Indirizzo" }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA",
    libraries: ["places"],
    language: "it",
  });

  const autocompleteInputRef = useRef(null);

  useEffect(() => {
    if (!isLoaded || !autocompleteInputRef.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteInputRef.current
    );
    autocomplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      const address = place.formatted_address;
      let city, region, country, postalCode, neighborhood, province;
      const location = place.geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();

      place.address_components.forEach((component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            region = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
          case "postal_code":
            postalCode = component.long_name;
            break;
          case "sublocality":
          case "sublocality_level_1":
            neighborhood = component.long_name;
            break;
          case "administrative_area_level_2":
            province = component.long_name;
            break;
          default:
            break;
        }
      });

      onChange(field, address);
      onChange("city", city);
      onChange("region", region);
      onChange("country", country);
      onChange("postalCode", postalCode);
      onChange("latitude", latitude.toString());
      onChange("longitude", longitude.toString());
      onChange("neighborhood", neighborhood);
      onChange("province", province);
    });
  }, [isLoaded]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <TextField
          margin="normal"
          label={label}
          variant="standard"
          sx={{ height: "50px", margin: 0 }}
          type="text"
          name="address"
          fullWidth
          placeholder="Cerca un indirizzo"
          inputRef={autocompleteInputRef}
          defaultValue={value}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(AddressRegister);
