import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import { CardHeader, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";

const StyledUl = styled("ul")({
  paddingLeft: "0.5em",
  borderLeft: "3px solid #c0d1d1",
  marginBottom: "1em",
});

const StyledLi = styled("li")({
  listStyleType: "none",
  marginBottom: "0.5em",
  marginTop: "0.5em",
});

const StyledDetails = styled("details")({
  summary: {
    position: "relative",
    cursor: "pointer",
    color: "#000",
    "&:after": {
      position: "relative",
      left: 0,
      top: "7px",
      background: "#66C9BA",
      /*  borderColor: "#da291c",
      borderRadius: "15px", */
      content: '"+"',
      color: " #fff",
      lineHeight: "16px",
      float: "left",
      fontSize: "20px",
      fontWeight: "bold",
      margin: "0 10px 0 0",
      padding: "0 0 3px 0",
      textAlign: "center",
      width: "20px",
    },
  },
  "&[open] > summary": {
    color: "#000",
  },
  "&[open] > summary:after": {
    content: '"-"',
  },
  "summary::marker": {
    content: "none",
  },

  /*  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-1em",
    top: "50%",
    transform: "translateY(-50%)",
    borderStyle: "solid",
    borderWidth: "0.25em 0.25em 0 0.25em",
    borderColor: "#2b4b82",
    transition: "transform 0.3s ease-in-out",
  },
  "&[open]::before": {
    transform: "translateY(-50%) rotate(-90deg)",
  },
  "summary::marker": {
    color: "#2b4b82",
    content: "none",
  }, */
  ul: {
    marginLeft: "2em",
  },
});

const UserHierarchyGrid = () => {
  const [hierarchicalUsers, setHierarchicalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDetails, setOpenDetails] = useState({}); // Stato per gestire quali dettagli sono aperti


  console.log(hierarchicalUsers)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const endpoint = process.env.REACT_APP_APP_ENDPOINT;
        const response = await axios.get(
          `${endpoint}api/auth/hierarchical-users`,
          { headers }
        );

        setHierarchicalUsers(response.data.hierarchicalUsers);
      } catch (error) {
        console.error("Failed to fetch hierarchical users", error.response);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDetailsToggle = (userId) => {
    setOpenDetails((prevOpenDetails) => ({
      ...prevOpenDetails,
      [userId]: !prevOpenDetails[userId],
    }));
  };

  const renderTree = (users, level = 0) => {
    if (!Array.isArray(users)) return null;

    return (
      <StyledUl style={{ borderLeft: level > 0 ? "3px solid #c0d1d1" : "none" }}>
        {users.map((item) => {
          // Estrai le informazioni sull'utente come prima...
          let user, subordinates, agencyName;
          if (item.Admin) {
            user = item.Admin;
            subordinates = item.Agenzie;
          } else if (item.Agenzia) {
            user = item.Agenzia;
            subordinates = item.Operatori;
            // Cerca il nome dell'agenzia nei related_values
            const agencyTitleField = user.agencyData.related_values.find(field => field.custom_field.fieldName === "title");
            agencyName = agencyTitleField ? agencyTitleField.value : "Nome Agenzia Non Disponibile";
          } else if (item.Operatore) {
            user = item.Operatore;
            subordinates = item.Clienti;
          } else {
            user = item;
            subordinates = [];
          }

          // Continua con la tua logica per gestire gli altri campi e visualizzare i dettagli...
          const userId = user.id;
          const userName = user.name || "";
          const userLastname = user.cognome || "";

          const userRole = user.temporaryRole === "Agenzia" ? "Operatore" :
            user.temporaryRole === "Customer" ? "Cliente" :
              user.temporaryRole || "";

          return (
            <StyledLi key={userId}>
              <StyledDetails open={true}>
                <summary>
                  {agencyName ? <span>Agenzia: {agencyName}</span> : `[${userRole}] ${userName} ${userLastname}`}
                  {/* Logica per mostrare l'icona, se applicabile */}


                  {!agencyName ? (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // Previene la propagazione per non attivare il dettaglio
                        window.open(`/adminList/User/show/${userId}`, "_blank");
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  ) : (null)}
                </summary>
                {subordinates && subordinates.length > 0 && renderTree(subordinates, level + 1)}
              </StyledDetails>
            </StyledLi>
          );
        })}
      </StyledUl>
    );
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "30px",
          boxShadow: "2px 4px 13px -2px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Card
          sx={{
            padding: 2,
            boxShadow: "none",
            borderRadius: "8px",
          }}
        >
          <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
            Playlist Gerarchia utenti
          </Typography>
          {renderTree(hierarchicalUsers)}
        </Card>
      </div>
    </>
  );
};

export default UserHierarchyGrid;
