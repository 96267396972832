import React from "react";

const RilanciTotali = ({ fillColor = "#E1251B", width = 19, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_768_588)">
        <path
          d="M18.451 8.8372C18.3455 6.9503 17.6786 5.09224 16.5429 3.5833C16.1539 3.06613 15.7107 2.5908 15.2198 2.17173C12.7488 0.0605023 9.13499 -0.580728 6.10186 0.554588C5.5625 0.75655 4.93906 1.28886 5.22263 1.79593C5.48056 2.25828 6.16171 2.09527 6.65761 1.92C9.95223 0.75655 13.9522 2.1775 15.8069 5.17014C17.6615 8.16279 17.1841 12.4271 14.7153 14.9235C12.2465 17.4206 8.03346 17.8988 5.07942 16.0184C2.12538 14.138 0.726023 10.0872 1.87885 6.75266C2.03347 6.30402 2.20518 5.73852 1.86603 5.41034C1.38651 4.94582 0.721036 5.5683 0.566422 6.03497C0.324171 6.76781 0.137495 7.53166 0.054845 8.30272C-0.114018 9.87875 0.10757 11.4995 0.712486 12.963C0.766636 13.0943 0.824349 13.2248 0.884911 13.3539C1.94654 15.6137 3.94298 17.4054 6.2864 18.2003C6.4745 18.2645 6.67756 18.3309 6.79441 18.4932C6.91055 18.6526 6.91411 18.8653 6.91269 19.063C6.90984 19.7727 6.90628 20.4818 6.90271 21.1908C6.90129 21.2795 6.97183 21.3516 7.05947 21.3516H8.16812C8.25433 21.3516 8.32487 21.281 8.32416 21.193C8.32416 20.9773 8.32273 20.7609 8.32273 20.546C8.32273 20.0071 8.33057 19.4676 8.31846 18.9295C8.31347 18.7218 8.36905 18.6713 8.57852 18.6829C9.01386 18.7059 9.45205 18.7045 9.88739 18.6807C10.0897 18.6699 10.1239 18.7369 10.1225 18.9173C10.1182 19.6768 10.1161 20.4363 10.1182 21.1951C10.1182 21.2817 10.1881 21.3516 10.2729 21.3516H11.3958C11.482 21.3516 11.5511 21.2802 11.5504 21.1937C11.5432 20.4854 11.5354 19.7771 11.5276 19.0687C11.5254 18.8646 11.5261 18.6468 11.6344 18.4737C11.7691 18.258 12.0313 18.17 12.27 18.0871C14.8557 17.1898 16.9625 15.0497 17.9194 12.4675C18.3498 11.304 18.5187 10.0641 18.4495 8.8372H18.451Z"
          fill={fillColor}
        />
        <path
          d="M13.9314 7.08961C13.5345 5.46237 11.6043 4.88966 10.4016 6.04806C9.72545 6.69939 9.08847 7.39255 8.39378 8.10807C8.26054 7.94939 8.16364 7.8109 8.04465 7.69621C7.19678 6.88404 5.89076 6.89486 5.07067 7.71497C4.26411 8.52209 4.2178 9.84278 5.00511 10.6997C5.63283 11.3827 6.28762 12.0434 6.95951 12.6832C7.79813 13.481 9.05213 13.4788 9.87508 12.6659C11.0699 11.4881 12.2441 10.2885 13.4105 9.08182C13.9456 8.52859 14.1138 7.83831 13.9314 7.08961ZM12.4593 7.99122C12.4095 8.07056 12.3418 8.14053 12.2748 8.20833C11.1904 9.31047 10.1038 10.4104 9.01936 11.5126C8.84836 11.6864 8.66738 11.8343 8.41231 11.8191C8.19072 11.8278 8.01972 11.7333 7.87223 11.5833C7.28726 10.9904 6.69802 10.4018 6.11876 9.80383C5.79742 9.47204 5.78317 9.04143 6.06461 8.74858C6.35602 8.44564 6.78495 8.46295 7.13265 8.80051C7.37134 9.03277 7.59863 9.27585 7.83589 9.50954C8.22991 9.89832 8.58616 9.90337 8.9759 9.51243C9.74041 8.74642 10.4985 7.97463 11.2566 7.20213C11.4283 7.02685 11.6079 6.88404 11.8679 6.88764C12.1544 6.89125 12.3688 7.01892 12.4971 7.27498C12.6182 7.51661 12.604 7.76185 12.4593 7.99122Z"
          fill={fillColor}
        />
        <path
          d="M3.90149 3.23615C3.90719 3.63069 3.61364 3.94734 3.22746 3.96177C2.83203 3.97619 2.4943 3.65666 2.48646 3.25995C2.47862 2.87045 2.8192 2.52279 3.20395 2.5264C3.58229 2.53 3.89508 2.84881 3.90149 3.23543V3.23615Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_768_588">
          <rect width="18.466" height="21.3517" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RilanciTotali;
