import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import AddressRegister from "components/customInputs/AddressRegister";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();

export default function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [documentFile, setDocumentFile] = useState(null);
  const [companyDocumentFile, setCompanyDocumentFile] = useState(null);
  const [visuraFile, setVisuraFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [companyFileUploaded, setCompanyFileUploaded] = useState(false);
  const [visuraFileUploaded, setVisuraFileUploaded] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTermsChange = (e) => {
    setAcceptTerms(e.target.checked);
  };

  const handleAddressChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsCompany(e.target.checked);
  };

  const handleFileChange = (e) => {
    setDocumentFile(e.target.files[0]);
    setFileUploaded(true);
  };

  const handleCompanyFileChange = (e) => {
    setCompanyDocumentFile(e.target.files[0]);
    setCompanyFileUploaded(true);
  };

  const handleVisuraFileChange = (e) => {
    setVisuraFile(e.target.files[0]);
    setVisuraFileUploaded(true);
  };

  const handleDateChange = (e) => {
    setFormData({
      ...formData,
      data_nascita: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    if (documentFile) {
      formDataToSend.append("documentFile", documentFile);
    }
    if (companyDocumentFile) {
      formDataToSend.append("ci_rapp_legale", companyDocumentFile);
    }
    if (visuraFile) {
      formDataToSend.append("visura", visuraFile);
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/register",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const token = response.data.token;

      if (token != null) {
        localStorage.setItem("token", token);
        navigate("/verification-opt");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;

        if (errorMessage === "validation error") {
          if (error.response.data.errors) {
            const errorFields = Object.keys(error.response.data.errors);
            const errorMessage = `I seguenti campi sono richiesti: ${errorFields.join(
              ", "
            )}`;
            setSnackbarMessage(errorMessage);
            setOpenSnackbar(true);
          }
        } else if (errorMessage === "The email has already been taken.") {
          setSnackbarMessage("L'email è già stata registrata.");
          setOpenSnackbar(true);
        } else {
          setErrorMessage(errorMessage);
          setOpenSnackbar(true);
        }
      } else {
        setErrorMessage(error.message);
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#01685D",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              backgroundColor: "white",
              boxShadow:
                "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
              padding: { xs: 2, sm: 4, md: 6 },
              margin: { xs: 2, sm: 4, md: 10 },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#6CC4B4" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Registrati
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      onChange={handleChange}
                      autoFocus
                      value={formData.name || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      required
                      fullWidth
                      id="cognome"
                      label="Cognome"
                      name="cognome"
                      onChange={handleChange}
                      value={formData.cognome || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      required
                      fullWidth
                      id="email"
                      label="Indirizzo email"
                      name="email"
                      autoComplete="email"
                      onChange={handleChange}
                      value={formData.email || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      value={formData.password || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AddressRegister
                      field="address"
                      value={formData.address || ""}
                      onChange={handleAddressChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      id="referral_code"
                      label="Codice Referral"
                      name="referral_code"
                      onChange={handleChange}
                      value={formData.referral_code || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      autoComplete="given-name"
                      name="telefono"
                      required
                      fullWidth
                      id="telefono"
                      label="Telefono"
                      onChange={handleChange}
                      value={formData.telefono || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      autoComplete="given-name"
                      name="codice_fiscale"
                      required
                      fullWidth
                      id="codice_fiscale"
                      label="Codice Fiscale"
                      onChange={handleChange}
                      value={formData.codice_fiscale || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="data_nascita"
                      label="Data di Nascita"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleDateChange}
                      variant="standard"
                      fullWidth
                      name="data_nascita"
                      value={formData.data_nascita || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="documentFile"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="documentFile" style={{ width: "100%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          sx={{
                            background: "#6CC4B4",
                            "&:hover": {
                              background: "#01685D",
                              color: "#fff",
                            },
                          }}
                        >
                          Documento di Identità
                        </Button>
                      </label>
                      {fileUploaded ? (
                        <CheckCircleIcon sx={{ color: "green", ml: 2 }} />
                      ) : (
                        <CancelIcon sx={{ color: "red", ml: 2 }} />
                      )}
                    </Box>
                    {documentFile && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {documentFile.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCompany}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Sei un'azienda?"
                    />
                  </Grid>
                  {isCompany && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          autoComplete="given-name"
                          name="rag_sociale"
                          fullWidth
                          id="rag_sociale"
                          label="Ragione Sociale"
                          onChange={handleChange}
                          value={formData.rag_sociale || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AddressRegister
                          label="Sede Legale"
                          field="sede_legale"
                          value={formData.sede_legale || ""}
                          onChange={handleAddressChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          autoComplete="given-name"
                          name="pec"
                          fullWidth
                          id="pec"
                          label="PEC"
                          onChange={handleChange}
                          value={formData.pec || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          autoComplete="given-name"
                          name="piva_cfiscale"
                          required
                          fullWidth
                          id="piva_cfiscale"
                          label="Partita IVA / Fiscale"
                          onChange={handleChange}
                          value={formData.piva_cfiscale || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          autoComplete="given-name"
                          name="univoco"
                          fullWidth
                          id="univoco"
                          label="Codice Univoco"
                          onChange={handleChange}
                          value={formData.univoco || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="ci_rapp_legale"
                            type="file"
                            onChange={handleCompanyFileChange}
                          />
                          <label
                            htmlFor="ci_rapp_legale"
                            style={{ width: "100%" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              fullWidth
                              sx={{
                                background: "#6CC4B4",
                                "&:hover": {
                                  background: "#01685D",
                                  color: "#fff",
                                },
                              }}
                            >
                              Documento Identità Rapp. Legale
                            </Button>
                          </label>
                          {companyFileUploaded ? (
                            <CheckCircleIcon sx={{ color: "green", ml: 2 }} />
                          ) : (
                            <CancelIcon sx={{ color: "red", ml: 2 }} />
                          )}
                        </Box>
                        {companyDocumentFile && (
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            {companyDocumentFile.name}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="visura"
                            type="file"
                            onChange={handleVisuraFileChange}
                          />
                          <label htmlFor="visura" style={{ width: "100%" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              fullWidth
                              sx={{
                                background: "#6CC4B4",
                                "&:hover": {
                                  background: "#01685D",
                                  color: "#fff",
                                },
                              }}
                            >
                              Visura Camerale
                            </Button>
                          </label>
                          {visuraFileUploaded ? (
                            <CheckCircleIcon sx={{ color: "green", ml: 2 }} />
                          ) : (
                            <CancelIcon sx={{ color: "red", ml: 2 }} />
                          )}
                        </Box>
                        {visuraFile && (
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            {visuraFile.name}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptTerms}
                        onChange={handleTermsChange}
                        color="primary"
                      />
                    }
                    label={
                      <>
                        Ho letto e accetto i{" "}
                        <Link
                          href="/path/to/terms.pdf"
                          target="_blank"
                          rel="noopener"
                        >
                          Termini e Condizioni
                        </Link>
                      </>
                    }
                  />
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    background: "#6CC4B4",
                    "&:hover": {
                      background: "#01685D",
                      color: "#fff",
                    },
                  }}
                  disabled={!acceptTerms}
                >
                  Registrati
                </Button>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Link href="/" sx={{ color: "#01685D" }}>
                      Hai già un account? Accedi
                    </Link>
                  </Grid>
                </Grid>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="error">
                    {snackbarMessage || errorMessage}
                  </Alert>
                </Snackbar>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
