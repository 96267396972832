import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import ShowAsta from "./pages/Aste/showAsta";
import ShowAstaPrivate from "./pages/Aste/showAstaPrivate";
import LeMieInserzioni from "./pages/Inserzioni/LeMieInserzioni";
import { Layout } from "./components/layout/Layout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TutteLeAstePage from "./pages/Aste/tutteLeAste";
import Wallet from "./pages/wallet/Wallet";
import List from "./pages/Adminlist/List";
import Show from "./pages/Adminlist/Show";
import Impostazioni from "./pages/Impostazioni/Impostazioni";
import MyProfile from "./pages/Impostazioni/Impostazioni";
import OptPage from "pages/OptPage";
import IlMioProfilo from "./pages/Profilo/IlMioProfilo";
import env from "react-dotenv";
import { LicenseInfo } from "@mui/x-license-pro";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeMieAste from "./pages/Aste/LeMieAste";
import MyCustomers from "pages/MyCustomers/MyCustomers";
import MyOrders from "pages/MyCustomers/MyOrders";
import AstePubbliche from "pages/Aste/AstePubbliche";
import DynamicWizardHorizontal from "components/dynamic-wizard-horizontal";
import UserHierarchyGrid from "components/UserHierarchyGrid";
import NotFound from "components/NotFound";
import { useSelector } from "react-redux";
import ImpostazioniAgenzia from "pages/Impostazioni/impostzioneAgenzia";
import CLientiOperatori from "pages/Adminlist/User/ClientiOperatori";
import "./App.css";

LicenseInfo.setLicenseKey(
  "792d60123edeb1405c178f2f86f07ae7Tz04NzI0OCxFPTE3NDMwOTAxMzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const token = localStorage.getItem("token");
  const general = useSelector((state) => state.general);

  useEffect(() => {
    document.title = "BiddingWave - Aste Immobiliari";
  }, []);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
      />

      <BrowserRouter>
        <Routes>
          <Route path="/asta/:id" element={<ShowAsta />} />
          <Route path="/ricerca-pubblica" element={<AstePubbliche />} />
          <Route
            path="/ricerca-pubblica/:houseType"
            element={<AstePubbliche />}
          />
          <Route
            path="/ricerca-pubblica/:houseType/:type"
            element={<AstePubbliche />}
          />
          <Route
            path="/ricerca-pubblica/:houseType/:type/:city"
            element={<AstePubbliche />}
          />
          <Route
            path="/ricerca-pubblica/:houseType/:type/:city/:newConstruction"
            element={<AstePubbliche />}
          />
          <Route element={<ProtectedRoute />}>
            <Route index element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />

            <Route
              path="/mio-profilo"
              element={
                <Layout>
                  <IlMioProfilo />
                </Layout>
              }
            />

            <Route
              path="/my-customers"
              element={
                <Layout>
                  <MyOrders />
                  <MyCustomers />
                </Layout>
              }
            />

            <Route path="/verification-opt" element={<OptPage />} />

            <Route
              path="/le-mie-inserzioni"
              element={
                <Layout>
                  <LeMieInserzioni />
                </Layout>
              }
            />
            <Route
              path="ricerca"
              element={
                <Layout>
                  <TutteLeAstePage />
                </Layout>
              }
            />
            <Route
              path="/wallet"
              element={
                <Layout>
                  <Wallet />
                </Layout>
              }
            />
            <Route
              path="/adminlist/:object"
              element={
                <Layout>
                  <List />
                </Layout>
              }
            />
            <Route
              path="/adminlist/:object/create/"
              element={
                <Layout>
                  <DynamicWizardHorizontal
                    hiddenFields={{
                      actualOffer: true,
                      deadline: true,
                      related_user: true,
                      username: true,

                      ...(general.temporaryRole === "Agenzia"
                        ? { agency_id: true }
                        : {}),
                    }}
                    prefilledValues={{
                      ...(general.temporaryRole === "Agenzia"
                        ? {
                            agency_id: general.agencyId,
                            related_user: general.userId,
                          }
                        : {}),
                      owner_id: general.userId,
                    }}
                    objectType="immobile"
                  />
                </Layout>
              }
            />
            <Route
              path="/adminlist/:object/show/:id"
              element={
                <Layout>
                  <Show />
                </Layout>
              }
            />
            <Route
              path="/list/:object/show/:id"
              element={
                <Layout>
                  <ShowAstaPrivate />
                </Layout>
              }
            />
            <Route
              path="/settings"
              element={
                <Layout>
                  <Impostazioni />
                </Layout>
              }
            />
            <Route
              path="/settingsAgency"
              element={
                <Layout>
                  <ImpostazioniAgenzia />
                </Layout>
              }
            />
            <Route
              path="/clienti-operatori"
              element={
                <Layout>
                  <CLientiOperatori />
                </Layout>
              }
            />
            <Route
              path="/playlist"
              element={
                <Layout>
                  <UserHierarchyGrid />
                </Layout>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
