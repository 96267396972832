import React, { useState, useEffect } from "react";
import axios from "axios";
import FieldsRenderer from "./fields-renderer";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Card,
  CardContent,
  Grid,
  Button,
  CardHeader,
  Box,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

function DynamicWizard({
  objectType,
  prefilledValues,
  completeAndRedirect,
  onClose,
  reloadRecords,
  hiddenFields,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [formData, setFormData] = useState(prefilledValues || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate(); // Hook per navigare


  console.log('hiddenFields', hiddenFields)
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_APP_ENDPOINT +
        "api/auth/getFieldsForObjectForm/" +
        objectType,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setStepsData(response.data);
      })
      .catch((error) => {
        console.error("Errore nel recupero dei dati:", error);
        setIsError(true);
      });
  }, []);

  const handleApiCall = async (completeAndRedirect) => {

    try {
      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT +
        "api/auth/storeRecord/" +
        objectType,
        formData,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );

      if (completeAndRedirect) {
        toast.success("Dati salvati con successo!");
        setIsModalOpen(false);
        onClose && onClose(); // Additional callback if provided
        reloadRecords && reloadRecords(); // Additional callback if provided

      } else {
        toast.success("Dati salvati con successo!");
        setModalMessage("Dati salvati con successo!");

        setIsModalOpen(false);
        reloadRecords && reloadRecords(); // Additional callback if provided
        onClose && onClose(); // Additional callback if provided
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il salvataggio dei dati.";
      if (completeAndRedirect) {
        toast.error(errorMessage);
      } else {
        toast.error(errorMessage);

        setModalMessage(errorMessage);
        setIsModalOpen(true);
      }
    }
  };

  const handleSave = () => {

    handleApiCall(completeAndRedirect);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderStepContent = (stepKey) => {
    const fields = stepsData[stepKey] || [];

    return (
      <Grid container spacing={1}>
        {fields.map((field) => {
          const isReadOnly = Boolean(field.isReadOnly);
          if (
            isReadOnly ||
            (hiddenFields &&
              Object.keys(hiddenFields).includes(field.fieldName))
          ) {
            return null;
          }


          return (
            <Grid item xs={12} md={12} key={field.id}>
              <InputLabel htmlFor={field.name}>
                {field.label}
                {field.required && <span style={{ color: "red" }}> *</span>}
              </InputLabel>
              <FieldsRenderer
                field={field}
                value={formData[field.fieldName]}
                onChange={handleInputChange}
                prefilledValues={prefilledValues}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Card sx={{ borderRadius: 0 }}>
      <CardContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {Object.keys(stepsData).map((stepKey, index) => (
            <Step key={stepKey}>
              <StepLabel>{stepKey}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {renderStepContent(stepKey)}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep > 0 && (
                    <Button
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mr: 1 }}>
                      Indietro
                    </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === Object.keys(stepsData).length - 1 ? (
                    <Button variant="contained" onClick={handleSave}>
                      Completa ed Invia
                    </Button>
                  ) : (
                    <>
                      <Button variant="contained" onClick={handleSave}>
                        Salva e continua dopo
                      </Button>
                      <Button variant="contained" onClick={handleNext}>
                        Avanti
                      </Button>
                    </>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={
            {
              /* stili per la modale */
            }
          }>
          <Typography variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Button onClick={handleCloseModal}>OK</Button>
        </Box>
      </Modal>
    </Card>
  );
}

export default DynamicWizard;
